import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { updateManageProfile, deleteData } from "../../../../store/slices/manageProfile/manageProfileSlice.js";
import useValidations from "../../../../utils/hooks/useValidations.js";
import { useNavigate } from "react-router-dom";

const useProfile = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedItemsDetails } = useSelector((state) => state.manageProfile);
  const navigate = useNavigate();

  const { validateEmail } = useValidations();
  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails !== ""
      ? selectedItemsDetails?.data?.profile_data?.image
      : ""
  );

  const activeUser = useMemo(
    () => JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found",
    []
  );

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImagePreview(selectedItemsDetails?.data?.profile_data?.image ?? "");
    }
  }, [selectedItemsDetails]);

  const validation = Yup.object({
    first_name: Yup.string()
      .min(2, "Minimum 2 characters required")
      .max(20, "Maximum 20 characters allowed")
      .required("First name is required"),
    last_name: Yup.string()
      .max(20, "Maximum 20 characters allowed")
      .required("*Required"),
    bio: Yup.string()
      .max(75, "Maximum 75 charecters allowed")
      .required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      profile_image:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.profile_data?.image
          : "",
      first_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.profile_data?.first_name
          : "",
      last_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.profile_data?.last_name
          : "",
      email:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.profile_data?.email
          : "",
      bio:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.profile_data?.bio
          : "",
    },

    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Required";
      }
      if (values.email && !validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      return errors;
    },

    validationSchema: validation,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        bio: values.bio,
      };

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (values?.profile_image instanceof File) {
        formData.append("image", values?.profile_image);
      }

      dispatch(updateManageProfile(formData)).then((response) => {
        if (response?.payload?.success) {
          resetForm();
          closeModal?.();
          toast.success("Profile Updated Successfully");
        } else formik.setErrors(response?.payload?.message);
      });
    } catch (error) {}
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("profile_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };
  const handleDeleteAction = async () => {
    if (showDeleteConfirm) {
      dispatch(deleteData()).then((response) => {
        if (response?.payload?.success) {
          navigate('/login');
          setShowDeleteConfirm(false);
          toast.success("Profile Deleted Successfully");
        } else toast.error(response?.payload?.message);
      });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    formik,
    imagePreview,
    selectedItemsDetails,
    handleProfileImage,
    activeUser: activeUser?.id,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    showDeleteConfirm
  };
};

export default useProfile;
