import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import usePackageModules from "./usePackageModules";
import EmptyData from "../../../Global/EmptyData";
import Style from "../user.module.scss";
import { useNavigate } from "react-router-dom";
import Assets from "../../../../assets/Assets";

const PackageModules = () => {
  const {
    packageData,
    moveToProgram
  } = usePackageModules();
  const navigate = useNavigate();
  return (
    <>
      {packageData?.data?.programs?.length !== 0 && (
                        <div className={`col-auto pro-pt-4 pro-ps-5 pro-pb-6 ${Style.list_root_wrap}`}>
                          <div className="pro-d-flex pro-items-center  pro-mb-5">
                            <h6 className="pro-ttl h6 pro-me-5 pro-mb-0">Benefits</h6>
                            <SearchFilters
                              showActions={false}
                              SearchIcon={
                                <span className="material-symbols-outlined"> search </span>
                              }
                              
                            />
                          </div>
                          
                          <ul className={Style.list_root}>
                            {packageData?.data?.programs?.map(
                              (item, index) => (
                                <li
                                  key={item?.id}
                                  className={`${Style.list_item} pro-mb-3 `}
                                >
                                  <a className="pro-pnt pro-p-5 pro-d-block ">
                                    {/* <span className={Style.count}>{`${String(
                                      index + 1
                                    ).padStart(2, 0)}.`}</span> */}
                                    <span 
                                      onClick={() => {
                                      navigate({
                                        pathname: `/program-details/${item?.module_id}`,
                                      });
                                        sessionStorage.setItem("active", `${item?.module_id}`);
                                      }}
                                    >
                                      <figure className="pro-d-flex">
                                        <img src={Assets.ICON_MODULE1} />
                                        {/* <img src={item?.module_image_path}/> */}
                                        <figcaption className="pro-ms-3">{item?.module?.module_name}</figcaption>
                                      </figure>
                                      
                                      
                                    </span>
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
    </>
  );
};

export default PackageModules;
