import Style from './LearningThumbShimmer.module.scss';

const LearningThumbShimmer = () => {
  return (
    <>
          <div className='col'>
            <div className={`pro-d-flex pro-h-100  pro-border pro-mb-4 pro-rounded-3 overflow-x-hidden overflow-y-hidden pro-p-0 ${Style.shimmer_container}`}>
              <div className={`col-auto pro-w-25 shimmer ${Style.img}`}></div>
              <div className={`col pro-p-4 `}>
                <div className="shimmer pro-p-2 pro-w-50 letter para pro-mt-1"></div>
                <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                <div className={`shimmer pro-p-4 pro-w-50 letter para pro-mt-1 ${Style.tag} `}></div>
                <div className={`pro-d-flex pro-justify-end `} >
                    <div className={`shimmer  pro-p-5 ${Style.button}  letter para pro-mt-1`}></div>   
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
          <div className={`pro-d-flex pro-h-100  pro-border pro-mb-4 pro-rounded-3 overflow-x-hidden overflow-y-hidden pro-p-0 ${Style.shimmer_container}`}>
            <div className={`col-auto pro-w-25 shimmer ${Style.img}`}></div>
            <div className={`col pro-p-4 `}>
              <div className="shimmer pro-p-2 pro-w-50 letter para pro-mt-1"></div>
              <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
              <div className={`shimmer pro-p-4 pro-w-50 letter para pro-mt-1 ${Style.tag} `}></div>
              <div className={`pro-d-flex pro-justify-end `} >
                  <div className={`shimmer  pro-p-5 ${Style.button}  letter para pro-mt-1`}></div>   
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
        <div className={`pro-d-flex pro-h-100  pro-border pro-mb-4 pro-rounded-3 overflow-x-hidden overflow-y-hidden pro-p-0 ${Style.shimmer_container}`}>
          <div className={`col-auto pro-w-25 shimmer ${Style.img}`}></div>
          <div className={`col pro-p-4 `}>
            <div className="shimmer pro-p-2 pro-w-50 letter para pro-mt-1"></div>
            <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
            <div className={`shimmer pro-p-4 pro-w-50 letter para pro-mt-1 ${Style.tag} `}></div>
            <div className={`pro-d-flex pro-justify-end `} >
                <div className={`shimmer  pro-p-5 ${Style.button}  letter para pro-mt-1`}></div>   
            </div>
          </div>
        </div>
      </div>
  </>
    
  )
}

export default LearningThumbShimmer