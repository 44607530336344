import { combineReducers } from "@reduxjs/toolkit";
import { settings } from "./queries/settings";
import { global } from "./queries/global";
import globalSlice from "./slices/Global";
import { dashboard } from "./queries/Dashboard";
import { product } from "./queries/product";
import { profile } from "./queries/profile";
import profileSlice from "./slices/Profile/profileSlice";
import { notificationRtk } from "./queries/notifications";
import notificationSlice from "./slices/Notifications/notificationSlice";
import { roles } from "./queries/roles";
import rolesSlice from "./slices/Roles/rolesSlice";
import settingSlice from "./slices/Settings/settingSlice";
import productSlice from "./slices/Product/productSlice";
import { purpose } from "./queries/purpose";
import purposeSlice from "./slices/Purpose/purposeSlice";
import { delivery } from "./queries/delivery";
import deliverySlice from "./slices/Delivery/deliverySlice";
import { staffs } from "./queries/staffs";
import staffsSlice from "./slices/Staffs/staffsSlice";
import { branches } from "./queries/branches";
import branchSlice from "./slices/Branches/branchSlice";
import branchRateFormSlice from "./slices/Branches/branchRateFormSlice";
import branchPincodeSlice from "./slices/Branches/branchPincodeSlice";
import { user } from "./queries/user";
import userSlice from "./slices/user/userSlice";
import { currency } from "./queries/currency";
import currencySlice from "./slices/currency/currencySlice";
import { orders } from "./queries/orders";
import orderSlice from "./slices/orders/orderSlice";
import packageSlice from "./slices/packages/packageSlice";
import { packages } from "./queries/packages";
import { bonusProgram } from "./queries/bonusProgram";
import bonusProgramSlice from "./slices/bonusProgram/bonusProgramSlice";
import { program } from "./queries/program";
import programSlice from "./slices/program/programSlice";
import { customer } from "./queries/customer";
import customerSlice from "./slices/customer/customerSlice";
import programFormSlice from "./slices/program/programFormSlice";
import programPriceSlice from "./slices/program/programPriceSlice";
import programSessionSlice from "./slices/program/programSessionSlice";
import packagePriceSlice from "./slices/packages/packagePriceSlice";
import packageModuleSlice from "./slices/packages/packageModuleSlice";
import userGoalsSlice from "./slices/customer/userGoalsSlice";
import userPurchaseHistorySlice from "./slices/customer/userPurchaseHistorySlice";
import userOrderSlice from "./slices/customer/userOrderSlice";
import packageFormSlice from "./slices/packages/packageFormSlice";
import dailyMeditationSlice from "./slices/dailyMeditation/dailyMeditationSlice";
import { dailyMeditation } from "./queries/dailyMeditation";
import { questionnaire } from "./queries/questionnaire";
import questionnaireSlice from "./slices/questionnaire/questionnaireSlice";
import { coupon } from "./queries/coupon";
import couponSlice from "./slices/coupon/couponSlice";
import { review } from "./queries/review";
import reviewSlice from "./slices/review/reviewSlice";
import { configure } from "./queries/configure";
import dashboardSlice from "./slices/Dashboard/dashboardSlice";
import { testimonial } from "./queries/testimonials";
import testimonialSlice from "./slices/Testimonial/testimonialSlice";
import { agent } from "./queries/agent";
import agentSlice from "./slices/Agent/agentSlice";
import { announcement } from "./queries/announcement";
import announcementSlice from "./slices/Announcement/announcementSlice";
import configureSlice from "./slices/configure/configureSlice";
import { trainingVideo } from "./queries/trainingVideo";
import trainingVideoSlice from "./slices/TrainingVideo/trainingVideoSlice";
import { trainingSession } from "./queries/trainingSession";
import trainingSessionSlice from "./slices/TrainingSession/trainingSessionSlice";
import { goal } from "./queries/goal";
import goalSlice from "./slices/goal/goalSlice";
import { coupons } from "./queries/coupons";
import couponsSlice from "./slices/Coupons/couponsSlice";
import { students } from "./queries/students";
import studentsSlice from "./slices/Students/studentsSlice";
import { detailCoupon } from "./queries/detailCoupon";
import detailCouponSlice from "./slices/DetailCoupon/detailCouponSlice";
import { payouts } from "./queries/payouts";
import payoutsSlice from "./slices/Payouts/payoutsSlice";
import { sellerLearning } from "./queries/SellerModule/sellerLearning";
import sellerLearningSlice from "./slices/SellerModule/SellerLearning/sellerLearningSlice";
import { sellerTraining } from "./queries/SellerModule/sellerTraining";
import sellerTrainingSlice from "./slices/SellerModule/SellerTraining/sellerTrainingSlice";
import { myStudents } from "./queries/SellerModule/myStudents";
import myStudentsSlice from "./slices/SellerModule/MyStudents/myStudentsSlice";
import { feedbacks } from "./queries/SellerModule/feedbacks";
import feedbacksSlice from "./slices/SellerModule/Feedbacks/feedbacksSlice";
import { reportedIssues } from "./queries/SellerModule/reportedIssues";
import reportedIssuesSlice from "./slices/SellerModule/ReportedIssues/reportedIssuesSlice";
import { studentGoals } from "./queries/SellerModule/studentGoals";
import studentGoalsSlice from "./slices/SellerModule/StudentGoals/studentGoalsSlice";
import { studentPayout } from "./queries/SellerModule/studentPayout";
import studentPayoutSlice from "./slices/SellerModule/StudentPayout/studentPayoutSlice";
import { studentFeedbacks } from "./queries/SellerModule/studentFeedbacks";
import studentFeedbacksSlice from "./slices/SellerModule/StudentFeedbacks/studentFeedbacksSlice";
import { studentIssues } from "./queries/SellerModule/studentIssues";
import studentIssuesSlice from "./slices/SellerModule/StudentIssues/studentIssuesSlice";
import { sellerCoupons } from "./queries/SellerModule/sellerCoupons";
import sellerCouponsSlice from "./slices/SellerModule/SellerCoupons/sellerCouponsSlice";
import { manageProfile } from "./queries/SellerModule/manageProfile";
import manageProfileSlice from "./slices/manageProfile/manageProfileSlice";

import { sellerAnnouncements } from "./queries/SellerModule/sellerAnnouncements";
import sellerAnnoucementsSlice from "./slices/SellerModule/sellerAnnouncements/sellerAnnoucementsSlice";
import { sellerPayout } from "./queries/SellerModule/sellerPayout";
import sellerPayoutSlice from "./slices/SellerModule/SellerPayout/sellerPayoutSlice";
import { sellerDashboard } from "./queries/SellerModule/sellerDashboard";
import sellerDashboardSlice from "./slices/SellerModule/SellerDashboard/sellerDashboardSlice";
import { agentPayouts } from "./queries/agentPayouts";
import agentPayoutsSlice from "./slices/AgentPayouts/agentPayoutsSlice";
import { issues } from "./queries/issues";
import issuesSlice from "./slices/Issues/issuesSlice";
import { monthlyPayouts } from "./queries/monthlyPayouts";
import monthlyPayoutsSlice from "./slices/MonthlyPayouts/monthlyPayoutsSlice";
import { payoutHistory } from "./queries/payoutHistory";
import payoutHistorySlice from "./slices/PayoutHistory/payoutHistorySlice";
import { order } from "./queries/order";
import ordersSlice from "./slices/Order/ordersSlice";

const appReducer = combineReducers({
  [product.reducerPath]: product.reducer,
  [roles.reducerPath]: roles.reducer,
  [settings.reducerPath]: settings.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [profile.reducerPath]: profile.reducer,
  [notificationRtk.reducerPath]: notificationRtk.reducer,
  [purpose.reducerPath]: purpose.reducer,
  [delivery.reducerPath]: delivery.reducer,
  [staffs.reducerPath]: staffs.reducer,
  [currency.reducerPath]: currency.reducer,
  [branches.reducerPath]: branches.reducer,
  [user.reducerPath]: user.reducer,
  [global.reducerPath]: global.reducer,
  [orders.reducerPath]: orders.reducer,
  [packages.reducerPath]: packages.reducer,
  [bonusProgram.reducerPath]: bonusProgram.reducer,
  [dailyMeditation.reducerPath]: dailyMeditation.reducer,
  [program.reducerPath]: program.reducer,
  [customer.reducerPath]: customer.reducer,
  [questionnaire.reducerPath]: questionnaire.reducer,
  [coupon.reducerPath]: coupon.reducer,
  [review.reducerPath]: review.reducer,
  [configure.reducerPath]: configure.reducer,
  [testimonial.reducerPath]: testimonial.reducer,
  [agent.reducerPath]: agent.reducer,
  [announcement.reducerPath]: announcement.reducer,
  [trainingVideo.reducerPath]: trainingVideo.reducer,
  [trainingSession.reducerPath]: trainingSession.reducer,
  [goal.reducerPath]: goal.reducer,
  [coupons.reducerPath]: coupons.reducer,
  [agentPayouts.reducerPath]: agentPayouts.reducer,
  [students.reducerPath]: students.reducer,
  [detailCoupon.reducerPath]: detailCoupon.reducer,
  [payouts.reducerPath]: payouts.reducer,
  [sellerLearning.reducerPath]: sellerLearning.reducer,
  [sellerTraining.reducerPath]: sellerTraining.reducer,
  [myStudents.reducerPath]: myStudents.reducer,
  [feedbacks.reducerPath]: feedbacks.reducer,
  [reportedIssues.reducerPath]: reportedIssues.reducer,
  [studentGoals.reducerPath]: studentGoals.reducer,
  [studentPayout.reducerPath]: studentPayout.reducer,
  [studentFeedbacks.reducerPath]: studentFeedbacks.reducer,
  [studentIssues.reducerPath]: studentIssues.reducer,
  [sellerCoupons.reducerPath]: sellerCoupons.reducer,
  [manageProfile.reducerPath]: manageProfile.reducer,
  [sellerAnnouncements.reducerPath]: sellerAnnouncements.reducer,
  [sellerPayout.reducerPath]: sellerPayout.reducer,
  [sellerDashboard.reducerPath]: sellerDashboard.reducer,
  [issues.reducerPath]: issues.reducer,
  [monthlyPayouts.reducerPath]: monthlyPayouts.reducer,
  [payoutHistory.reducerPath]: payoutHistory.reducer,
  [order.reducerPath]: order.reducer,
  order: ordersSlice,
  payoutHistory: payoutHistorySlice,
  monthlyPayouts: monthlyPayoutsSlice,
  issues: issuesSlice,
  agentPayouts: agentPayoutsSlice,
  sellerDashboard: sellerDashboardSlice,
  sellerPayout: sellerPayoutSlice,
  manageProfile: manageProfileSlice,
  sellerCoupons: sellerCouponsSlice,
  studentIssues: studentIssuesSlice,
  studentFeedbacks: studentFeedbacksSlice,
  studentPayout: studentPayoutSlice,
  studentGoals: studentGoalsSlice,
  reportedIssues: reportedIssuesSlice,
  feedbacks: feedbacksSlice,
  myStudents: myStudentsSlice,
  sellerTraining: sellerTrainingSlice,
  sellerLearning: sellerLearningSlice,
  payouts: payoutsSlice,
  detailCoupon: detailCouponSlice,
  students: studentsSlice,
  coupons: couponsSlice,
  goal: goalSlice,
  trainingSession: trainingSessionSlice,
  trainingVideo: trainingVideoSlice,
  announcement: announcementSlice,
  agent: agentSlice,
  testimonial: testimonialSlice,
  configure: configureSlice,
  review: reviewSlice,
  coupon: couponSlice,
  questionnaire: questionnaireSlice,
  customer: customerSlice,
  program: programSlice,
  programPrice: programPriceSlice,
  programSession: programSessionSlice,
  packagePrice: packagePriceSlice,
  packageModule: packageModuleSlice,
  bonusProgram: bonusProgramSlice,
  dailyMeditation: dailyMeditationSlice,
  package: packageSlice,
  packageForm: packageFormSlice,
  programForm: programFormSlice,
  staffs: staffsSlice,
  delivery: deliverySlice,
  purpose: purposeSlice,
  profile: profileSlice,
  product: productSlice,
  roles: rolesSlice,
  global: globalSlice,
  notification: notificationSlice,
  settings: settingSlice,
  currency: currencySlice,
  branch: branchSlice,
  user: userSlice,
  userGoals: userGoalsSlice,
  userPurchaseHistory: userPurchaseHistorySlice,
  userOrder: userOrderSlice,
  orders: orderSlice,
  branchRateForm: branchRateFormSlice,
  branchPincode: branchPincodeSlice,
  dashboard: dashboardSlice,
  sellerAnnouncments: sellerAnnoucementsSlice,
});

export default appReducer;
