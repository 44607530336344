import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { phoneRegExp, emailRegExp } from "../../../../utils/functions/table";
import {
  createUser,
  updateConfig,
  updateUser,
  // createNewEntry,
  // updateData,
} from "../../../../store/slices/user/userSlice";
const useUserForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();
  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.user
  );

  const [profileImagePreview, setProfileImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image : ""
  );

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setProfileImagePreview(selectedItemsDetails?.profile_image ?? "");
    }
  }, [selectedItemsDetails]);

  const validation = Yup.object({
    first_name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .max(50, "max 50 characters allowed")
      .required("*Required"),
    last_name: Yup.string()
      .min(2, "The last name must be at least 2 characters")
      .max(50, "max 50 characters allowed")
      .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    role_id: Yup.string().required("*Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(10, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      user_id: selectedItemsDetails !== "" ? selectedItemsDetails?.user_id : "",
      first_name:
        selectedItemsDetails !== "" ? selectedItemsDetails?.first_name : "",
      last_name:
        selectedItemsDetails !== "" ? selectedItemsDetails?.last_name : "",
      role_id: selectedItemsDetails !== "" ? selectedItemsDetails?.role_id : "",
      password: "",
      email: selectedItemsDetails !== "" ? selectedItemsDetails?.email : "",
      phone_number:
        selectedItemsDetails !== "" ? selectedItemsDetails?.phone_number : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 0
          : 0,
    },

    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      if (!is_edit) {
        if (!values.password) {
          errors.password = "*Password Required";
        }
        return errors;
      }
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        first_name: values?.first_name,
        last_name: values?.last_name,
        role_id: values?.role_id,
        email: values?.email,
        password: values?.password,
        personal_number: values?.phone_number,
      };
      let formData = new FormData();
      if (values?.status !== "") {
        obj.status = +values?.status;
      }

      if (values?.profile_image?.name) {
        obj.image = values?.profile_image;
      }

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        dispatch(updateUser({ data: formData })).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            toast.error(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createUser({ data: formData })).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            toast.error(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleProfilePhoto = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("profile_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setProfileImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
    handleProfilePhoto,
    profileImagePreview,
    is_edit,
  };
};

export default useUserForm;
