import React from "react";
import useTransactionPage from "./useTransactionPage";
import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";
import Style from "./transactionPage.module.scss";

const TransactionPage = () => {
  const { transactionDetails } = useTransactionPage();
  return (
    <>
      <div className={Style.wrapper}>
        <MultiColumnTable
          title={"Transaction Details"}
          data={transactionDetails}
          extraClassNames={Style?.table}
        />
      </div>
    </>
  );
};

export default TransactionPage;
