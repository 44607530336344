import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id) => `branch_id[]=${id}`).join("&")
      : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("order_id")}${getParams(
          "start_date"
        )}${getParams("end_date")}${getParams("sort_by")}&status=${
          params?.status
        }&sort_order=${
          params?.sort_order
            ? params?.sort_order
            : (params?.product_sort_order
                ? params?.product_sort_order
                : "desc") || "desc"
        }&length=${params?.per_page || "10"}&${getParams("search")}&page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const customer = createApi({
  reducerPath: "customerApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["customer"],
  endpoints: (builder) => ({
    getCustomerListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-customer`,
      }),
      providesTags: ["customer"],
    }),
    getProductListeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/product/list`,
      }),
      providesTags: ["orders"],
    }),
    getBasicDetails: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `/v1/admin-customer/show/${id}`,
      }),
      providesTags: ["customer"],
    }),
    getDeliveryDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/delivery/view`,
      }),
      providesTags: ["orders"],
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/orders/status/change`,
      }),

      invalidatesTags: ["orders"],
    }),
  }),
});

export const {
  useGetCustomerListDataQuery,
  useGetBasicDetailsQuery,
  useGetProductListeDataQuery,
  useGetDeliveryDetailsQuery,
  useUpdateStatusMutation,
} = customer;
