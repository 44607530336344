import { useSelector } from "react-redux";

export const useItemField = (formik) => {
  const aboutUsState = useSelector((state) => state.programForm);


  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.module_price?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("module_price", data);
  };

  

  return {
    handleRemoveFieldCS,
    aboutUsState,
  };
};

export default useItemField;
