import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { getAxiosInstance } from "../../../api";


const axiosBaseQuery =
     () =>
          async ({ params, endpoint, method, body }) => {

               const api = await getAxiosInstance();
               const getParams = (key) => {

                    if (params?.[key]) {
                         return `${key}=${params[key]}&`;
                    } else {
                         return "";
                    }
               };

               try {
                    const response = await api[method](
                         `${endpoint}?${getParams("start_date")}${getParams(
                              "end_date"
                            )}${getParams("status")}${getParams("coupon_type")}${getParams("sort_by")}&sort_order=${params?.sort_order || "desc"
                         }&length=${params?.per_page || "10"}&${getParams("search")}page=${params?.page || 1
                         }`,
                         body
                    );

                    return { data: response.data };
               } catch (axiosError) {
                    let err = axiosError;

                    return {
                         data: err?.response?.data || err.message,
                    };
               }
          };

export const coupon = createApi({
     reducerPath: 'couponApi',
     baseQuery: axiosBaseQuery(),
     tagTypes: ['coupon'],
     endpoints: (builder) => ({
          getCouponData: builder.query({
               query: (values) => ({
                    method: 'get',
                    params: values,
                    endpoint: `v1/admin-coupon`
               }),
               providesTags: ['coupon']
          }),
          updateCouponStatus: builder.mutation({
               query: (body) => ({
                    method: "post",
                    body,
                    endpoint: `v1/admin-coupon/approve-reject`,
               }),
               // invalidatesTags: ["user"]
          }),
          updateTableFieldsData: builder.mutation({
               query: (body) => ({
                    method: "post",
                    body,
                    endpoint: `/admin/user/fields/update`,
               }),
               invalidatesTags: ["user"],
          }),
     })
})

export const { useGetCouponDataQuery, useUpdateCouponStatusMutation,
     useUpdateTableFieldsDataMutation } = coupon;