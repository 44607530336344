import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
};

export const updateProfileData = createAsyncThunk(
  "/admin/profile/update",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/profile/update", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = profileSlice.actions;

export default profileSlice.reducer;
