import React from "react";
import {
  Actions,
  Header,
  ModalLayout,
  NavGroup,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  OffCanvasLayout,
  ConfirmationBox,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useProgramDetailsLayout from "./useProgramDetailsLayout";
import profileStyle from "./programDetailsLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";

// import StudentsForm from "../StudentsListing/StudentsForm";
import Assets from "../../../../assets/Assets";
import HeaderActions from "../../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../pages/Layouts/CommonLayout/useCommonLayout";
// import AddStudents from "../Students/AddStudents";
// import CourseAssign from "../CourseAssign";

const ProgramDetailsLayout = () => {
  const {
    activeProfile,
    isFetching,
    closeModal,
    programData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    handleChangeStatus,
    setStatusUpdate,
    popUpMessage,
    handleDeleteModal,
  } = useProgramDetailsLayout();

  const { navigations, globalState } = useCommonLayout();
  const createLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };


  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(programData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-4 pro-pt-5 left-sidebar`}>
                    <div className={`pro-pt-2 pro-pb-1 pro-border-bottom pro-mb-4 `}>
                      <Link to={`/program`} className="pro-back-btn pro-items-center pro-d-flex pro-items-center pro-mb-2">
                        <i className="material-symbols-outlined x4">
                          {" "}
                          keyboard_arrow_left{" "}
                        </i>
                        <span>{`Back`}</span>  
                        </Link>
                      <h2 className={profileStyle.left_sidebar_title}>{programData?.data?.module_name}</h2>
                    </div>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            programData?.data?.module_image_path ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>
                    </div>
                    <div className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}>
                      <div className="pro-d-flex  pro-justify-between">
                        <h6 className="pro-ttl h6 fw-semibold pro-mb-3 pro-fw-bold">  Basic Details</h6>
                        {/* <i><img src={Assets.EDIT} /></i> */}
                      </div>  
                      <div className={`${profileStyle.dtl_item} pro-mb-3 `}>
                        <strong>Category</strong> 
                        <div className={`${profileStyle.dtls} pro-mt-1 `}>
                          {programData?.data?.category?.category_name ?? ""}
                        </div>
                      </div>
                      <div className={`${profileStyle.dtl_item} pro-mb-3 `}>
                        <strong>Description</strong> 
                        <div className={`${profileStyle.dtls} pro-mt-1 `}>
                          {programData?.data?.module_description ?? ""}
                        </div>
                      </div>
                      {programData?.data?.module_benefits?.length !== 0 && (
                        <div  className={`${profileStyle.dtl_item} pro-mb-3 `}>
                          <strong>Benefits</strong>
                          <div className={`${profileStyle.dtls} pro-mt-1`}>
                            <ul className={Style.list_root}>
                              {programData?.data?.module_benefits?.map(
                                (file, index) => (
                                  <li
                                    key={file?.id}
                                    className={`${Style.list_item}`}
                                  >
                                    <span className={Style.count}>{`${String(
                                      index + 1
                                    ).padStart(2, 0)}.`}</span>
                                    <span>
                                      {file?.title}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                      
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          {/* <OffCanvasLayout
            show={ProfileState?.showAddModal}
            handleClose={closeModal}
            title={`${"Update Student Basic Details"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
          >
            <AddStudents
              isStickyFooter
              refetch={refetch}
              closeModal={closeModal}
            />
          </OffCanvasLayout> */}

          {/* <ModalLayout
            show={ProfileState?.showDeleteModal}
            handleClose={() => handleDeleteModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={popUpMessage}
                cancelText={"No"}
                cancelAction={handleDeleteModal}
                submitText={"Yes"}
                submitAction={() => setStatusUpdate(true)}
                isRight={true}
              />
            </div>
          </ModalLayout> */}

          {/* main container */}

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
            <div className={` tab_wrapper`}>
              <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
              <Outlet context={{ activeProfile }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default ProgramDetailsLayout;
