import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     editProgram: false,
     selectedData: '',
     clearSelection: false,
     start_date: "",
    end_date: "",
    category: "",
    status: "",
}



  

  export const deleteData = createAsyncThunk(
    "/v1/admin-module/delete",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(`/v1/admin-module/delete`,params);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  

const programSlice = createSlice({
     name: "program",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          },
          setStoreFormValues: (state, action) => {
            state.category= action.payload.category;
            state.start_date= action.payload.start_date;
            state.end_date= action.payload.end_date;
            state.status= action.payload.status;
          },
          resetExtraFilters: (state, action) => {
            state.category= '';
            state.start_date= '';
            state.end_date= '';
            state.status= '';
          },
     },
     
})

export const { updateConfig, setStoreFormValues, resetExtraFilters } = programSlice.actions;

export default programSlice.reducer;