import React from "react";
import Style from "./ChartPreloader.module.scss";
import Assets from "../../../assets/Assets";



function ChartPreloader() {



  return (
    <div className={Style.root}>
      <figure>
        <img src={Assets.CHART_PRELOADER} alt="preloader"/>
      </figure>
    </div>
  );
}

export default ChartPreloader;
