import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetListDataQuery } from "../../../../store/queries/SellerModule/sellerLearning/index.js";

const useMyLearning = () => {
  const [Player, setPlayer] = useState({
    modal: false,
    video: "",
    title: "",
  });
  const menuState = useSelector((state) => state.sellerLearning);

  const { data: mainData = {} } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  const handleWatchVideo = (content) => {
    setPlayer({
      modal: true,
      video: content?.video_url,
      title: content?.name,
    });
  };

  const closePlayerModal = () => {
    setPlayer({
      video: "",
      modal: false,
      title: "",
    });
  };

  return {
    mainData,
    Player,
    closePlayerModal,
    handleWatchVideo,
  };
};

export default useMyLearning;
