import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import { updateConfig } from "../../../../store/slices/PayoutHistory/payoutHistorySlice.js";
import { useGetPayoutHistoryBasicDataQuery } from "../../../../store/queries/payoutHistory/index.js";

const usePayoutDetailsLayout = () => {
  const dispatch = useDispatch();
  const { batchId } = useParams();
  const activeProfile = batchId ?? sessionStorage.getItem("active");
  const menuState = useSelector((state) => state.payoutHistory);

  const navigate = useNavigate();

  const {
    data: payoutHistoryData,
    isFetching,
    isError,
    refetch,
    isLoading,
  } = useGetPayoutHistoryBasicDataQuery({
    payout_batch_payment_id: activeProfile,
    sort_by: menuState.detailSortBy,
    sort_order: menuState.detailSortOrder,
    search: menuState.detailSearch,
    page_size: menuState.detailCurrentPageSize,
    page: menuState.detailCurrentPage,
  });

  useEffect(() => {
    if (payoutHistoryData?.statusCode === 400 || payoutHistoryData === "") {
      toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [payoutHistoryData, activeProfile]);

  useEffect(() => {
    if (isError) {
      toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleSort = (label) => {
    if (menuState.detailSortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.detailSortOrder =
            menuState.detailSortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.detailSortBy = label;
          state.detailSortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.detailSearch = e.target.value;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.detailCurrentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.detailCurrentPageSize = page_size;
        state.detailCurrentPage = 1;
      })
    );
  };

  const handleNavigation = () => {
    navigate("/agent/history");
  };

  return {
    refetch,
    handlePageSize,
    handlePagination,
    handleSearch,
    handleSort,
    getRow,
    handleNavigation,
    activeProfile,
    paginationOptions,
    payoutHistoryData,
    menuState,
    isFetching,
    isLoading,
  };
};

export default usePayoutDetailsLayout;
