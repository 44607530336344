import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import Filter from "./Filter";
import usePayoutHistory from "./usePayoutHistory";

const PayoutHistory = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showEditModal,
    showDeleteConfirm,
    filterShow,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleSearch,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    updateTableFields,
    handleDashboardRedirect,
    setFilterShow,
  } = usePayoutHistory();
  return (
    <>
      {!dashboard && (
        <HeadingGroup title={"Payout History"} className={`pro-mb-4`} />
      )}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard && (
          <div className="row">
            <div className="col">
              <SearchFilters
                searchable={true}
                onSearchInput={handleSearch}
                showActions={true}
                handleActionClick={handleEditClick}
                loading={isLoading}
                extraFilters={
                  <div className="col-auto">
                    <button
                      className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `}
                      onClick={() => setFilterShow(true)}
                    >
                      <span className="material-symbols-outlined">tune</span>
                      <span>Filter</span>
                    </button>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {mainData?.data?.list?.length === 0 ? (
          <EmptyData />
        ) : (
          <div onClick={dashboard ? () => handleDashboardRedirect() : () => {}}>
            <Table
              data={mainData?.data?.list || []}
              uniqueID={"id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              showCheckBox={false}
              multiSelect={false}
              fields={mainData?.data?.fields}
              SortIcon={!dashboard && <FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
            />
          </div>
        )}

        {mainData?.data?.list?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.total_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
        <OffCanvasLayout
          centered={true}
          show={filterShow}
          handleClose={() => {
            setFilterShow(false);
          }}
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setFilterShow} refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default PayoutHistory;
