import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createNewEntry,
  updateConfig,
  updateData,
} from "../../../../store/slices/Agent/agentSlice.js";
import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table.js";

const useAddAgent = ({ refetch }) => {
  const dispatch = useDispatch();

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.agent
  );

  const initialData = {
    first_name: is_edit ? selectedItemsDetails?.first_name : "",
    last_name: is_edit ? selectedItemsDetails?.last_name : "",
    country_code: is_edit
      ? selectedItemsDetails?.country_code
      : "",
      mobile: is_edit ? selectedItemsDetails?.mobile : "",
    email: is_edit ? selectedItemsDetails?.email : "",
    // dob: is_edit ? selectedItemsDetails?.dob : "",
    status: is_edit ? selectedItemsDetails?.status : false
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      first_name: Yup.string()
      .required("*Required"),
      last_name: Yup.string().required("*Required"),
      country_code: Yup.number()
        .required("*Required"),
        mobile: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*phone number is required"),
      email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*email is required"),
      // dob: Yup.string().required("*Required")
    }),

    onSubmit: (values) => {
      // Create an object with the form values
      let obj = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        country_code: values?.country_code,
        mobile: values?.mobile,
        // dob: values?.dob,
        email: values?.email
      };
    
      // If editing, add the additional fields to the object
      if (is_edit) {
        obj.agent_id = selectedId;
        obj.status = +values?.status; // Ensure status is a number
      }
    
      // Create a new FormData instance
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        formData.append(key, obj[key]);
      });
    
      // Dispatch the appropriate action based on the mode (edit or create)
      const action = is_edit ? updateData({ data: formData }) : createNewEntry(formData);
    
      dispatch(action)
        .then((result) => {
          if (result?.payload?.status_code === 200) {
            refetch();
            toast.success(result?.payload?.message);
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
                state.is_edit = false;
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.payload?.message);
          }
        })
    },
    
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  return {
    formik,
    is_edit,
    getFieldError,
    closeModal,
  };
};

export default useAddAgent;
