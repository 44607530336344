import React from "react";
import useProductsPage from "./useProductsPage";
import EmptyData from "../../../Global/EmptyData";
import { Table } from "@wac-ui-dashboard/wac_component_library";

const ProductsPage = () => {
  const { productData, field, getRow } = useProductsPage();

  return (
    <>
      {productData?.length === 0 ? (
        <EmptyData />
      ) : (
        <Table
          data={productData || []}
          uniqueID={"id"}
          showCheckBox={false}
          deletable={false}
          editable={false}
          multiSelect={false}
          assignable={false}
          fields={field}
          getRow={getRow}
        />
      )}
    </>
  );
};

export default ProductsPage;
