import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
  ConfirmationBox,
  SearchFilters,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import useMyStudents from "./useMyStudents";
import EmptyData from "../../../Global/EmptyData";
import CaptureEmail from "./CaptureEmailForm";
import StudentOtp from "./StudentOtp";
import Filter from "./Filter";

const MyStudents = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showEditModal,
    showDeleteConfirm,
    hasUpdatePermission,
    hasDeletePermission,
    showCreateModal,
    showOtpModal,
    closeOtpModal,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    closeEditModal,
    handleDelete,
    setShowDeleteConfirm,
    handleDeleteAction,
    updateTableFields,
    closeModal,
    handleCreateClick,
    handleEditAction,
    handleDashboardRedirect,
    handleEditColumnsClick,
    setFilterShow,
    filterShow,
    handleSearch
  } = useMyStudents({ dashboard });

  return (
    <>
      {!dashboard ? (
        <HeadingGroup
          title={"My Students"}
          extraClassName={`pro-mb-2 main_heading`}
          buttonTitle={"Forgot to Register ?"}
          handleClick={handleCreateClick}
        />
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 `}>
        {!dashboard && (
          <div className="row">
            <div className="col">
              <SearchFilters
              onSearchInput={handleSearch}
                showActions={true}
                handleActionClick={handleEditColumnsClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                searchInputProps={{ value: menuState?.search }}
                extraFilters={
                  <div className="col-auto">
                    <button
                      className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `}
                      onClick={() => setFilterShow(true)}
                    >
                      <span className="material-symbols-outlined">tune</span>
                      <span>Filter</span>
                    </button>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {mainData?.data?.list?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            className="table_scroll scrollbar"
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
          >
            <Table
              data={mainData?.data?.list || []}
              uniqueID={"id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              showCheckBox={
                dashboard ? false : hasUpdatePermission === 1 ? true : false
              }
              deletable={hasDeletePermission === 1 ? true : true}
              handleDelete={handleDelete}
              handleEdit={handleEditAction}
              clear={menuState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={mainData?.data?.fields}
              SortIcon={!dashboard && <FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
              editable={hasUpdatePermission === 1 ? true : false}
            />
          </div>
        )}
        {mainData?.data?.list?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.total_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>

      <ModalLayout
        show={showCreateModal}
        handleClose={closeModal}
        title={"Student OTP Registration"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <CaptureEmail refetch={refetch} closeEmailModal={closeModal} />
      </ModalLayout>

      <ModalLayout
        show={showOtpModal}
        handleClose={closeOtpModal}
        title={"Student OTP Registration"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <StudentOtp refetch={refetch} closeOtpModal={closeOtpModal} />
      </ModalLayout>
      <OffCanvasLayout
        centered={true}
        show={filterShow}
        handleClose={() => {
          setFilterShow(false);
        }}
        title={"Filter"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <Filter setShowform={setFilterShow} refetch={refetch} />
      </OffCanvasLayout>
    </>
  );
};

export default MyStudents;
