import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { getAxiosInstance } from "../../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("end")}${getParams(
          "filter"
        )}${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.page_size || "10"}&${getParams(
          "search"
        )}&${getParams("customer_id")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const sellerAnnouncements = createApi({
  reducerPath: "sellerAnnouncemensApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["sellerAnnouncement"],
  endpoints: (builder) => ({
    getSellerAnnouncementData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/agent/announcement`,
      }),
      providesTags: ["sellerAnnouncement"],
    }),
  }),
});

export const { useGetSellerAnnouncementDataQuery } = sellerAnnouncements;
