import style from "./dashboardCard.module.scss";
const DashboardCard = ({ title, data, icon, active, prev, transactions }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner} pro-bg-white`}>
        <div className={`${style.body}`}>
          {/* <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
            {data?.current_month !== null && data?.current_month !== undefined
              ? data?.current_month
              : data?.total_payment !== null &&
                data?.total_payment !== undefined
              ? data?.total_payment
              : null}
          </h3> */}
          <h3 className={`pro-ttl pro-mb-0 pro-fw-bold ${style.total_value}`}>
            {data?.current_month !== null && data?.current_month !== undefined
              ? data?.current_month
              : data !== null && data !== undefined
              ? data
              : null}
          </h3>
          <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
            {title}
          </h6>
          <div className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}>
          {data?.comparison_status && (
            <span
              className={`${
                data?.comparison_status === "greater"
                  ? style.increment
                  : data?.comparison_status === "lower"
                  ? style.decrement
                  : ""
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
            >
              <span className={`material-symbols-outlined ${style.arrow}`}>
                arrow_outward
              </span>
              <span>{data?.percentage_difference}%</span>
            </span>
          )}

          {data?.comparison_status && (
            <p className={`pro-mb-0 pro-font-xs pro-fw-medium pro-badge ${style.last_month}`}>
              {"Last Month: "} <span>{data?.previous_month}</span>
            </p>
          )}
          
        </div>
      </div>

      <div className={`${style.icon}`}>
          <span className="material-symbols-outlined">{icon}</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
