import {
  HeadingGroup,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import useSellerAnnouncements from "./useSellerAnnouncements";
import EmptyData from "../../../Global/EmptyData";
import Style from "./sellerAnnouncement.module.scss"
import AnnouncementShimmer from "../../../Global/Shimmers/AnnouncementShimmer";

const SellerAnnouncements = ({ dashboard = false }) => {
  const {
    sellerAnnouncementData,
    sellerAnnouncementState,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleDashboardRedirect
  } = useSellerAnnouncements({ dashboard});
  
  return (
    <>
    {!dashboard ? (
      <HeadingGroup title={"Announcements"}  extraClassName={`pro-mb-4`} /> ) : (<></>)}

      <div className=" pro-w-100  ">
        {!sellerAnnouncementData?.data?.data ? (
          <AnnouncementShimmer/>
        ) : sellerAnnouncementData?.data?.data?.length  === 0 ? (
          <EmptyData />
        ) : (
        
          sellerAnnouncementData?.data?.data?.map((item) => (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            >
            <div className={` pro-d-flex ${Style.announced_item}`} key={item?.id}>
              <div className={`col-auto pro-pe-2 ${Style.icon}`}>
                <span className={`material-symbols-outlined `}>campaign</span>
              </div>
              <div className={`col  ${Style.accounce_dtls}`}>
                <div className={Style.inner_wrap}>
                  <h6 className={`${Style.title} pro-m-0`}>{item?.name}</h6>
                  <p className={`${Style.desc} pro-m-0`}>{item?.description}</p>
                  <p className={`${Style.date_time} pro-m-0`}>
                    {item?.created_date} | {item?.time} <span className="pro-badge badge-success pro-ms-2">Training</span>
                  </p>
                </div>
                <span className={Style.created_date}>{item?.created_date}</span>
              </div>
              
            </div>
            </div>
          ))
        )}
      </div>

      {sellerAnnouncementData?.data?.data?.length > 0 && !dashboard && (
        <Pagination
          currentPage={sellerAnnouncementState?.currentPage}
          defaultValue={paginationOptions?.filter(
            (item) => item.value === sellerAnnouncementState?.currentPageSize
          )}
          totalPageCount={Math.ceil(
            sellerAnnouncementData?.data?.total_count /
              sellerAnnouncementState?.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </>
  );
};

export default SellerAnnouncements;