import React, { useState, useEffect, useRef } from "react";
import Style from "./RangeSlider.module.scss";

const RangeSlider = ({ min, max, initialValue, formik }) => {
  
  const [value, setValue] = useState(initialValue);
  const [tooltipPosition, setTooltipPosition] = useState(0);
  const rangeRef = useRef(null);
  const thumbWidth = 20; // Set this to the pixel width of the thumb

  const updateTooltipPosition = () => {
    const rangeInput = rangeRef.current;
    const rangeWidth = rangeInput.offsetWidth;
    const currentValue = value !== undefined ? value : initialValue
    const offset = ((currentValue - min) / (max - min)) * (rangeWidth - thumbWidth);
    setTooltipPosition(offset); // Adjust for the label width
  };

  const updateBackground = () => {
    const rangeInput = rangeRef.current;
    const currentValue = value !== undefined ? value : initialValue
    // const val = value < 30 ? "< 31" : value;
    const percentage = ((currentValue - min) / (max - min)) * 100;
    const grad = `linear-gradient(90deg, var(--pro-primary) ${percentage}%, grey ${percentage + 1}%)`;
    rangeInput.style.background = grad;
  };

  useEffect(() => {
    updateTooltipPosition();
    updateBackground();
    window.addEventListener('resize', updateTooltipPosition);
    return () => window.removeEventListener('resize', updateTooltipPosition);
  }, [value, initialValue, min, max]);

  return (
    <div className={`${Style.range_slider} input-wrap`}>
      <input
        type="range"
        min={min}
        max={max}
        id="discount_percentage"
        title="discount_percentage"
        name="discount_percentage"
        value={value ?? initialValue}
        onChange={(e) => {
          setValue(Number(e.target.value));
          formik?.setFieldValue("discount_percentage", Number(e.target.value));
        }}
        ref={rangeRef}
      />
      <div className={Style.range_percentage}>
        <span className={Style.min}>{min}%</span>
        <span
          className={`${Style.current} ${(value ? value === min : initialValue === min) || (value ? value === max : initialValue === max) ? Style.hide : ''}`}
          style={{ left: `${tooltipPosition}px` }}
        >
          <em>{value ?? initialValue}%</em>
        </span>
        <span className={Style.max}>{max}%</span>
      </div>
    </div>
  );
};

export default RangeSlider;
