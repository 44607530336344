import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetUserListDataQuery,
  useUpdateUserStatusMutation,
} from "../../../store/queries/user";
import { deleteData, updateConfig } from "../../../store/slices/user/userSlice";
import { addDays } from "date-fns";
import { useState, useMemo, useEffect } from "react";
import {
  useGetBasicDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/global";
import { getUserEditData } from "../../../store/slices/user/userSlice";
import { user } from "../../../store/queries/user";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const useUser = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.user);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const { showCreateModal } = useSelector((state) => state.user);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const { data: formData = {} } = useGetBasicDataQuery();
  const [filterShow, setFilterShow] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetUserListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState?.currentPage,
    filter: menuState?.currentFilter,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
    role: menuState.role,
    status: menuState.status,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permissions?.[0]?.can_create ?? false;
    // eslint-disable-next-line
  }, [mainData]);

  const hasEditPermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permissions?.[0]?.can_edit ?? false;
    // eslint-disable-next-line
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permissions?.[0]?.can_delete ?? false;
    // eslint-disable-next-line
  }, [mainData]);

  const hasViewPermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permissions?.[0]?.can_view ?? false;
    // eslint-disable-next-line
  }, [mainData]);

  const hasChangeStatusPermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permissions?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      // name: (field, data) => <p className="pro-mb-0">{data[field].name}</p>,

      status: (field, data) => {
        const isChecked = data?.status;
        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={
                  hasChangeStatusPermission
                    ? () => toggleStatusById(data?.status, data?.user_id)
                    : undefined
                }
                disabled={!hasChangeStatusPermission}
              />
              <span></span>
            </div>
            {/* {hasChangeStatusPermission === 0 ? <label>Status</label> : null} */}
          </div>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const toggleStatusById = (status, id) => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("user_id", id);
    formData.append("status", status === 1 ? 0 : 1);

    updateUserStatus(formData);
    dispatch(
      user.util.updateQueryData(
        "getUserListData",
        {
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState?.currentPage,
          filter: menuState?.currentFilter,
          start_date: menuState.start_date,
          end_date: menuState.end_date,
          role: menuState.role,
          status: menuState.status,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.data?.data?.map((res) =>
            res.user_id === id
              ? {
                  ...res,
                  status: status === 1 ? 0 : 1,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              data: {
                ...currentCache.data.data,
                data: newResult,
              },
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (data) => {
    dispatch(getUserEditData(data?.[0]));

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        state.userEditData = {};
        // state.selectedItemsDetails = ""
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };
  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({ id: selectedItemID[0] })).then((result) => {
        if (result?.payload?.status_code === 200) {
          toast.success(result?.payload?.message);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error(result?.payload?.message);
        }
        if (result) {
          refetch();
          setSelectedItemID("");
          setShowDeleteConfirm(false);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        }
      });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  return {
    mainData,
    isLoading,
    isFetching,
    hasEditPermission,
    hasDeletePermission,
    hasCreatePermission,
    hasViewPermission,
    menuState,
    paginationOptions,
    actionOptions,
    currentPage: menuState?.currentPage,
    activeFilter: menuState?.currentFilter,
    formData,
    showCreateModal,
    showEditModal,
    date,
    filters,
    handleDateRangeChange,
    handleFilter,
    updateTableFields,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    setFilterShow,
    filterShow,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleCreateClick,
  };
};

export default useUser;
