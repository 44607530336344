import React from "react";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useStudentActivity = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "My Students",
      link: "/student-activity/my-student",
      icon: <span className="material-symbols-outlined x4"> school </span>,
      active: checkIfActiveRoute("/student-activity/my-student", true),
    },
    {
      title: "Reported Issues",
      link: "/student-activity/reported-issue",
      icon: <span className="material-symbols-outlined x4"> grading </span>,
      active: checkIfActiveRoute("/student-activity/reported-issue", true),
    },
    {
      title: "Feedbacks",
      link: "/student-activity/feedback",
      icon: <span className="material-symbols-outlined x4"> chat_add_on </span>,
      active: checkIfActiveRoute("/student-activity/feedback", true),
    },
    
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useStudentActivity;
