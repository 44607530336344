import { useGetSellerAnnouncementDataQuery } from "../../../../store/queries/SellerModule/sellerAnnouncements";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/SellerModule/sellerAnnouncements/sellerAnnoucementsSlice";
import { useNavigate } from "react-router-dom";

const useSellerAnnouncements = ({dashboard}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sellerAnnouncementState = useSelector(
    (state) => state.sellerAnnouncments
  );
  const { data: sellerAnnouncementData = {}, isFetching } =
    useGetSellerAnnouncementDataQuery({
      sort_by: sellerAnnouncementState.sortBy,
      sort_order: sellerAnnouncementState.sortOrder,
      search: sellerAnnouncementState.search,
      per_page: sellerAnnouncementState.currentPageSize,
      page: sellerAnnouncementState?.currentPage,
    });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/seller-announcement/");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  return {
    sellerAnnouncementData,
    sellerAnnouncementState,
    paginationOptions,
    isFetching,
    handlePagination,
    handlePageSize,
    handleDashboardRedirect
  };
};

export default useSellerAnnouncements;