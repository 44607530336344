import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useAgent = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "Agent",
      link: "/agent",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          admin_panel_settings{" "}
        </span>
      ),
      active: checkIfActiveRoute("/agent", true),
    },
    {
      title: "Announcement",
      link: "/agent/announcement",
      icon: <span className="material-symbols-outlined x4"> campaign </span>,
      active: checkIfActiveRoute("/agent/announcement", true),
    },
    {
      title: "Training Video",
      link: "/agent/training-video",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          cast_for_education{" "}
        </span>
      ),
      active: checkIfActiveRoute("/agent/training-video", true),
    },
    {
      title: "Training Session",
      link: "/agent/training-session",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          currency_exchange{" "}
        </span>
      ),
      active: checkIfActiveRoute("/agent/training-session", true),
    },
    {
      title: "Coupon",
      link: "/agent/agent-coupon",
      icon: (
        <span className="material-symbols-outlined x4"> local_activity </span>
      ),
      active: checkIfActiveRoute("/agent/agent-coupon", true),
    },
    {
      title: "Payouts",
      link: "/agent/agent-payouts",
      icon: <span className="material-symbols-outlined x4"> payments </span>,
      active: checkIfActiveRoute("/agent/agent-payouts", true),
    },
    {
      title: "Monthly Payout",
      link: "/agent/monthly-payouts",
      icon: (
        <span className="material-symbols-outlined x4"> local_activity </span>
      ),
      active: checkIfActiveRoute("/agent/monthly-payouts", true),
    },
    {
      title: "Payout History",
      link: "/agent/history",
      icon: (
        <span className="material-symbols-outlined x4"> local_activity </span>
      ),
      active: checkIfActiveRoute("/agent/history", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useAgent;
