import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { getAxiosInstance } from "../../../api";


const axiosBaseQuery =
     () =>
          async ({ params, endpoint, method, body }) => {

               const api = await getAxiosInstance();
               const getParams = (key) => {

                    if (params?.[key]) {
                         return `${key}=${params[key]}&`;
                    } else {
                         return "";
                    }
               };

               try {
                    const response = await api[method](
                         `${endpoint}?${getParams("start_date")}${getParams(
                              "end_date"
                            )}${getParams("role")}${getParams("status")}${getParams("filter")}${getParams("sort_by")}&sort_order=${params?.sort_order || "desc"
                         }&length=${params?.per_page || "10"}&${getParams("search")}page=${params?.page || 1
                         }`,
                         body
                    );

                    return { data: response.data };
               } catch (axiosError) {
                    let err = axiosError;

                    return {
                         data: err?.response?.data || err.message,
                    };
               }
          };

export const user = createApi({
     reducerPath: 'userApi',
     baseQuery: axiosBaseQuery(),
     tagTypes: ['user'],
     endpoints: (builder) => ({
          getUserListData: builder.query({
               query: (values) => ({
                    method: 'get',
                    params: values,
                    endpoint: `v1/admin`
               }),
               providesTags: ['user']
          }),
          updateUserStatus: builder.mutation({
               query: (body) => ({
                    method: "post",
                    body,
                    endpoint: `/admin/users/change/status`,
               }),
               // invalidatesTags: ["user"]
          }),

          getUserOrderListData: builder.query({
               query: (values) => ({
                    method: 'get',
                    params: values,
                    endpoint: `v1/admin-customer/orders?customer_id=${values?.user_id}`
               }),
               providesTags: ['user']
          }),
     })
})

export const { useGetUserListDataQuery, useUpdateUserStatusMutation, useGetUserOrderListDataQuery } = user;