import React, { useEffect, useState } from "react";
import {
  Input,
} from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../../user.module.scss";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getCountries } from "../../../../../store/slices/program/programPriceSlice";

export const ItemField = ({ formik, itmIndex, fields, isEdit, setPriceAmount }) => {
  const { handleRemoveFieldCS} = useItemField(
    fields,
    itmIndex,
    formik,
    isEdit
  );

  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    dispatch(getCountries())
      .unwrap()
      .then((result) => {
        setCountryList(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});
  }, []);

  let currencyList = [
    {
    label: "INR",
    value: "INR"
  },
  {
    label: "AED",
    value: "AED"
  },
]
const [inputClasses, setInputClasses] = useState({});

  const handleChange = (e) => {
    const newDate = e.target.value;

    // Update the Formik field value
    formik.setFieldValue(`package_price.${itmIndex}.offer_validity`, newDate);

    // Update the class based on whether a date is selected
    setInputClasses((prevClasses) => ({
      ...prevClasses,
      [itmIndex]: newDate ? "date-selected" : "", // Apply the class based on the value
    }));
  };
  return (
    <>
      <div className={`pro-mb-4 ${Style.box_root} `}>
        {formik?.values?.package_price?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className={` ${Style.add_more_field}  row gx-2`}>
          <div className="col-md-12">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Country *
              </label>
              <Select
                name="country_id"
                options={countryList} // replace with your country_id options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={countryList?.filter((m) => formik?.values?.package_price?.[itmIndex]?.country_id === m?.value)}
                className={`pro-input lg ${
                  formik.touched?.package_price?.[itmIndex]?.country_id &&
                  formik.errors?.package_price?.[itmIndex]?.country_id &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`package_price.${itmIndex}.country_id`)}
                // value={formik.values.package_price?.[itmIndex]?.country_id}
                
                onChange={(selectedOption) =>
                  formik.setFieldValue(`package_price.${itmIndex}.country_id`, selectedOption?.value)
                }
              />
              {formik.touched?.package_price?.[itmIndex]?.country_id &&
                formik.errors?.package_price?.[itmIndex]?.country_id && (
                <div className="error-text">{formik.errors?.package_price?.[itmIndex]?.country_id}</div>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Currency *
              </label>
              <Select
                name="currency"
                options={currencyList} // replace with your currency options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={currencyList?.filter((m) => formik?.values?.package_price?.[itmIndex]?.currency === m?.value)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(`package_price.${itmIndex}.currency`, selectedOption?.value)
                }
                className={`pro-input lg ${
                  formik.touched?.package_price?.[itmIndex]?.currency &&
                  formik.errors?.package_price?.[itmIndex]?.currency &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`package_price.${itmIndex}.currency`)}
                // value={formik.values.package_price?.[itmIndex]?.currency}
                
              />
              {formik.touched?.package_price?.[itmIndex]?.currency &&
                formik.errors?.package_price?.[itmIndex]?.currency && (
                <div className="error-text">{formik.errors?.package_price?.[itmIndex]?.currency}</div>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <Input
              type="text"
              id={`mrp_amount`}
              name={`mrp_amount`}
              label={`MRP`}
              onBlur={formik.handleBlur(`package_price.${itmIndex}.mrp_amount`)}
              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.mrp_amount &&
                formik.errors?.package_price?.[itmIndex]?.mrp_amount &&
                " error"
              }`}
              {...formik.getFieldProps(`package_price.${itmIndex}.mrp_amount`)}
              error={
                formik.touched?.package_price?.[itmIndex]?.mrp_amount &&
                formik.errors?.package_price?.[itmIndex]?.mrp_amount && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.mrp_amount}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.mrp_amount &&
                formik.errors?.package_price?.[itmIndex]?.mrp_amount && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.mrp_amount}
                  </span>
                )
              }
            />
          </div>

          <div className="col-md-12">
            <Input
              type="text"
              id={`offer_percentage`}
              name={`offer_percentage`}
              label={`Offer( In percentage)`}
              onChange={(e) => {
                formik.setFieldValue(`package_price.${itmIndex}.offer_percentage`, e?.target?.value)
                setPriceAmount(itmIndex,formik.values?.package_price?.[itmIndex]?.mrp_amount, e.target.value)
              }}
              onBlur={formik.handleBlur(`package_price.${itmIndex}.offer_percentage`)}

              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.offer_percentage &&
                formik.errors?.package_price?.[itmIndex]?.offer_percentage &&
                " error"
              }`}
              {...formik.getFieldProps(`package_price.${itmIndex}.offer_percentage`)}
              error={
                formik.touched?.package_price?.[itmIndex]?.offer_percentage &&
                formik.errors?.package_price?.[itmIndex]?.offer_percentage && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.offer_percentage}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.offer_percentage &&
                formik.errors?.package_price?.[itmIndex]?.offer_percentage && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.offer_percentage}
                  </span>
                )
              }
            />
          </div>

          <div className="col-md-12">
            <Input
              type="text"
              id={`offer_tag`}
              name={`offer_tag`}
              label={`Offer Tag`}
              onBlur={formik.handleBlur(`package_price.${itmIndex}.offer_tag`)}
              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.offer_tag &&
                formik.errors?.package_price?.[itmIndex]?.offer_tag &&
                " error"
              }`}
              {...formik.getFieldProps(`package_price.${itmIndex}.offer_tag`)}
              error={
                formik.touched?.package_price?.[itmIndex]?.offer_tag &&
                formik.errors?.package_price?.[itmIndex]?.offer_tag && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.offer_tag}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.offer_tag &&
                formik.errors?.package_price?.[itmIndex]?.offer_tag && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.offer_tag}
                  </span>
                )
              }
            />
          </div>

          <div className="col-md-12">
            <Input
              type="text"
              id={`price`}
              name={`price`}
              label={`Price`}
              disabled={true}
              onBlur={formik.handleBlur(`package_price.${itmIndex}.price`)}
              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.price &&
                formik.errors?.package_price?.[itmIndex]?.price &&
                " error"
              }`}
              {...formik.getFieldProps(`package_price.${itmIndex}.price`)}
              error={
                formik.touched?.package_price?.[itmIndex]?.price &&
                formik.errors?.package_price?.[itmIndex]?.price && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.price}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.price &&
                formik.errors?.package_price?.[itmIndex]?.price && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.price}
                  </span>
                )
              }
            />
          </div>
          <div className="col-md-12">
            <Input
              type="text"
              id={`tax`}
              name={`tax`}
              label={`Tax`}
              onBlur={formik.handleBlur(`package_price.${itmIndex}.tax`)}
              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.tax &&
                formik.errors?.package_price?.[itmIndex]?.tax &&
                " error"
              }`}
              {...formik.getFieldProps(`package_price.${itmIndex}.tax`)}
              error={
                formik.touched?.package_price?.[itmIndex]?.tax &&
                formik.errors?.package_price?.[itmIndex]?.tax && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.tax}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.tax &&
                formik.errors?.package_price?.[itmIndex]?.tax && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.tax}
                  </span>
                )
              }
            />
          </div>
          
          {/* <div className="col-md-12">
            <Input
              type="text"
              id={`price_without_offer`}
              name={`price_without_offer`}
              label={`Price Without Offer`}
              onBlur={formik.handleBlur(
                `package_price.${itmIndex}.price_without_offer`
              )}
              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.price_without_offer &&
                formik.errors?.package_price?.[itmIndex]?.price_without_offer &&
                " error"
              }`}
              {...formik.getFieldProps(
                `package_price.${itmIndex}.price_without_offer`
              )}
              error={
                formik.touched?.package_price?.[itmIndex]?.price_without_offer &&
                formik.errors?.package_price?.[itmIndex]?.price_without_offer && (
                  <span className="error-text">
                    {
                      formik.errors?.package_price?.[itmIndex]
                        ?.price_without_offer
                    }
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.price_without_offer &&
                formik.errors?.package_price?.[itmIndex]?.price_without_offer && (
                  <span className="error-text">
                    {
                      formik.errors?.package_price?.[itmIndex]
                        ?.price_without_offer
                    }
                  </span>
                )
              }
            />
          </div> */}

          
          <div className="col-md-12">
            <Input
              type="date"
              id={`offer_validity`}
              name={`offer_validity`}
              label={`Offer Validity`}
              value={formik?.values?.package_price?.[itmIndex]?.offer_validity}
              onBlur={formik.handleBlur(
                `package_price.${itmIndex}.offer_validity`
              )}
              className={`pro-input lg ${
                formik.touched?.package_price?.[itmIndex]?.offer_validity &&
                formik.errors?.package_price?.[itmIndex]?.offer_validity &&
                " error"
              }`}
              {...formik.getFieldProps(
                `package_price.${itmIndex}.offer_validity`
              )}
              error={
                formik.touched?.package_price?.[itmIndex]?.offer_validity &&
                formik.errors?.package_price?.[itmIndex]?.offer_validity && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.offer_validity}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.package_price?.[itmIndex]?.offer_validity &&
                formik.errors?.package_price?.[itmIndex]?.offer_validity && (
                  <span className="error-text">
                    {formik.errors?.package_price?.[itmIndex]?.offer_validity}
                  </span>
                )
              }
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemField;
