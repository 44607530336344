import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("startDate")}${getParams("endDate")}&status=${
          params?.status
        }${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.page_size}&${getParams("search")}&${getParams(
          "payout_batch_payment_id"
        )}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const payoutHistory = createApi({
  reducerPath: "payoutHistoryApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["payoutHistory"],
  endpoints: (builder) => ({
    getPayoutHistoryData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-agent/payouts-history`,
      }),
      providesTags: ["payoutHistory"],
    }),
    updateAgentStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/users/change/status`,
      }),
      // invalidatesTags: ["user"]
    }),
    getPayoutHistoryBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin-agent/payouts-batch-details`,
      }),
      providesTags: ["payoutHistory"],
    }),
    getUserOrderListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-customer/orders`,
      }),
      providesTags: ["orders"],
    }),
  }),
});

export const {
  useGetPayoutHistoryDataQuery,
  useUpdateAgentStatusMutation,
  useGetPayoutHistoryBasicDataQuery,
} = payoutHistory;
