import { useState } from "react";


export const useItemField = (
  {formik, is_edit},
) => {

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.questionnaire?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("questionnaire", data);
  };
  

  return {
    handleRemoveFieldCS,
  };
};

export default useItemField;
