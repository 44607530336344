import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAgentCouponsForm from "./useAgentCouponsForm";
import { capitalizeOnSpace } from "../../../../utils/functions/table";
import Select from "react-select";

const AgentCouponsForm = ({ refetch, closeModal, isStickyFooter }) => {
  const { formik, selectedItemsDetails, typeList } = useAgentCouponsForm({
    refetch,
    closeModal,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Code*"}
        type="text"
        id="code
          "
        name="code
          "
        className={`pro-input lg ${
          formik.errors.code && formik.touched.code && "error"
        }`}
        {...formik.getFieldProps("code")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.code && formik.touched.code}
        errorMessage={formik.errors.code}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Coupon Type *
        </label>
        <Select
          name="type"
          options={typeList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={typeList?.filter((m) => formik?.values?.type === m?.value)}
          onChange={(selectedOption) =>
            formik.setFieldValue(`type`, selectedOption?.value)
          }
          className={`pro-input lg ${
            formik.touched?.type && formik.errors?.type && " error"
          }`}
          classNamePrefix={`pro-input`}
          onBlur={formik.handleBlur(`type`)}
        />
        {formik.touched?.type && formik.errors?.type && (
          <div className="error-text">{formik.errors?.type}</div>
        )}
      </div>
      {formik.values?.type === 1 && (
        <Input
          label={"Available Count*"}
          type="text"
          id="available_count
          "
          name="available_count
          "
          className={`pro-input lg ${
            formik.errors.available_count &&
            formik.touched.available_count &&
            "error"
          }`}
          {...formik.getFieldProps("available_count")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={
            formik.errors.available_count && formik.touched.available_count
          }
          errorMessage={formik.errors.available_count}
        />
      )}

      <Input
        label={"Discount Percentage*"}
        type="text"
        id="discount_percentage
          "
        name="discount_percentage
          "
        className={`pro-input lg ${
          formik.errors.discount_percentage &&
          formik.touched.discount_percentage &&
          "error"
        }`}
        {...formik.getFieldProps("discount_percentage")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={
          formik.errors.discount_percentage &&
          formik.touched.discount_percentage
        }
        errorMessage={formik.errors.discount_percentage}
      />

      <Input
        label={"Description*"}
        type="text"
        id="description
          "
        name="description
          "
        className={`pro-input lg ${
          formik.errors.description && formik.touched.description && "error"
        }`}
        {...formik.getFieldProps("description")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.description && formik.touched.description}
        errorMessage={formik.errors.description}
      />

      {/* toggle button start  */}

      {/* <div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? 0
                );
              }}
            />
            <span></span>
          </div>
          <label>Status</label>
        </div> */}

      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default AgentCouponsForm;
