import { useDispatch, useSelector } from "react-redux";
// import { useGetRateEditDataQuery } from "../../../../../store/queries/branches";
import { updateFormData } from "../../../../../store/slices/Branches/branchRateFormSlice";
import { useEffect } from "react";
import { updateConfig } from "../../../../../store/slices/Branches/branchSlice";

const useBranchRateForm = () => {
  //   const [params] = useSearchParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { activeTab, CompletedPercentage, completedTabs, webId } = useSelector(
    (state) => state.branchRateForm
  );

  useEffect(() => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Buy";
      })
    );
  }, []);

  //   const activeUser = params.get("user");
  //   const edit = params.get("edit");
  //   const toTab = params.get("tab");
  //   const scrollTo = params.get("scroll_to");

  //   const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
  //     useGetRateEditDataQuery({});

  //   useEffect(() => {

  //     if (scrollTo) {
  //       const element = document.getElementById(scrollTo);

  //       if (element) {
  //         element.scrollIntoView({ behavior: "smooth" });
  //       }
  //     }
  //   });

  //   useEffect(() => {
  //     if (toTab) {
  //       dispatch(
  //         updateFormData((state) => {
  //           state.activeTab = toTab;
  //         })
  //       );
  //     }
  //     if (scrollTo == null) {
  //       window.scrollTo(0, 0);
  //     }
  //     // eslint-disable-next-line
  //   }, []);
  //   useEffect(() => {
  //     return () => {
  //       dispatch(
  //         updateFormData((state) => {
  //           state.currentFormDataBuy = {};
  //           state.currentFormDataSell = {};
  //           state.activeTab = "Buy";
  //           state.CompletedPercentage = {
  //             Buy: 0,
  //             Sell: 0,

  //           };
  //         })
  //       );
  //     };
  //     // eslint-disable-next-line
  //   }, [edit]);

  let tabs = [
    { label: "Buy", completed: CompletedPercentage.Buy },
    { label: "Sell", completed: CompletedPercentage.Sale },
  ];

  const handleTabClick = (tab) => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = tab.label;
      })
    );
  };

  return {
    tabs,
    activeTab,
    //     optionsData,
    webId,
    handleTabClick,
    //     isOptionDataSuccess,
    //     activeUser,
    //     edit,
    //     toTab,
  };
};

export default useBranchRateForm;
