import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";
import { settings } from "../../../../store/queries/settings";
import { product } from "../../../../store/queries/product";
import { logoutUser } from "./api";
import { roles } from "../../../../store/queries/roles";
import { toast } from "react-toastify";
import { getManageProfileData } from "../../../../store/slices/manageProfile/manageProfileSlice";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [activeUser, setActiveUser] = useState(
    JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found"
  );

  const [showModal, setShowModal] = useState(false);
  const [isProfileViewVisible, setProfileViewVisible] = useState(false);
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("USER_DETAILS"));
    if (storedUser) {
      setActiveUser(storedUser);
    }
  }, []);

  useEffect(() => {
    if (activeUser?.is_bank_account_verified !== 1 && activeUser?.id !== 1) {
      dispatch(getManageProfileData());
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [activeUser, dispatch]);

  const updateUserDetails = (newDetails) => {
    const updatedUser = { ...activeUser, ...newDetails };
    localStorage.setItem("USER_DETAILS", JSON.stringify(updatedUser));
    setActiveUser(updatedUser);
  };

  useEffect(() => {
    if (isProfileViewVisible) {
      dispatch(
        updateConfig((state) => {
          state.isProfileViewVisible = false;
          setProfileViewVisible(false);
        })
      );
    }
  }, [isProfileViewVisible, dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen((state) => !state);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleViewProfile = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = true;
      })
    );

    dispatch(getManageProfileData());
  };

  const handleCloseProfileView = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = false;
      })
    );
  };

  const handleLogout = () => {
    logoutUser().then((res) => {
      toast.success(res?.data?.message ?? "Logged out successfully");
    });
    dispatch(product.util.resetApiState());
    dispatch(roles.util.resetApiState());
    dispatch(settings.util.resetApiState());

    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");

    navigate("/");
  };

  const handleSettings = () => {
    navigate("/settings/appearance");
  };

  const handleProfileRedirect = (userId) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), userId]
      : [userId];

    const newIds = ids.filter((id) => id !== userId).join(",");

    window.open(
      `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    );
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    activeUser,
    userData: globalState?.searchData?.[activeUser],
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleProfileRedirect,
    handleSettings,
    handleViewProfile,
    handleCloseProfileView,
    setProfileViewVisible,
    isNewNotification: globalState.isNewNotification,
    showModal,
    updateUserDetails, // Expose the function to update user details
  };
};

export default useHeaderActions;
