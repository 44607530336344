import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useMonthlyPayouts from "./useMonthlyPayouts";
import EmptyData from "../../Global/EmptyData";

const MonthlyPayouts = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showEditModal,
    showCreateModal,
    hasUpdatePermission,
    hasDeletePermission,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDelete,
    handleSendPayoutAction,
    updateTableFields,
    closeModal,
    handleEditAction,
    generateList,
    handleSendPayout,
  } = useMonthlyPayouts();

  return (
    <>
      <HeadingGroup title={"Monthly Payouts"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              searchable={true}
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
            />
          </div>
          <div className="col-auto pro-ps-0 pro-d-flex pro-gap-3">
            {mainData?.data?.genarate_payout && (
              <button
                className="pro-btn pro-btn-primary "
                onClick={() => generateList()}
              >
                Generate
              </button>
            )}
            {mainData?.data?.send_payout && (
              <button
                className="pro-btn pro-btn-primary "
                onClick={() => handleSendPayout()}
              >
                Send Payouts
              </button>
            )}
          </div>
        </div>

        {mainData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            showCheckBox={false}
            deletable={hasDeletePermission === 1 ? true : true}
            handleDelete={handleDelete}
            handleEdit={handleEditAction}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.field}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
            editable={hasUpdatePermission === 1 ? true : true}
          />
        )}

        {mainData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.total_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          centered={false}
          show={showCreateModal}
          handleClose={closeModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to Send?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeModal}
              submitAction={handleSendPayoutAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default MonthlyPayouts;
