import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../../utils/hooks/useRouteUtils.js";
import { useGetStudentBasicDataQuery } from "../../../../../store/queries/SellerModule/myStudents/index.js";

const useStudentDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const ProfileState = useSelector((state) => state.myStudents);
  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");

  const navigation = [
    {
      label: "Goals",
      title: "Goals",
      link: `/student-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/student-details/${activeProfile}`, true),
    },
    {
      label: "Purchase History",
      title: "Purchase History",
      link: `/student-details/purchase-history/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/student-details/purchase-history/${activeProfile}`, true),
    },
    {
      label: "Feedback",
      title: "Feedback",
      link: `/student-details/feedback/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/student-details/feedback/${activeProfile}`, true),
    },
    {
      label: "Reported Issues",
      title: "Reported Issues",
      link: `/student-details/reported-issues/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/student-details/reported-issues/${activeProfile}`, true),
    },
  ];

  const navigate = useNavigate();

  const {
    data: basicData,
    isFetching,
    isError,
    refetch,
  } = useGetStudentBasicDataQuery({
    customer_id: activeProfile
  });

  useEffect(() => {
    if (basicData?.statusCode === 400 || basicData === "") {
        toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [basicData, activeProfile]);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const label = {
    name: "Full Name",
    email: "Email",
    dob: "Date of Birth",
    mobile: "Mobile",
  };

  const value = {
    name: `${basicData?.data?.name ?? "Not specified"}`,
    dob: `${
      basicData?.data?.date_of_birth ?? "Not specified"
    }`,
    mobile: `${
      basicData?.data?.mobile ?? "Not specified"
    }`,
    email: `${basicData?.data?.email ?? "Not specified"}`,
    
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    activeProfile,
    isFetching,
    basicData,
    basicDetails,
    ProfileState,
    refetch,
    navigation,
  };
};

export default useStudentDetailsLayout;
