import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React, {useState} from "react";
import useAddAgent from "./useAddAgent";
import Select from "react-select";
import countryData from "../../../../utils/components/countryCode";

const AddAgent = ({ refetch, closeModal, programId }) => {
  const { getFieldError, formik, is_edit} = useAddAgent({
    closeModal,
    refetch,
    programId,
  });

  const [dobClass, setDobClass] = useState("");
  const handleExpiryDateChange = (e) => {
    // Manually set the field value using formik.setFieldValue
    formik.setFieldValue("dob", e.target.value);

    // Update the class based on the value of the input
    setDobClass(e.target.value ? "date-selected" : "");
  };

  return (
    <div className={`row`}>
      
      <Input
        label={"First Name*"}
        type="text"
        id="first_name"
        name="first_name"
        className={`pro-input lg ${getFieldError("first_name") && " error"}`}
        {...formik.getFieldProps("first_name")}
        error={getFieldError("first_name")}
        errorMessage={getFieldError("first_name")}
      />
      <Input
        label={"Last Name*"}
        type="text"
        id="last_name"
        name="last_name"
        className={`pro-input lg ${
          getFieldError("last_name") && " error"
        }`}
        {...formik.getFieldProps("last_name")}
        error={getFieldError("last_name")}
        errorMessage={getFieldError("last_name")}
      />
      <Input
				label={"Email address*"}
				type="text"
				id="email"
				name="email"
				placeholder="name@example.com"
				className={`pro-input lg ${formik.errors.email && formik.touched.email && " error"
					}`}
				{...formik.getFieldProps("email")}
				error={formik.errors.email && formik.touched.email}
				errorMessage={formik.errors.email}
			/>

<div className="input-wrap pro-mb-4 ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country Code*
        </label>
        <Select
          id="country_code"
          options={countryData}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option.value}`}
          className={`pro-input lg multi-select ${
            formik.touched.country_code &&
            formik.errors.country_code &&
            " error"
          }`}
          value={countryData?.filter(
            (p) =>
              p.value?.toString() === formik.values.country_code?.toString()
          )}
          classNamePrefix="pro-input"
          onBlur={() => formik.handleBlur("country_code")}
          onChange={(value) =>
            formik.setFieldValue("country_code", value?.value)
          }
        ></Select>

        {formik.touched.country_code && formik.errors.country_code && (
          <span className="error-text">{formik.errors.country_code}</span>
        )}
      </div>
      <Input
        label={"Mobile Number*"}
        type="text"
        id="mobile"
        name="mobile"
        className={`pro-input lg ${
          formik.errors.mobile && formik.touched.mobile && " error"
        }`}
        {...formik.getFieldProps("mobile")}
        error={formik.touched.mobile && formik.errors.mobile}
        errorMessage={formik.errors.mobile}
        onKeyDown={(e) => {
          // Allow backspace (keyCode 8)
          if (e.keyCode === 8) {
            return;
          }

          const regex = /^[0-9]*$/; // Only allows digits
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={15}
      />

{/* <div className="col-md-12">
        <Input
          type="date"
          id={`dob`}
          name={`dob`}
          label={`DOB`}
          max={new Date().toISOString().split("T")[0]}
          value={formik?.values?.dob}
          onBlur={formik.handleBlur(`dob`)}
          className={`pro-input lg ${dobClass} ${
            formik.touched?.dob &&
            formik.errors?.dob &&
            " error"
          }`}
          {...formik.getFieldProps(`dob`)}
          error={
            formik.touched?.dob &&
            formik.errors?.dob && (
              <span className="error-text">{formik.errors?.dob}</span>
            )
          }
          errorMessage={
            formik.touched?.dob &&
            formik.errors?.dob && (
              <span className="error-text">{formik.errors?.dob}</span>
            )
          }
          onChange={handleExpiryDateChange}
        />
      </div> */}

      <div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? 0
                );
              }}
            />
            <span></span>
          </div>
          {/* <label>Status</label> */}
        </div>

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={() => closeModal()}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddAgent;
