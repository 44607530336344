import { useState } from "react";
import { toast } from "react-toastify";
import { validateStudentEmail } from "../api";
import { emailRegExp } from "../../../../../utils/functions/table";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/SellerModule/MyStudents/myStudentsSlice";

const useCaptureEmail = ({ refetch }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (email) {
      if (email?.length > 1 && !emailRegExp.test(email)) {
        setErrors("Enter a valid email");
      } else {
        setErrors("");
        validateStudentEmail({ email: email }).then((response) => {
          if (response?.data.success) {
            dispatch(updateConfig((state) => (state.enteredEmail = email)));
            toast.success("OTP sent to your given Mail Id!");
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
                state.showOtpModal = true;
              })
            );
          } else if (response?.status_code === 422) {
            // toast.error(response?.message ?? "The selected email is ivalid");
            setErrors(response?.message ?? "The selected email is invalid");
          } else {
            // toast.error(response?.message ?? "The selected email is ivalid");
            setErrors("The selected email is invalid");
          }
        });
      }
    } else {
      setErrors("*Email cannot be empty");
    }
  };

  return {
    email,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
  };
};

export default useCaptureEmail;
