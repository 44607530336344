import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import useStudents from "./useStudents";
import EmptyData from "../../../Global/EmptyData";

const Students = () => {
  const {
    isLoading,
    isFetching,
    studentList,
    paginationOptions,
    studentState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
    updateTableFields,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditColumnsClick,
  } = useStudents();
  
  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: studentState?.search }}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
        />
        <div className={`pro-pb-5`}>
          {studentList?.data?.rows?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={studentList?.data?.rows?.data || []}
              uniqueID={"id"}
              fields={studentList?.data?.field || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={studentState?.clearSelection}
              perpage={studentState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
        </div>

        {studentList?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={studentState?.currentPage}
            totalPageCount={Math.ceil(
              studentList?.data?.rows?.total / studentState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(
                studentList?.data?.field ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = studentList?.data?.field[property];
                return filteredObj;
              }, {})}
              moduleId={studentList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Students;
