import React from "react";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useAgentTraining = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const location = useLocation();
  const route =
    location?.pathname === "/training/upcoming"
      ? "/training/upcoming"
      : "/training/enrolled"
      ? "/training/enrolled"
      : "";

  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "Training",
      link: "/training/upcoming",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          category_search{" "}
        </span>
      ),
      active: checkIfActiveRoute(route, true),
    },
    {
      title: "My Learning",
      link: "/training/my-learning",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          task{" "}
        </span>
      ),
      active: checkIfActiveRoute("/training/my-learning", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useAgentTraining;
