import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  getCategories,
  getVisaTypes,
} from "../../../../store/slices/program/programFormSlice.js";
import { useDispatch } from "react-redux";
import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../user.module.scss";
import { updateConfig } from "../../../../store/slices/program/programFormSlice";
import { toast } from "react-toastify";
import { limitStrLength } from "../../../../utils/functions/table.js";

import { SketchPicker } from "react-color";
import ColorPicker from "../../../Global/ColorPricker/index.js";
const BasicForm = ({
  formik,
  setActiveTab,
  
  tabs,
  setShowform,
  selectedItemsDetails,
  selectedData
}) => {
  const dispatch = useDispatch();

  const [categoryList, setCategoryList] = useState([]);
  const profilefileInputRef = useRef(null);

  const [imageThumbnailPreview, setImageCoverPreview] = useState(
    selectedItemsDetails ? selectedData?.module_image : ""
  );

  const [imageWebPreview, setImageWebPreview] = useState(
    selectedItemsDetails ? selectedData?.web_image : ""
  );
  const [imageWebBackPreview, setImageWebBackPreview] = useState(
    selectedItemsDetails ? selectedData?.web_background_image : ""
  );

  const [thumbnail, setThumbnail] = useState(
    selectedItemsDetails ? selectedData?.module_video : null
  );

  const [currentColor, setCurrentColor] = useState(
    formik?.values?.colour_code
  );

  useEffect(() => {
		if (selectedItemsDetails !== "") {
			setImageCoverPreview(selectedData?.module_image ?? "");
			setThumbnail(selectedData?.module_video ?? "");
      setImageWebPreview(selectedData?.web_image ?? "");
      setImageWebBackPreview(selectedData?.web_background_image ?? "");
		}
	}, [selectedItemsDetails]);

  useEffect(() => {
    if(formik?.values?.colour_code) {
      setCurrentColor(formik?.values?.colour_code);
    } 
    
  }, [formik?.values?.colour_code]);

  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
    formik.setFieldValue("colour_code", color.hex);
  }

  useEffect(() => {
    setCurrentColor(formik?.values?.colour_code);
    dispatch(getCategories())
      .unwrap()
      .then((result) => {
        setCategoryList(
          result.data.data?.map((item) => ({
            label: item.category_name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});
  }, []);

  const handleAddField = () => {
    let { module_benefits } = formik?.values;
    module_benefits = [
      ...module_benefits,
      {
        title: "",
      },
    ];
    formik.setFieldValue("module_benefits", module_benefits);
  };

  const handleThumbnailImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("module_image", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageCoverPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "module_image",
          "The Module image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "module_image",
        "The Module image must be less than 5MB in size."
      );
    }
  };

  const handleWebImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("web_image", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageWebPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "web_image",
          "The Web image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "web_image",
        "The Web image must be less than 5MB in size."
      );
    }
  };

  const handleWebBackgroundImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("web_background_image", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageWebBackPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "web_background_image",
          "The Web background image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "web_background_image",
        "The Web background image must be less than 5MB in size."
      );
    }
  };

  const dataURLtoBlob = async (dataURL) => {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleVideosChange = async (e) => {
    const files = e?.target?.files[0];
    const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
    if (!allowedFormats.includes(files.type)) {
      formik.setFieldError("module_video", "invalid file type");
      return;
      // errors.push(`File ${file.name} is not a valid video format`);
    } else if (files.size > maxSizeInBytes) {
      // errors.push(`File ${file.name} exceeds the 10 MB limit`);
      formik.setFieldError("module_video", "invalid file size");
      return;
    }

    if (allowedFormats.includes(files.type)) {
      formik.setFieldValue("module_video", e?.target?.files?.[0]);
    }
    const thumbnailFile = await generateThumbnail(files);

    const reader = new FileReader();

    reader.onloadend = () => {
      setThumbnail(reader.result);
    };

    if (thumbnailFile) {
      reader.readAsDataURL(thumbnailFile);
    }

    // Function to generate thumbnail using async/await
    async function generateThumbnail(file) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadeddata = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          video.currentTime = 2; // Set the time to 1 second (adjust if needed)
          video.play().then(async () => {
            video.pause();
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // Here, you can use imlet gData or perform further actions with the second frame
            let blob = await dataURLtoBlob(canvas.toDataURL());
            const thumbnailFile = new File([blob], `thumbnail.jpg`, {
              type: "image/jpeg",
            });
            resolve(thumbnailFile);
          });
        };
        video.src = URL.createObjectURL(file);
        video.load();
      });
    }
  };

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.module_benefits?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("module_benefits", data);
  };

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Category *
          </label>

          <Select
            id="category_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.category_id &&
              formik.errors.category_id &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={categoryList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={categoryList?.filter(
              (m) => formik.values.category_id === m?.value
            )}
            onBlur={formik.handleBlur("category_id")}
            onChange={(selectedOption) =>
              formik.setFieldValue("category_id", selectedOption?.value)
            }
          />
          {formik.touched?.category_id && formik.errors?.category_id && (
            <div className="error-text">{formik.errors?.category_id}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Program Name *
          </label>
          <input
            type="text"
            name="module_name"
            placeholder="Program Name"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors?.module_name &&
              formik.touched?.module_name &&
              "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.module_name}
          />
          {formik.touched?.module_name && formik.errors?.module_name && (
            <div className="error-text">{formik.errors?.module_name}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description *
          </label>
          <input
            type="text"
            name="module_description"
            placeholder="Description"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors?.module_description &&
              formik.touched?.module_description &&
              "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.module_description}
          />
          {formik.touched?.module_description &&
            formik.errors?.module_description && (
              <div className="error-text">
                {formik.errors?.module_description}
              </div>
            )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Benefits *
        </label>
        <div className={`pro-p-4 pro-h-100 ${Style.box_root}`}>
          <div className={Style.add_form_wrap}>
            {formik?.values?.module_benefits?.map((fields, index) => (
              <div className={Style.box_root_inner}>
                {formik?.values?.module_benefits?.length !== 1 && (
                  <button
                    className={`${Style.btn_close} btn-close`}
                    onClick={() => handleRemoveFieldCS(index)}
                  ></button>
                )}
                <div className="row gx-2">
                  <div className="col-md-12">
                    <Input
                      type="text"
                      id={`title`}
                      name={`title`}
                      label={`Title`}
                      onBlur={formik.handleBlur(
                        `module_benefits.${index}.title`
                      )}
                      className={`pro-input lg ${
                        formik.touched?.module_benefits?.[index]?.title &&
                        formik.errors?.module_benefits?.[index]?.title &&
                        " error"
                      }`}
                      {...formik.getFieldProps(
                        `module_benefits.${index}.title`
                      )}
                      error={
                        formik.touched?.module_benefits?.[index]?.title &&
                        formik.errors?.module_benefits?.[index]?.title && (
                          <span className="error-text">
                            {formik.errors?.module_benefits?.[index]?.title}
                          </span>
                        )
                      }
                      errorMessage={
                        formik.touched?.module_benefits?.[index]?.title &&
                        formik.errors?.module_benefits?.[index]?.title && (
                          <span className="error-text">
                            {formik.errors?.module_benefits?.[index]?.title}
                          </span>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            type="button"
            onClick={handleAddField}
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Module Image*
        </label>
        <div className={`input-drag ${imageThumbnailPreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched?.module_image &&
              formik.errors?.module_image &&
              " error"
            }`}
            id="module_image"
            name="module_image"
            onBlur={formik.handleBlur("module_image")}
            onChange={(e) => handleThumbnailImage(e)}
          />
          <span className="input-drag-box">
            {!imageThumbnailPreview ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              <div className="img-wrap">
                {/* <span className="img-close">&#10006;</span> */}
                <Image
                  width={100}
                  height={100}
                  src={
                    imageThumbnailPreview
                      ? imageThumbnailPreview
                      : formik?.values?.module_image_path
                  }
                  alt={`Program_image`}
                />
              </div>
            )}
          </span>
          {formik.touched?.module_image && formik.errors?.module_image && (
            <span className="error-text">{formik.errors?.module_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}></div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Web Image*
        </label>
        <div className={`input-drag ${imageWebPreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched?.web_image &&
              formik.errors?.web_image &&
              " error"
            }`}
            id="web_image"
            name="web_image"
            onBlur={formik.handleBlur("web_image")}
            onChange={(e) => handleWebImage(e)}
          />
          <span className="input-drag-box">
            {!imageWebPreview ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              <div className="img-wrap">
                {/* <span className="img-close">&#10006;</span> */}
                <Image
                  width={100}
                  height={100}
                  src={
                    imageWebPreview
                      ? imageWebPreview
                      : formik?.values?.web_image
                  }
                  alt={`web_image`}
                />
              </div>
            )}
          </span>
          {formik.touched?.web_image && formik.errors?.web_image && (
            <span className="error-text">{formik.errors?.web_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}></div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Web Background Image*
        </label>
        <div className={`input-drag ${imageWebBackPreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched?.web_background_image &&
              formik.errors?.web_background_image &&
              " error"
            }`}
            id="web_background_image"
            name="web_background_image"
            onBlur={formik.handleBlur("web_background_image")}
            onChange={(e) => handleWebBackgroundImage(e)}
          />
          <span className="input-drag-box">
            {!imageWebBackPreview ? (
              <IconText title={`Drop files to attach or browse`} />
            ) : (
              <div className="img-wrap">
                {/* <span className="img-close">&#10006;</span> */}
                <Image
                  width={100}
                  height={100}
                  src={
                    imageWebBackPreview
                      ? imageWebBackPreview
                      : formik?.values?.web_background_image
                  }
                  alt={`web_background_image`}
                />
              </div>
            )}
          </span>
          {formik.touched?.web_background_image && formik.errors?.web_background_image && (
            <span className="error-text">{formik.errors?.web_background_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}></div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Related Disease *
          </label>
          <input
            type="text"
            name="related_disease"
            placeholder="Related Disease"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors?.related_disease &&
              formik.touched?.related_disease &&
              "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.related_disease}
          />
          {formik.touched?.related_disease &&
            formik.errors?.related_disease && (
              <div className="error-text">
                {formik.errors?.related_disease}
              </div>
            )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Related Disease Description *
          </label>
          <input
            type="text"
            name="related_disease_description"
            placeholder="Description"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors?.related_disease_description &&
              formik.touched?.related_disease_description &&
              "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.related_disease_description}
          />
          {formik.touched?.related_disease_description &&
            formik.errors?.related_disease_description && (
              <div className="error-text">
                {formik.errors?.related_disease_description}
              </div>
            )}
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Color Code*
        </label>
        <SketchPicker 
        color={currentColor}
        onChangeComplete={handleColorChange}
        
        />
        {/* <ColorPicker
        id={'colour'}
          name={'colour'}
          onChange={(color) => formik.setFieldValue("colour", color)} 
          value={formik?.values?.colour}
          // isError={getFieldError("colour")}
          // errorMessage={getFieldError("colour")}
          onBlur={formik.handleBlur}
        /> */}
      </div>

      <div className={Style.wrapper}>
        <label className="pro-mb-1 pro-font-sm pro-fw-medium">
          Module Video*
        </label>
        <div
          className={`pro-d-flex pro-input  pro-justify-center pro-flex-wrap pro-gap-3 pro-p-2 upload-wrap ${
            Style.upload_wrap
          } video-upload-wrap ${thumbnail ? "active" : ""}`}
        >
          <></>
          <label
            for="video-upload"
            className={`pro-w-100 pro-h-100 stretched-label`}
          ></label>
          {/* {showAddButton && ( */}
          <div
            className={`input-wrap  ${
              formik.touched?.module_video &&
              formik.errors?.module_video &&
              " error"
            }`}
          >
            {!thumbnail ? (
              <div className="pro-d-flex pro-justify-center pro-items-center pro-flex-column  pro-text-center">
                <span className="material-symbols-outlined x3">backup</span>
                <span className={`${Style.add_text} pro-fw-medium `}>
                  "Add Video"
                </span>
              </div>
            ) : (
              <div className="pro-input pro-d-flex pro-h-100 pro-justify-between pro-items-center">
                <div className="file-name ">{thumbnail.substring(0,30)}</div>
                {/* <button  type="button" className="btn-close"></button> */}
              </div>
            )}
            <input
              type="file"
              className={`${Style.file} pro-d-none`}
              onChange={handleVideosChange}
              id="video-upload"
            />
          </div>
          {/* )} */}
          {formik.touched?.module_video && formik.errors?.module_video && (
            <span className="error-custom">{formik.errors?.module_video}</span>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-mt-5 "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="button"
          // disabled={
          //   formik?.errors?.firstName != undefined ||
          //   formik?.errors?.lastName != undefined ||
          //   formik?.errors?.mobileNumber != undefined ||
          //   formik?.errors?.email != undefined ||
          //   formik?.errors?.dateOfBirth != undefined ||
          //   formik?.errors?.nationality != undefined
          // }
          onClick={() => {
            // setActiveTab(tabs[1]);
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = tabs[1].label;
              })
            );
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;
