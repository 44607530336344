import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateConfig as globalUpdateConfig } from "../../../../../store/slices/Global";
import { updateConfig } from "../../../../../store/slices/SellerModule/StudentIssues/studentIssuesSlice";
import { toast } from "react-toastify";
import { useGetStudentIssuesListQuery } from "../../../../../store/queries/SellerModule/studentIssues";

const useStudentIssues = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { studentID } = useParams();
  const navigate = useNavigate();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const issueState = useSelector((state) => state.studentIssues);
  const { tableFields } = useSelector((state) => state.global);
  const {
    data: issueList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetStudentIssuesListQuery({
    customer_id: activeProfile,
    page_size: issueState?.currentPageSize,
    page: issueState?.currentPage,
    sort_by: issueState?.sortBy,
    sort_order: issueState?.sortOrder,
    search: issueState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  

  const handleSort = (label) => {
    if (issueState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = issueState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (feild, data) => {
        return (
          <div
          >
              {data?.["country"]?.name}
          </div>
        );
      },
      
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    issueList,
    paginationOptions,
    issueState,
    handlePagination,
    handlePageSize,
    handleSort,
    refetch,
    getRow,
    handleEditColumnsClick,
  };
};

export default useStudentIssues;
