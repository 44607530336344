import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: {},
  is_edit: false,
  customer_id: "",
  start_date: "",
    end_date: "",
    status: "",
    coupon_type: "",
};

export const getEditDataByID = createAsyncThunk(
  "/v1/agent-coupon/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/agent-coupon/edit?id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewSellerCoupon = createAsyncThunk(
  "new/createNewCoupon",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/agent-coupon/store`, body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSellerCoupon = createAsyncThunk(
  "v1/admin-coupon/update",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/agent-coupon/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSellerCoupon = createAsyncThunk(
  "/v1/admin-coupon/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/agent-coupon/delete`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sellerCouponsSlice = createSlice({
  name: "sellerCoupons",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.start_date= action.payload.start_date;
      state.end_date= action.payload.end_date;
      state.status= action.payload.status;
      state.coupon_type= action.payload.coupon_type;
    },
    resetExtraFilters: (state, action) => {
      state.start_date= '';
      state.end_date= '';
      state.status= '';
      state.coupon_type= '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditDataByID.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEditDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getEditDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } = sellerCouponsSlice.actions;

export default sellerCouponsSlice.reducer;
