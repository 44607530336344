import { useState } from "react";


export const useItemField = (
  {formik, is_edit},
) => {

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.sub_module_content?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("sub_module_content", data);
  };

  const dataURLtoBlob = async (dataURL) => {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleVideosChange = async (e, itmIndex) => {
    
    const files = e?.target?.files[0];
    const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
    if (!allowedFormats.includes(files.type)) {
      formik.setFieldError(`sub_module_content.${itmIndex}.content`, "invalid file type");
      return;
      // errors.push(`File ${file.name} is not a valid video format`);
    } else if (files.size > maxSizeInBytes) {
      // errors.push(`File ${file.name} exceeds the 10 MB limit`);
      formik.setFieldError(`sub_module_content.${itmIndex}.content`, "invalid file size");
      return;
    }

    if(allowedFormats.includes(files.type)) {
      formik.setFieldValue(`sub_module_content.${itmIndex}.content`, e?.target?.files?.[0]);
    }
  };

  const handleAudioChange = async (e, itmIndex) => {
    const files = e?.target?.files[0];
    const allowedFormats = ["audio/mpeg", "audio/wav", "audio/ogg", "audio/mp3"]; // Add more audio formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
    if (!allowedFormats.includes(files.type)) {
      formik.setFieldError(`sub_module_content.${itmIndex}.content`, "invalid file type");
      return;
    } else if (files.size > maxSizeInBytes) {
      formik.setFieldError(`sub_module_content.${itmIndex}.content`, "invalid file size");
      return;
    }
  
    formik.setFieldValue(`sub_module_content.${itmIndex}.content`, e?.target?.files?.[0]);
  
    const reader = new FileReader();
  
    reader.onloadend = () => {
      // You can handle further processing if needed
    };
  
    reader.readAsDataURL(files); // Read the audio file
  };
  
  

  return {
    handleRemoveFieldCS,
    handleVideosChange,
    handleAudioChange
  };
};

export default useItemField;
