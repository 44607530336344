import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("type")}${getParams("filter")}${getParams(
          "sort_by"
        )}&sort_order=${params?.sort_order || "desc"}&length=${
          params?.page_size || "10"
        }&${getParams("search")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const testimonial = createApi({
  reducerPath: "testimonialsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["testimonial"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin-testimonials`,
      }),
      providesTags: ["testimonial"],
    }),
    createNew: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/frame-type/create`,
      }),
      invalidatesTags: ["testimonial"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["testimonial"],
    }),
    updateStatusById: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin-testimonials/status-change`,
      }),

      // invalidatesTags: ["testimonials"],
    }),
  }),
});

export const {
  useGetListDataQuery,
  useCreateNewMutation,
  useUpdateTableFieldsDataMutation,
  useUpdateStatusByIdMutation,
} = testimonial;
