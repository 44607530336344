import { useSelector } from "react-redux";

const useProductsPage = () => {
  const { productData } = useSelector((state) => state.order);

  const field = {
    name: {
      label: "Name",
      position: 1,
    },
    price: {
      label: "Price",
      position: 2,
    },
  };

  const getRow = (field, data) => {
    const rows = {
      name: (field, data) => (
        <p className="pro-mb-0">
          <span className="pro-me-1">{data[field]}</span>
          <span
            className={`pro-badge ${
              data?.type === "Package"
                ? "badge-success-outline"
                : "badge-warning-outline"
            }`}
          >
            {data?.type}
          </span>
        </p>
      ),

      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  return { productData, field, getRow };
};

export default useProductsPage;
