import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import CreateRoleModal from "./CreateRoleModal";
import useRoles from "./useRoles";
import OrderColumn from "../../Global/OrderColumn";

const Roles = () => {
  const {
    showModal,
    roles,
    rolesState,
    isFetching,
    hasCreatePermission,
    showEditModal,
    showDeleteConfirm,
    hasEditPermission,
    hasDeletePermission,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleSearch,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
    handleEditClick,
    refetch,
    closeEditModal,
    updateTableFields,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
  } = useRoles();

  return (
    <>
      <HeadingGroup
        title={"Role"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ? "Create" : ""}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          searchable={true}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditClick}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
        />

        <Table
          data={roles?.data?.rows?.data || []}
          deletable={hasDeletePermission || hasDeletePermission}
          assignable={false}
          uniqueID={"id"}
          fields={roles?.data?.field}
          showCheckBox={hasEditPermission}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          loading={isFetching}
          perpage={rolesState?.currentPageSize}
          multiSelect={false}
          clear={rolesState.clearSelection}
        />

        {roles?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={rolesState?.currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === rolesState?.currentPageSize
            )}
            totalPageCount={roles?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          centered={false}
          show={showModal}
          handleClose={closeModal}
          title={"Create Role"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <CreateRoleModal />
        </ModalLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(roles?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = roles?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={roles?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Roles;
