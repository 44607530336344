import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
  getTypeList,
} from "../../../../store/slices/Coupons/couponsSlice.js";

const useAgentCouponsForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit,selectedId, selectedItemsDetails } = useSelector((state) => state.coupons);


  const [typeList, setTypeList] = useState([]);

    useEffect(() => {
      dispatch(getTypeList())
        .unwrap()
        .then((result) => {
          setTypeList(
            result.data.data?.coupon_type?.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          );
        })
        .catch((err) => {});
    }, []);

  const validation = Yup.object({
    code: Yup.string()
      .required("*Required"),
      type: Yup.string().required("Select one"),
    //   available_count: Yup.string()
    // .when('type', {
    //   is: 2,
    //   then: Yup.string().required("*Required"),
    //   otherwise: Yup.string()
    // }),

    available_count: Yup.string().test(
      "conditional-validation",
      "*Required",
      function (value) {
        const typeId = this.parent.type;
        if (typeId == 1) {
          return value !== undefined && value !== null;
        }
        return true;
      }
    ),

    discount_percentage: Yup.string()
    .required("*Required")
    .test(
      'max',
      'Maximum value is 30',
      (value) => parseFloat(value) <= 30
    ),

      description: Yup.string()
      .min(2, "The description must be at least 2 characters")
      .max(500, "max 500 characters allowed")
      .required("*Required"),
  });


  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
      ? selectedItemsDetails.id
      : "",
      
      code:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.code
          : "",
      discount_percentage:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.discount_percentage
          : "",
      description:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.description
          : "",
      type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.type
          : "",
      available_count:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.available_count
          : "",
      
      // status: selectedItemsDetails !== "" ? selectedItemsDetails?.status !== null ? selectedItemsDetails?.status : 0 : '',
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        code: values.code,
        description: values.description,
        type: values.type,
        discount_percentage: values?.discount_percentage
        
      };

      if(values?.type == 1) {
        obj.available_count = values?.available_count;
      }
      
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        // formData.append("_method", "PUT");
        dispatch(
          updateData({data: formData })
        ).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            toast.error(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            toast.error(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
    is_edit,
    typeList
  };
};

export default useAgentCouponsForm;
