import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
};

export const createNewEntry = createAsyncThunk(
  "/admin/delivery/types/create",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/delivery/types/create", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateData = createAsyncThunk(
  "/admin/delivery/types/update",
  async ( {data} , { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/delivery/types/update`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  
});

export const { updateConfig } = deliverySlice.actions;

export default deliverySlice.reducer;
