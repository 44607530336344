import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useDailyMeditation from "./useDailyMeditation";

const DailyMeditation = () => {
  const { drawerMenu } = useDailyMeditation();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default DailyMeditation;
