import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const today = new Date();
const startOfWeek = new Date(today); // Create a new Date object for the start of the week

// Set the start date to the previous Monday
startOfWeek.setDate(today.getDate() - 6);

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: startOfWeek,
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  agent_id: "",
  status: "",
  verification_status: "",
  showPayModal: false,
};

export const getEditDataByID = createAsyncThunk(
  "/v1/admin-agent/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin-agent/edit?agent_id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewEntry = createAsyncThunk(
  "/v1/admin-agent/create",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin-agent/create", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateData = createAsyncThunk(
  "/v1/admin-agent/update",
  async ({ data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-agent/update`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const docVerify = createAsyncThunk(
  "/v1/admin-agent/verify",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-agent/verify`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/admin-agent/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-agent/delete?agent_id=${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.status = action.payload.status;
      state.verification_status = action.payload.verification_status;
    },
    resetExtraFilters: (state, action) => {
      state.startDate = "";
      state.endDate = "";
      state.status = "";
      state.verification_status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditDataByID.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEditDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getEditDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  agentSlice.actions;

export default agentSlice.reducer;
