import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import { capitalizeOnSpace } from "../../../../utils/functions/table";
import useUserForm from "./useUserForm";
import Select from "react-select";
import Style from "./userForm.module.scss";
import { limitStrLength } from "../../../../utils/functions/table";
import { useRef, useState } from "react";
const UserForm = ({
  refetch,
  closeModal,
  isStickyFooter,
  formData,
  hasEditPermission,
}) => {
  const {
    formik,
    selectedItemsDetails,
    handleProfilePhoto,
    profileImagePreview,
    profilefileInputRef,
    is_edit,
  } = useUserForm({ refetch, closeModal });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const passwordInputRef = useRef(null); // Reference to the password input field

  const handlePasswordVisibility = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsPasswordVisible((prevState) => !prevState);

    // Temporarily change the input type without resetting the value
    if (passwordInputRef.current) {
      const input = passwordInputRef.current;
      const currentValue = input.value;
      input.type = isPasswordVisible ? "password" : "text";
      input.value = currentValue;
    }
  };

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"First Name*"}
        type="text"
        id="first_name
          "
        name="first_name
          "
        className={`pro-input lg ${
          formik?.errors?.first_name && formik?.touched?.first_name && "error"
        }`}
        {...formik.getFieldProps("first_name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.first_name && formik?.touched?.first_name}
        errorMessage={formik?.errors?.first_name}
        disabled={hasEditPermission ? false : true}
      />
      <Input
        label={"Last Name*"}
        type="text"
        id="last_name
          "
        name="last_name
          "
        className={`pro-input lg ${
          formik?.errors?.last_name && formik?.touched?.last_name && "error"
        }`}
        {...formik.getFieldProps("last_name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.last_name && formik?.touched?.last_name}
        errorMessage={formik?.errors?.last_name}
        disabled={hasEditPermission ? false : true}
      />

      <Input
        label={"Email*"}
        type="email"
        id="email
          "
        name="email
          "
        className={`pro-input lg ${
          formik?.errors?.email && formik?.touched?.email && "error"
        }`}
        {...formik.getFieldProps("email")}
        // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.email && formik?.touched?.email}
        errorMessage={formik?.errors?.email}
        disabled={hasEditPermission ? false : true}
      />

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Role *
          </label>
          <Select
            id="role_id"
            isClearable={true}
            placeholder={"Select"}
            options={formData?.role}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            className={`pro-input lg ${
              formik.errors.role_id && formik.touched.role_id && "error"
            }`}
            value={formData?.role?.filter(
              (p) => p.id === formik.values.role_id
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur}
            onChange={(value) =>
              formik.setFieldValue(`role_id`, value?.id || null)
            }
          />
          {formik.touched.role_id && formik.errors.role_id && (
            <div className="error-text">{formik.errors.role_id}</div>
          )}
        </div>
      </div>

      <Input
        label={"Personal Number*"}
        type="text"
        id="phone_number
          "
        name="phone_number
          "
        className={`pro-input lg ${
          formik?.errors?.phone_number &&
          formik?.touched?.phone_number &&
          "error"
        }`}
        {...formik.getFieldProps("phone_number")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.phone_number && formik?.touched?.phone_number}
        errorMessage={formik?.errors?.phone_number}
      />

      {!is_edit && (
        <div className="input-wrap pro-mb-5 p-re">
          <label
            htmlFor="password"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Password*
          </label>
          <div className="password-wrapper pro-w-100">
            <input
              name="password"
              id="password"
              ref={passwordInputRef} // Attach the ref to the password input
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={isPasswordVisible ? "text" : "password"}
              className={`pro-input lg pro-w-100 ${
                formik.errors.password && formik.touched.password && "error"
              } hide-icon-input`}
            />
            <button
              className={`hide-icon`}
              onClick={handlePasswordVisibility}
              type="button"
              tabIndex="-1"
            >
              <span className="material-symbols-outlined">
                {isPasswordVisible ? "visibility_off" : "visibility"}
              </span>
            </button>
          </div>
          {formik.errors.password && formik.touched.password && (
            <span className="error-text">{formik?.errors?.password}</span>
          )}
        </div>
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Profile Photo
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="profile_url"
            name="profile_url"
            onBlur={formik.handleBlur("profile_url")}
            onChange={(e) => handleProfilePhoto(e)}
            disabled={hasEditPermission ? false : true}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.profile_image?.name !== undefined
                  ? limitStrLength(formik?.values?.profile_image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.profile_image_name
                  ? selectedItemsDetails?.profile_image_name
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched?.profile_image && formik?.errors?.profile_image && (
            <span className="error-text">{formik?.errors?.profile_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={profileImagePreview}
            alt={`branch - 01`}
          />
        </div>
      </div>
      {/* toggle button start  */}
      {/* {is_edit && ( */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? 0
              );
            }}
            // disabled={hasEditPermission ? false : true}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      {/* )} */}

      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        {hasEditPermission !== 0 && (
          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            // disabled={hasUpdatePermission === true ? false : true}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserForm;
