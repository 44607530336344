import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { registerNowTraining } from "../../../../../store/slices/SellerModule/SellerTraining/sellerTrainingSlice";

const useRegisterNowForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.sellerTraining);
  

  const registerNow = async () => {
    try {
      const response = await dispatch(
        registerNowTraining({ data: { session_id: selectedItemsDetails?.id } })
      ).unwrap();
      if(response?.status_code === 200) {
        toast.success(response?.message);
        closeModal();
        refetch();
      }
      else {
        toast.error(response?.message);
        closeModal();
        refetch();
      }
    } catch (error) {
      toast.error("Registration failed", error);
    }
  };
  

  return {
    registerNow,
    selectedItemsDetails
  };
};

export default useRegisterNowForm;
