import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ItemField from "../MultiForm/ItemFieldClick";
import Style from "../user.module.scss";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPrice, updateConfig } from "../../../../store/slices/program/programFormSlice";
const PriceForm = ({ formik, setShowform, isLoading, checkError, tabs }) => {
  const dispatch = useDispatch();
  const handleAddField = () => {
    let { module_price } = formik?.values;
    module_price = [
      ...module_price,
      {
        country_id: "",
        price: "",
        currency: "",
        tax: "",
        mrp_amount: "",
        price_without_offer: "",
        offer_tag: "",
        offer_validity: "",
      },
    ];
    formik.setFieldValue("module_price", module_price);
  };

  const setPriceAmount = (index, mrp, offer_percentage) => {
    let params = {
      mrp: mrp,
      offer_percentage: offer_percentage,
    };
    if (params?.mrp != "" && params?.offer_percentage != "") {
      dispatch(getPrice(params))
        .then((result) => {
          if (
            result?.payload?.data?.status_code === 200 &&
            result?.payload?.data?.data != ""
          ) {
            formik.setFieldValue(
              `module_price.${index}.price`,
              result?.payload?.data?.data
            );
          } else {
            toast.error(result?.payload?.message);
          }
        })
        .catch((err) => {
            toast.error("Something went wrong!");
        });
    }
  };

  return (
    <div>
      {/* Render form fields for the "Address" tab */}
      <div className="col-12 pro-mb-4">
        <div className={`${Style.add_more_wrap}`}>
          {formik?.values?.module_price?.map((fields, index) => (
            <ItemField
              key={index}
              itmIndex={index}
              fields={fields}
              formik={formik}
              setPriceAmount={setPriceAmount}
            />
          ))}
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            type="button"
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More Country`}
          </Button>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="button"
          // disabled={
          //   formik?.errors?.firstName != undefined ||
          //   formik?.errors?.lastName != undefined ||
          //   formik?.errors?.mobileNumber != undefined ||
          //   formik?.errors?.email != undefined ||
          //   formik?.errors?.dateOfBirth != undefined ||
          //   formik?.errors?.nationality != undefined
          // }
          onClick={() => {
            // setActiveTab(tabs[1]);
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = tabs[2].label;
              })
            );
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PriceForm;
