import { useSelector } from "react-redux";

const useTransactionPage = () => {
  const { transactionData } = useSelector((state) => state.order);

  const currentURL = window.location.href;
  const newURL = currentURL.replace("/transaction", "");

  window.addEventListener("beforeunload", function (e) {
    // Redirect to the desired URL
    e.preventDefault();
    // Set the new URL in the window location
    window.location.href = newURL;
  });

  const transactionLabel = {
    payment_gateway: "Payment Gateway",
    payment_type: "Payment Type",
    transaction_date: "Transaction Date",
    transaction_id: "Transaction ID",
  };

  const transactionValues = {
    payment_gateway: `${
      transactionData.payment_gateway !== ""
        ? transactionData.payment_gateway
        : "Not specified"
    }`,
    payment_type: `${
      transactionData.payment_type !== ""
        ? transactionData.payment_type
        : "Not specified"
    }`,
    transaction_date: `${
      transactionData.transaction_date !== ""
        ? transactionData.transaction_date
        : "Not specified"
    }`,
    transaction_id: `${
      transactionData.transaction_id !== ""
        ? transactionData.transaction_id
        : "Not specified"
    }`,
  };

  const transactionDetails = Object.keys(transactionValues).map((key) => {
    return {
      label: transactionLabel[key],
      value:
        typeof transactionValues[key] === "string"
          ? transactionValues?.[key]
          : typeof transactionValues[key] === "number"
          ? transactionValues?.[key]
          : transactionValues?.[key]?.name,
    };
  });
  return { transactionDetails };
};

export default useTransactionPage;
