import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React,{useState} from "react";
import useAnnouncementForm from "./useAnnouncementForm";
import {
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import Select from "react-select";
import Style from "../announcement.module.scss";

const AnnouncementForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
  } = useAnnouncementForm({ refetch, closeModal });

  const today = new Date();
  const minDate = today.toISOString().split('T')[0];

  const [expiryDateClass, setExpiryDateClass] = useState("");
  const handleExpiryDateChange = (e) => {
    // Manually set the field value using formik.setFieldValue
    formik.setFieldValue("expiry_date", e.target.value);

    // Update the class based on the value of the input
    setExpiryDateClass(e.target.value ? "date-selected" : "");
  };
  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

      <div className="col-md-12">
        <Input
          type="date"
          id={`expiry_date`}
          name={`expiry_date`}
          label={`Expiry Data`}
          min={minDate}
          value={formik?.values?.expiry_date}
          onBlur={formik.handleBlur(`expiry_date`)}
          className={`pro-input lg ${expiryDateClass} ${
            formik.touched?.expiry_date &&
            formik.errors?.expiry_date &&
            " error"
          }`}
          {...formik.getFieldProps(`expiry_date`)}
          error={
            formik.touched?.expiry_date &&
            formik.errors?.expiry_date && (
              <span className="error-text">{formik.errors?.expiry_date}</span>
            )
          }
          errorMessage={
            formik.touched?.expiry_date &&
            formik.errors?.expiry_date && (
              <span className="error-text">{formik.errors?.expiry_date}</span>
            )
          }
          onChange={handleExpiryDateChange}
        />
      </div>


      <Input
        label={"Description*"}
        type="text"
        id="description
          "
        name="description
          "
        className={`pro-input lg ${
          formik.errors.description && formik.touched.description && "error"
        }`}
        {...formik.getFieldProps("description")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.description && formik.touched.description}
        errorMessage={formik.errors.description}
      />

      {/* toggle button start  */}
     
        <div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? 0
                );
              }}
            />
            <span></span>
          </div>
          {/* <label>Status</label> */}
        </div>

      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default AnnouncementForm;
