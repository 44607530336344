import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id) => `branch_id[]=${id}`).join("&")
      : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("order_id")}${getParams(
          "start_date"
        )}${getParams("end_date")}${getParams("category")}${getParams(
          "status"
        )}${getParams("sort_by")}&sort_order=${
          params?.sort_order
            ? params?.sort_order
            : (params?.product_sort_order
                ? params?.product_sort_order
                : "desc") || "desc"
        }&length=${params?.per_page || "10"}&${getParams("search")}&page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const program = createApi({
  reducerPath: "programApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["program", "price", "session"],
  endpoints: (builder) => ({
    getProgramListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-module`,
      }),
      providesTags: ["program"],
    }),

    getProgramBasicData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `/v1/admin-module/show/${id}`,
      }),
      providesTags: ["program"],
    }),

    getPriceData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-module/program/price?program_id=${values.program_id}`,
      }),
      providesTags: ["price"],
    }),

    getSessionData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-module/program-session?program_id=${values.program_id}`,
      }),
      providesTags: ["session"],
    }),
    // getDocumentListeData: builder.query({
    //      query: (values) => ({
    //           method: "get",
    //           params: values,
    //           endpoint: `admin/orders/document`
    //      }),
    //      providesTags: ["orders"]
    // }),
    // getProductListeData: builder.query({
    //      query: (values) => ({
    //           method: "get",
    //           params: values,
    //           endpoint: `admin/orders/product/list`
    //      }),
    //      providesTags: ["orders"]
    // }),
    // getTravelDetails: builder.query({
    //      query: (values) => ({
    //           method: "get",
    //           params: values,
    //           endpoint: `admin/orders/travel-details/view`
    //      }),
    //      providesTags: ["orders"]
    // }),
    // getDeliveryDetails: builder.query({
    //      query: (values) => ({
    //           method: "get",
    //           params: values,
    //           endpoint: `admin/orders/delivery/view`
    //      }),
    //      providesTags: ["orders"]
    // }),
    //    updateStatus: builder.mutation({
    //      query: (body) => ({
    //        method: "post",
    //        body,
    //        endpoint: `admin/orders/status/change`,
    //      }),

    //      invalidatesTags: ["orders"],
    //    }),
  }),
});

export const {
  useGetProgramListDataQuery,
  useGetProgramBasicDataQuery,
  useGetPriceDataQuery,
  useGetSessionDataQuery,
} = program;
