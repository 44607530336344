import React from "react";
import CommonLayout from "../../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useSellerAnnouncement from "./useSellerAnnouncement";

const SellerAnnouncement = () => {
  const { drawerMenu } = useSellerAnnouncement();
  return (
    <CommonLayout dashboard={false} drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default SellerAnnouncement;