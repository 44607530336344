import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateStudentOTP } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const useStudentOtp = ({ refetch, closeOtpModal }) => {
  const navigate = useNavigate();
  const { enteredEmail } = useSelector((state) => state.myStudents);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState("");

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (value) => {
    setOtp(value);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (otp) {
      validateStudentOTP({ otp: parseInt(otp), email: enteredEmail }).then(
        (response) => {
          if (response?.data?.success && response) {
            closeOtpModal();
            toast.success("Student registered successfully");
            refetch();
          } else if (response?.success === false) {
            closeOtpModal();
            toast.error("Failed to register student");
          }
        }
      );
    } else {
      setErrors("*OTP cannot be empty");
    }
  };

  return {
    otp,
    errors,
    enteredEmail,
    handleChange,
    handleSubmit,
    handleBlur,
    handleLoginClick,
  };
};

export default useStudentOtp;
