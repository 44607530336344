import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetPayoutHistoryDataQuery } from "../../../store/queries/payoutHistory/index.js";
import { useUpdateTableFieldsDataMutation } from "../../../store/queries/global/index.js";
import {
  deleteData,
  getEditDataByID,
  updateConfig,
} from "../../../store/slices/PayoutHistory/payoutHistorySlice.js";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";
import { getFormatedDate } from "../../../utils/functions/table.js";
import Style from "./agents.module.scss";

const usePayoutHistory = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.payoutHistory);

  const { showCreateModal } = useSelector((state) => state.payoutHistory);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [selectedOption, setSelectedOption] = useState(null);
  const [filterShow, setFilterShow] = useState(false);

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPayoutHistoryDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
  });
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      batch_transfer_id: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/history-details/${data?.id}`,
              });

              sessionStorage.setItem("active", `${data?.id}`);
            }}
          >
            <span
              className={`pro-ms-2 pro-pnt pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {data?.[feild]}
            </span>
          </div>
        );
      },
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.status = null;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.status = selectedOption.value;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/agent/");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    actionOptions,
    selectedOption,
    tableRef,
    filterShow,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    getRow,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleChange,
    handleDashboardRedirect,
    setFilterShow,
    handleEditClick,
  };
};

export default usePayoutHistory;
