import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddAddons from "./useAddAddons";
// import ItemField from "./ItemFieldClick";
import Select from "react-select";
import ItemField from "./ItemFieldClick";
import Style from "../user.module.scss";

const AddAddons = ({ refetch, setShowform, programId }) => {
  const { getFieldError, formik, handleAddField, typeList, is_edit } =
    useAddAddons({ setShowform, refetch, programId });

  return (
    <div className={`row`}>
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Questionnaire Type *
          </label>
          <Select
            name="question_type_id"
            options={typeList} // replace with your question_type_id options
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={typeList?.filter(
              (m) => formik?.values?.question_type_id === m?.value
            )}
            onChange={(selectedOption) =>
              formik.setFieldValue(`question_type_id`, selectedOption?.value)
            }
            className={`pro-input lg ${
              formik.touched?.question_type_id &&
              formik.errors?.question_type_id &&
              " error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur(`question_type_id`)}
          />
          {formik.touched?.question_type_id &&
            formik.errors?.question_type_id && (
              <div className="error-text">
                {formik.errors?.question_type_id}
              </div>
            )}
        </div>
      </div>
      <div className="col-12 pro-mb-4">
        <div className={`${Style.add_more_wrap}`}>
          {formik?.values?.questionnaire?.map((fields, index) => (
            <ItemField
              key={index}
              itmIndex={index}
              fields={fields}
              formik={formik}
              getFieldError={getFieldError}
              isEdit={is_edit}
            />
          ))}
          {!is_edit && (
            <Button
              className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
              onClick={handleAddField}
              // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
            >
              {` Add Content`}
            </Button>
          )}
        </div>
      </div>
      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={setShowform}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddAddons;
