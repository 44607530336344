import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React, {useState} from "react";
import useAddCoupon from "./useAddCoupon";
import Select from "react-select";

const AddCoupon = ({ refetch, setShowform, programId }) => {
  const { getFieldError, formik, is_edit } = useAddCoupon({
    setShowform,
    refetch,
    programId,
  });

  return (
    <div className={`row`}>
      
      <Input
        label={"Code*"}
        type="text"
        id="code"
        name="code"
        className={`pro-input lg ${getFieldError("code") && " error"}`}
        {...formik.getFieldProps("code")}
        error={getFieldError("code")}
        errorMessage={getFieldError("code")}
      />
      <Input
        label={"Discount Percentage*"}
        type="text"
        id="discount_percentage"
        name="discount_percentage"
        className={`pro-input lg ${
          getFieldError("discount_percentage") && " error"
        }`}
        {...formik.getFieldProps("discount_percentage")}
        error={getFieldError("discount_percentage")}
        errorMessage={getFieldError("discount_percentage")}
      />

<Input
        label={"Available Count*"}
        type="text"
        id="available_count"
        available_count="available_count"
        className={`pro-input lg ${getFieldError("available_count") && " error"}`}
        {...formik.getFieldProps("available_count")}
        error={getFieldError("available_count")}
        errorMessage={getFieldError("available_count")}
      />

<div className={`pro-mb-4 input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Description *
        </label>
        <textarea
          type="text"
          name="description"
          placeholder="Write here ..."
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.description && formik.touched.description && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.description}
        />
        {formik.touched.description && formik.errors.description && (
          <div className="error-text">{formik.errors.description}</div>
        )}
      </div>

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={setShowform}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddCoupon;
