import { Button } from "@wac-ui-dashboard/wac_component_library";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getBonusList, getProgramList } from "../../../../store/slices/packages/packageModuleSlice";

const ModuleForm = ({ 
  formik,
  setActiveTab,
  tabs,
  setShowform,
  isLoading,
  checkError, 
}) => {
  const dispatch = useDispatch();
  const [programList, setProgramList] = useState([]);
  const [bonusList, setBonusList] = useState([]);

  useEffect(() => {
    dispatch(getProgramList())
      .unwrap()
      .then((result) => {
        setProgramList(
          result.data.data?.map((item) => ({
            label: item.module_name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

      dispatch(getBonusList())
      .unwrap()
      .then((result) => {
        setBonusList(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});
  }, []);
  

  return (
    <div>
      {/* <div className="col pro-mb-4">

        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Programs *
          </label>
          <Select
          id="modules"
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.modules &&
            formik.touched.modules &&
            "error"
          }`}
          classNamePrefix="pro-input"
          isMulti={true}
          name="modules"
          options={programList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={programList?.filter((m) =>
            formik.values.modules?.includes?.(m?.value)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "modules",
              value.map((v) => v?.value)
            )
          }
          menuPlacement="auto"
        />
          {formik.touched.modules &&
            formik.errors.modules && (
              <span className="error-text">
                {formik.errors.modules}
              </span>
            )}

        </div>
      </div> */}
      <div className="col pro-mb-4">

        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Addons *
          </label>
          <Select
          id="addons"
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.addons &&
            formik.touched.addons &&
            "error"
          }`}
          classNamePrefix="pro-input"
          isMulti={true}
          name="addons"
          options={bonusList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={bonusList?.filter((m) =>
            formik.values.addons?.includes?.(m?.value)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "addons",
              value.map((v) => v?.value)
            )
          }
          menuPlacement="auto"
        />
          {formik.touched.addons && formik.errors.addons && (
            <div className="error-text">{formik.errors.addons}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            isLoading ? "loading" : ""
          }`}
          type="submit"
          disabled={isLoading}
          onClick={checkError}
        >
          {isLoading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ModuleForm;
