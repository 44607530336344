import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils";
import {
  // getEditStudentData,
  // getEducationLevelData,
  updateConfig,
} from "../../../../store/slices/program/programSlice.js";
import { useGetProgramBasicDataQuery } from "../../../../store/queries/program";

const useProgramDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const ProfileState = useSelector((state) => state.program);
  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});
  // const [updateStudentStatus] = useUpdateStudentStatusMutation();

  const navigation = [
    {
      label: "Price",
      title: "Price",
      link: `/program-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/program-details/${activeProfile}`, true),
    },
    {
      label: "Sessions",
      title: "Sessions",
      link: `/program-details/sessions/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/sessions/${activeProfile}`),
    },
  ];

  const navigate = useNavigate();

  const {
    data: programData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetProgramBasicDataQuery(activeProfile);

  // const hasUpdatePermission = useMemo(() => {
  //   let permission = programData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [programData]);

  useEffect(() => {
    if (programData?.statusCode === 400 || programData === "") {
        toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [programData, activeProfile]);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const label = {
    gender: "Gender",
    email: "Email",
    education_level: "Education Level",
    location: "Location",
  };

  const value = {
    gender: `${programData?.data?.student_detail?.gender ?? "Not specified"}`,
    email: `${programData?.data?.student_detail?.email ?? "Not specified"}`,
    education_level: `${
      programData?.data?.student_detail?.education_level ?? "Not specified"
    }`,
    location: `${
      programData?.data?.student_detail?.location ?? "Not specified"
    }`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.isEdit = true;
        state.selectedID = programData?.data?.student_detail?._id;
      })
    );
    // dispatch(getEducationLevelData());
    // dispatch(getEditStudentData(programData?.data?.student_detail?._id));
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.isEdit = false;
        state.selectedID = {};
        state.imagePreview = "";
        state.imageName = "";
        state.studentData = [];
        state.educationLevel = [];
      })
    );
  };

  // useEffect(() => {
  //   if (statusUpdate) {
  //     updateStudentStatus(obj?.id).then((res) => {
  //       if (res?.data?.statusCode === 200) {
  //         toast.success(res?.data?.message);
  //         refetch();
  //       } else {
  //         toast.error(res?.data?.message);
  //       }
  //     });
  //     handleDeleteModal();
  //   }
  // }, [statusUpdate === true]);

  const handleDeleteModal = () => {
    setStatusUpdate(false);
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };
  return {
    activeProfile,
    isFetching,
    printRef,
    closeModal,
    programData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    popUpMessage,
    handleDeleteModal,
  };
};

export default useProgramDetailsLayout;
