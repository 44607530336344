import { useDispatch } from "react-redux"
import { updateConfig, getBranchCurrency, currencySave } from "../../../../store/slices/Branches/branchSlice";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import currencyData from "../../../../utils/components/currencyCode";
import { useGetAllCurrencyDataQuery } from "../../../../store/queries/branches";
import { branches } from "../../../../store/queries/branches";

const useBranchCurrency = () => {
	const { branch_id } = useParams();
	const dispatch = useDispatch();
	const menuState = useSelector((state) => state.branch);
	const activeProfile = branch_id ?? sessionStorage.getItem('active');
	const [mainData, setMainData] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [clearedItem, setClearedItem] = useState([]);
	const [initialData, setInitialData] = useState([]);

	const {
		data: allCurrency = {},

	} = useGetAllCurrencyDataQuery({
		search: menuState.searchCurrency,
	});


	useEffect(() => {
		dispatch(getBranchCurrency(activeProfile))
			.then((result) => {
				if (result?.payload?.data?.data?.currencies) {
					setMainData(result?.payload?.data?.data?.currencies);
					setInitialData(result?.payload?.data?.data?.currencies)
				}
			})
			.catch((err) => { });
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if(allCurrency?.data?.data?.length !== 0 || mainData?.length !== 0) {
			if (mainData?.length === allCurrency?.data?.data?.length) {
				setIsChecked(true)
			}
		}	

	}, [mainData, allCurrency?.data?.data]);



	const handleSelectall = () => {
		const allCurrencies = allCurrency?.data?.data || [];
		if (!isChecked) {

			let temp = allCurrencies.map(i => ({ branch_currency_id: i?.branch_currency_id ? i?.branch_currency_id : null, name: i.currency_code }))
			let tempArray = temp.filter((i) => {
				let checked = mainData?.map(currency => currency?.name).includes(i.name)
				if (!checked) {
					return i;
				}
			})
			let tempMain = [...mainData, ...(tempArray.length ? tempArray : [])]
			setMainData(tempMain);
			setClearedItem([]);
		} else {
			let remov = mainData.filter((i) => { return i });
			setMainData([]);
			setClearedItem(remov);
		}
		setIsChecked(prev => !prev);
	};


	const handleCheckboxChange = (e, item) => {
		const { checked } = e.target;

		if (checked) {
			
			setMainData([...mainData, { branch_currency_id: item?.branch_currency_id ? item?.branch_currency_id : null, name: item?.currency_code }]);
			setIsChecked(mainData.length === allCurrency?.data?.data?.length - 1);
		} else {
			
			setMainData(mainData.filter((obj) => obj?.name !== item?.currency_code));
			setClearedItem((prev) => [
				...prev,
				...mainData?.filter((i) => i?.name === item?.currency_code),
			]);
			setIsChecked(mainData.length === allCurrency?.data?.data?.length + 1);
		}
	};


	const handleSave = () => {

		const tempArray = [...mainData?.map(i => ({ branch_currency_id: i.branch_currency_id, code: i.name }
		))];

		const newArray = tempArray.filter(item2 => !initialData.some(item1 => item1.name === item2.code)).map(item2 => item2.code);


		const new2Array = [];
		for (const item1 of initialData) {
			if (tempArray.length !== 0) {
				for (const item2 of tempArray) {
					if (item1.name === item2.code) {
						new2Array.push(item1.branch_currency_id);
						break;
					}
				}
			}

		}


		const tempClearedArray = clearedItem?.filter((c) => {
			if (c?.branch_currency_id !== null) {
				return c.branch_currency_id;
			}
		});

		const tempClearedItem = [];
		if (tempArray.length === 0) {
			for (const item1 of initialData) {
				for (const item2 of clearedItem) {
					if (item1.name === item2.name) {
						tempClearedItem.push({ "branch_currency_id": item1.branch_currency_id, "name": item1.name });
						break;
					}
				}
			}
		}

		const new3Array = tempClearedArray.length !== 0 ?
			tempClearedArray.filter(item2 => !new2Array.includes(item2.branch_currency_id))
				.map(item => item.branch_currency_id) : tempClearedItem.filter(item2 => !new2Array.includes(item2.branch_currency_id))
					.map(item => item.branch_currency_id);

		let formData = {
			"branch_id": activeProfile,
			"currency": newArray,
			"deleted_currency": new3Array,
		}


		dispatch(currencySave(formData))
			.unwrap()
			.then((result) => {
				if (result?.success) {
					toast.success(result?.message);
					setClearedItem([]);
					setInitialData([]);
					setMainData([]);
					dispatch(branches.util.invalidateTags(["branch-rate", "branch-currency"]));
					dispatch(getBranchCurrency(activeProfile))
						.then((result) => {
							if (result?.payload?.data?.data?.currencies) {
								setMainData(result?.payload?.data?.data?.currencies);
								setInitialData(result?.payload?.data?.data?.currencies)
							}
						})
						.catch((err) => { });
				} else {
					toast.error(result?.message);
				}
			})
			.catch((err) => {
			});

	}

	const getCoutryIconFromName = (name = "") => {
		const currencyObj = currencyData.find(
			(obj) => obj.code.toLowerCase() === name?.toLowerCase?.()
		);
		if (currencyObj) {
			return currencyObj.flag;
		} else {
			return false;
		}

	};

	const handleSearch = (e) => {
		dispatch(
			updateConfig((state) => {
				state.searchCurrency = e.target.value;
			})
		);
	};

	return {
		menuState,
		mainData,
		allCurrency,
		handleSearch,
		handleSelectall,
		isChecked,
		handleCheckboxChange,
		handleSave,
		currencyData,
		getCoutryIconFromName,
		setIsChecked
	}
}

export default useBranchCurrency