import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  createNewSession,
  updateConfig,
  updateSession,
} from "../../../../store/slices/program/programSessionSlice";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";

const useAddSession = ({ refetch, handleModalClick, programId }) => {
  const dispatch = useDispatch();

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.programSession
  );


  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setInitialData({
        sub_module_name: selectedItemsDetails?.sub_module_name ,
        sub_module_description: selectedItemsDetails?.sub_module_description,
        display_order: selectedItemsDetails?.display_order,
        daily_watch: selectedItemsDetails?.daily_watch,
        sub_module_content: selectedItemsDetails?.content_list?.length > 0
            ? selectedItemsDetails?.content_list?.map((item, index) => ({
                content_id: item?.id,
                content_title: item?.content_title ?? "",
                content_description: item?.content_description ?? "",
                content_type: item?.content_type_value ?? "",
                content:
                  item?.content_type_value === 3
                    ? 
                    item?.content
                      ? convertHtmlToEditorState(item?.content)
                      : ""
                    : item?.content
                    ? item?.content
                    : "",
                content_text:
                  item?.content_type_value === 1 || item?.content_type_value === 2
                    ? item?.content_text
                      ? convertHtmlToEditorState(item?.content_text)
                      : ""
                    : item?.content_text
                    ? item?.content_text
                    : "",
              }))
            : [
                {
                  content_title: "",
                  content_description: "",
                  content_type: "",
                  // content: "",
                  content_text: "",
                },
              ],
    })
    }
  }, [selectedItemsDetails]);



  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Return empty editor state if no HTML

    const sanitizedHtml = DOMPurify.sanitize(html);
    const contentBlock = htmlToDraft(sanitizedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };



  const [initialData, setInitialData] = useState({
    sub_module_name: "",
    sub_module_description: "",
    display_order: "",
    daily_watch: 0,
    sub_module_content: [
            {
              content_title: "",
              content_description: "",
              content_type: "",
              // content: "",
              content_text: "",
            },
          ],
        });

  const orderList = Array.from({ length: 15 }, (_, index) => ({
    label: index + 1,
    value: index + 1,
  }));

  const formik = useFormik({
     enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      sub_module_name: Yup.string().required("*Required"),
      sub_module_description: Yup.string().required("*Required"),
      display_order: Yup.number().required("*Required"),
      sub_module_content: Yup.array().of(
        Yup.object().shape({
          content_title: Yup.string().required("*Required"),
          content_description: Yup.string().required("*Required"),
          content_type: Yup.string().required("*Required"),
          // content: Yup.string().required("*Required"),
        })
      ),
    }),

    validate: (values) => {
      let errors = {};
      // Check if content is not undefined and has length
      if (values.sub_module_content && values.sub_module_content.length > 0) {
        // Initialize sub_module_content array in errors object
        errors.sub_module_content = [];

        values.sub_module_content.forEach((value, index) => {
          
          // Initialize an empty object for holding errors of this item
          let contentErrors = {};

          if (value?.content_type === 3) {
            // Assuming `content` is an EditorState object from Draft.js or similar library
            let plainText = "";
            if(typeof(value?.content) === Yup.string) {
              plainText = value?.content
              ? value?.content?.getCurrentContent()?.getPlainText()
              : "";
            }
            else {

              // plainText = "";
              plainText = value?.content
              ? value?.content?.getCurrentContent()?.getPlainText()
              : "";
            }
            

            // Check if the sub_module_content is empty or only contains whitespace
            if (!plainText.trim()) {
              // Assign the error message to the content field of the current item
              contentErrors.content = "*Required";
            }
          } else if(value?.content_type === 1 || value?.content_type === 2){
            if (value?.content === "" || value?.content === undefined) {
              contentErrors.content = "*Required";
            }
            // if (
            //   value?.content_type === 3 &&
            //   (value?.content_text === "" ||
            //   value?.content_text === undefined)
            // ) {
            //   const plainText = value?.content_text
            //     ? value?.content_text?.getCurrentContent().getPlainText()
            //     : "";

            //   // Check if the sub_module_content is empty or only contains whitespace
            //   if (!plainText.trim()) {
            //     // Assign the error message to the content_text field of the current item
            //     contentErrors.content_text = "*Required";
            //   }
            // }
          }

          // If there are any errors for the current item, push them to the errors.sub_module_content array
          if (Object.keys(contentErrors).length > 0) {
            errors.sub_module_content[index] = contentErrors;
          }
        });
        // If after processing all sub_module_content items there are no errors, remove the sub_module_content key from errors
        if (errors.sub_module_content.length === 0) {
          delete errors.sub_module_content;
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("program_id", programId);
      formData.append("sub_module_name", values["sub_module_name"]);
      formData.append(
        "sub_module_description",
        values["sub_module_description"]
      );
      formData.append("display_order", values["display_order"]);
      formData.append("daily_watch", values["daily_watch"] ? 1 : 0 );

      values?.sub_module_content?.forEach((value, index) => {
        if (value?.content_id) {
          formData.append(
            `sub_module_content[${index}][id]`,
            value?.content_id
          );
        }
        formData.append(
          `sub_module_content[${index}][content_title]`,
          value?.content_title
        );
        formData.append(
          `sub_module_content[${index}][content_description]`,
          value?.content_description
        );
        formData.append(
          `sub_module_content[${index}][content_type]`,
          value?.content_type
        );
        // formData.append(
        //   `sub_module_content[${index}][content]`,
        //   value?.content
        // );
        if (value?.content_type === 3) {
          let contentState;

          if (value?.content?.getCurrentContent) {
            contentState = value?.content?.getCurrentContent();
          } else {
            // Assuming value.content might directly be a string or another format in some cases
            contentState = value.content; // Or handle this case based on your actual data structure
          }
          const contentRaw = convertToRaw(contentState);
          const plainText = draftToHtml(contentRaw);

          formData.append(`sub_module_content[${index}][content]`, plainText);
          formData.append(`sub_module_content[${index}][content_text]`, "");
        } else if(value?.content_type === 1 || value?.content_type === 2) {
          formData.append(
            `sub_module_content[${index}][content]`,
            value?.content
          );

          let contentState;

          if (value?.content_text) {
            contentState = value?.content_text?.getCurrentContent();
          } else {
            // Assuming value.content_text might directly be a string or another format in some cases
            contentState = value?.content_text; // Or handle this case based on your actual data structure
          }
          const contentRaw = convertToRaw(contentState);
          const plainText = draftToHtml(contentRaw);
          formData.append(
            `sub_module_content[${index}][content_text]`,
            plainText
          );
        }
        else {
          formData.append(
            `sub_module_content[${index}][content]`,
            ""
          );
          formData.append(
            `sub_module_content[${index}][content_text]`,
            ""
          );

        }
      });

      if (is_edit) {
        formData.append("id", selectedId);
        dispatch(updateSession(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewSession(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }

      // updateAboutUs(formData, selectedData).then((response) => {
      //   if (response?.data?.statusCode === 200) {
      //     handleModalClick();
      //     refetch();
      //     toast.success("Policy updated");
      //   } else if (response?.statusCode === 422) {
      //     const errors = response?.errors;
      //     errors.forEach((error) => {
      //       formik.setFieldError(error.field, error.message);
      //     });
      //   } else {
      //     toast.error("Something went wrong");
      //   }
      // });
    },
  });

  const handleAddField = () => {
    let { sub_module_content } = formik?.values;
    sub_module_content = [
      ...sub_module_content,
      {
        content_title: "",
        content_description: "",
        content_type: "",
        content: "",
        content_text: "",
      },
    ];
    formik.setFieldValue("sub_module_content", sub_module_content);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  const handleContentChange = (editorState, index) => {
    const fieldName = `sub_module_content[${index}][content]`;
    formik?.setFieldValue(fieldName, editorState);
  };

  const handleContentTextChange = (editorState, index) => {
    const fieldName = `sub_module_content[${index}][content_text]`;
    formik?.setFieldValue(fieldName, editorState);
  };

  return {
    formik,
    is_edit,
    getFieldError,
    closeModal,
    handleAddField,
    orderList,
    handleContentChange,
    handleContentTextChange,
  };
};

export default useAddSession;
