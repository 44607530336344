import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddAddons from "./useAddAddons";
import Style from "../user.module.scss";

const AddAddons = ({ refetch, setShowform, programId }) => {
  const { getFieldError, formik, is_edit, handleVideosChange } = useAddAddons({
    setShowform,
    refetch,
    programId,
  });

  return (
    <div className={`row`}>
      <Input
        label={"Title*"}
        type="text"
        id="title"
        title="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
      />
      <Input
        label={"Description*"}
        type="text"
        id="description"
        name="description"
        className={`pro-input lg ${getFieldError("description") && " error"}`}
        {...formik.getFieldProps("description")}
        error={getFieldError("description")}
        errorMessage={getFieldError("description")}
      />
      <Input
        label={"Order*"}
        type="text"
        id="order"
        name="order"
        className={`pro-input lg ${getFieldError("order") && " error"}`}
        {...formik.getFieldProps("order")}
        error={getFieldError("order")}
        errorMessage={getFieldError("order")}
      />

      <div className={Style.wrapper}>
        <label className="pro-mb-1 pro-font-sm pro-fw-medium">Video*</label>
        <div
          className={`pro-d-flex pro-input  pro-justify-center pro-flex-wrap pro-gap-3 pro-p-2 upload-wrap ${Style.upload_wrap} video-upload-wrap ${handleVideosChange ? 'active': ''}`}
        >
          <></>
          <label for="video-upload" className={`pro-w-100 pro-h-100 stretched-label`}  ></label>
          {/* {showAddButton && ( */}
          <div
            className={`input-wrap ${
              formik.touched?.file && formik.errors?.file && " error"
            }`}
          >
            {handleVideosChange ?(
            <div className="pro-d-flex pro-justify-center pro-items-center pro-flex-column pro-text-center">
              <span className="material-symbols-outlined x3">backup</span>
              <span className={`${Style.add_text} pro-fw-medium `}>
                "Add Video"
              </span>
            </div>
              ):(
                <div className="pro-input pro-h-100 pro-d-flex pro-justify-between pro-items-center">
                  <div className="file-name ">File Name.mp4</div>
                  {/* <button  type="button" className="btn-close"></button> */}
                </div>
            )}
            <input
              type="file"
              className={`${Style.file} pro-d-none`}
              onChange={handleVideosChange}
              id="video-upload"
            />
          </div>
          {/* )} */}
          {formik.touched?.file && formik.errors?.file && (
            <span className="error-custom">{formik.errors?.file}</span>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={setShowform}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddAddons;
