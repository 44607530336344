
import Style from './DonutGraphShimmer.module.scss';

const DonutGraphShimmer = () => {
  return (
    <div className={`${Style.root} pro-p-3`}>
        <div className="pro-d-flex pro-justify-between pro-mb-5">
            <div className="pro-py-3 pro-pb-3 pro-align-self-center pro-px-6 shimmer"></div>
            <div className="pro-py-4 pro-px-6 pro-ps-4 shimmer"></div>
        </div>
        <div >
            <div className='pro-mb-4'>
                <div class={`${Style.donut_chart} pro-mx-auto `}>
                    <div class={Style.shimmer}></div>
                </div>  

            </div>
            <div className='row row-cols-3 gx-4'>
            
                <div className={` pro-d-flex pro-gap-1 pro-mb-3 `}>
                    <div className='pro-p-1 shimmer col-auto'></div>
                    <div className='pro-p-1 shimmer col'></div>
                </div>
                <div className={`col pro-d-flex pro-gap-1 pro-mb-3`}>
                    <div className='pro-p-1 shimmer col-auto'></div>
                    <div className='pro-p-1 shimmer col'></div>
                </div>
                <div className={`col  pro-d-flex pro-gap-1 pro-mb-3`}>
                    <div className='pro-p-1 shimmer col-auto'></div>
                    <div className='pro-p-1 shimmer col'></div>
                </div>
                <div className={`col  pro-d-flex pro-gap-1 pro-mb-3`}>
                    <div className='pro-p-1 shimmer col-auto'></div>
                    <div className='pro-p-1 shimmer col'></div>
                </div>
                <div className={` col pro-d-flex pro-gap-1 pro-mb-3`}>
                    <div className='pro-p-1 shimmer col-auto'></div>
                    <div className='pro-p-1 shimmer col'></div>
                </div>
                <div className={` col pro-d-flex pro-gap-1 pro-mb-3`}>
                    <div className='pro-p-1 shimmer col-auto'></div>
                    <div className='pro-p-1 shimmer col'></div>
                </div>
                    
                
            </div>
            
        </div>
        
    </div>
  )
}

export default DonutGraphShimmer