import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  formActiveTab: "Profile",
  documents: [],
};

export const updateProfileData = createAsyncThunk(
  "/admin/profile/update",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/profile/update", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateManageProfile = createAsyncThunk(
  "/v1/agent/profile/update",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/agent/profile/update", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/agent/profile/delete",
  async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/agent/profile/delete`);
      return response.data;
    } catch (error) {
      // return rejectWithValue(error.response.data);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "/aadhaar-send-otp",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/aadhaar-send-otp", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const aadharVerification = createAsyncThunk(
  "/aadhaar-verify-otp",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/aadhaar-verify-otp", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBankInfo = createAsyncThunk(
  "/v1/agent/kyc/verify-bankaccount",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/agent/kyc/verify-bankaccount", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getManageProfileData = createAsyncThunk(
  "/v1/agent/profile/edit",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/agent/profile/edit", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const manageProfileSlice = createSlice({
  name: "manageProfile",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getManageProfileData.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload;
    });
  },
});

export const { updateConfig } = manageProfileSlice.actions;

export default manageProfileSlice.reducer;
