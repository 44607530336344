import { getAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/admin-auth/login", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const logout = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/admin-auth/logout", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/admin-auth/forgot-password", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/user/change/password`,
      params
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const changePassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/admin-auth/forgot-password-change`,
      params
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const ProfileChangePassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/admin/change-password`,
      params
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const validateEmail = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/v1/admin-auth/forgot-password", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const validateOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/admin-auth/forgot-password-verify`,
      params
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
