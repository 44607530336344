import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     start: subDays(new Date(), 30),
      end: new Date(),
     clearSelection: false,
     queryData: {},
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     productSortBy: "",
     productSortOrder: "desc",
     productCurrentFilter: null,
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     isTravelLoading: false,
     isDeliveryLoading: false,
     showInvoiceModal:false,
     invoiceLoading:false,
     orderId:'',
     searchProduct:"",
     currentBranchFilter: [],
}

export const getProgramList = createAsyncThunk(
  "list/programs",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin-package/programs");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBonusList = createAsyncThunk(
  "list/bonus-program",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin-package/bonus-programs");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const packageModuleSlice = createSlice({
     name: "packageModule",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          }
     },
})

export const { updateConfig } = packageModuleSlice.actions;

export default packageModuleSlice.reducer;