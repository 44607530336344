import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     start: subDays(new Date(), 30),
      end: new Date(),
     clearSelection: false,
     queryData: {},
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     userEditStatus:'idle',
     isEditLoading: false,
     start_date: "",
 end_date: "",
 role: "",
 status: "",
 
}

export const updateUser = createAsyncThunk(
     `/v1/admin/update`,
     async ({data}, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post("/v1/admin/update", data);
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)

export const createUser = createAsyncThunk(
     `/v1/admin/store`,
     async ({data}, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post("/v1/admin/store", data);
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)

export const getUserEditData = createAsyncThunk(
     'v1/admin/show/',
     async (data, { rejectWithValue }) => {  
          const api = await getAxiosInstance();
          try {
               const response = await api.get(`/v1/admin/show/${data}`);
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data)
          }
     }
)

export const deleteData = createAsyncThunk(
     "/v1/admin/delete",
     async (params, { rejectWithValue }) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.post(`/v1/admin/delete`,params);
         return response.data;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );


const userSlice = createSlice({
     name: 'user',
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          },
          setStoreFormValues: (state, action) => {
               state.role= action.payload.role;
               state.start_date= action.payload.start_date;
               state.end_date= action.payload.end_date;
               state.status= action.payload.status;
             },
             resetExtraFilters: (state, action) => {
               state.role= '';
               state.start_date= '';
               state.end_date= '';
               state.status= '';
             },
     },
     extraReducers: (builder) => {
          builder
          .addCase(getUserEditData.pending, (state, action) => {
               state.isEditLoading = true;
               state.error = null;
             })
            .addCase(getUserEditData.fulfilled, (state, action) => {
              state.selectedItemsDetails = action.payload.data;
              state.error = null;
              localStorage.setItem(
               "userDetails",
               JSON.stringify(action.payload.data)
             );
            })
            .addCase(getUserEditData.rejected, (state, action) => {
              state.selectedItemsDetails = null;
              state.error = action.payload;
            });
        },
})



export const { updateConfig, setStoreFormValues, resetExtraFilters } = userSlice.actions;
export default userSlice.reducer;