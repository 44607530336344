import {
  FormTabs,
  HeadingGroup,
} from "@wac-ui-dashboard/wac_component_library";
import Buy from "./Buy";
import Sell from "./Sell";

import useBranchRateForm from "./useBranchRateForm";
import style from "./branchRateForm.module.scss";

const BranchRateForm = ({refetch,closeModal}) => {
  const {
    tabs,
    activeTab,
    webId,
    optionsData,
    // isOptionDataSuccess,
    // activeUser,
    // edit,
    // toTab,
    handleTabClick,
  } = useBranchRateForm();
  return (
    <div className={`${style.profile_create} pro-mb-6`}>
      

      <FormTabs
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
        propStyle={{ root: style.formTab_root }}
      />
      {activeTab && activeTab === "Buy" ? (
        <Buy
          optionsData={optionsData}
          refetch={refetch}
          closeModal={closeModal}
          // isOptionDataSuccess={isOptionDataSuccess}
          // activeUser={activeUser}
          // edit={edit}
          // toTab={toTab}
          // webId={webId}
        />
      ) : activeTab === "Sell" ? (
        <Sell
          optionsData={optionsData}
          refetch={refetch}
          closeModal={closeModal}
          // isOptionDataSuccess={isOptionDataSuccess}
          // activeUser={activeUser}
          // toTab={toTab}
          // webId={webId}
        />
      ) :  (
        <></>
      )}
    </div>
  );
};

export default BranchRateForm;
