import {
  Button,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useRegisterNowForm from "./useRegisterNowForm";
import Assets from "../../../../../assets/Assets";
import Style from './registerNowForm.module.scss'

const RegisterNowForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    registerNow,
    selectedItemsDetails
  } = useRegisterNowForm({ refetch, closeModal });



  return (
    <div className={`pro-w-100 pro-p-5 ${Style.register_content_wrap}`}>
        <figure className="pro-mb-5 pro-d-flex pro-justify-center pro-items-center pro-border pro-rounded-3">
          <img src={Assets.REGISTER_BANNER}  alt="Register Banner"/>
        </figure>
        <div className={` ${Style.register_content}`}>
          <h3 className={Style.title}>{selectedItemsDetails?.name}</h3>
          <p>{selectedItemsDetails?.description}</p>
        </div>
        <div className={`${Style.register_now} pro-bg-white pro-mt-5 pro-border pro-rounded-3  pro-d-flex  pro-justify-between pro-items-center ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}>
          <div className="col">
            <p className="pro-mb-1 pro-d-flex pro-items-center">
              <span class="material-symbols-outlined pro-fw-light pro-me-1">
                calendar_month
              </span>
              <strong className="pro-border-right ">{selectedItemsDetails?.date}</strong>
              <strong>{selectedItemsDetails?.time}</strong>
            </p>
            <p className="pro-mb-0  pro-d-flex pro-items-center" >
              <span class="material-symbols-outlined pro-fw-light pro-me-1">account_circle</span>
              <strong>{selectedItemsDetails?.mentor}</strong>
            </p>
          </div>
          <div className={`col pro-d-flex  pro-justify-end`}>
            <Button
              className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
              type="submit"
              onClick={() => registerNow()}
            >
              Register Now
            </Button>
      </div>
        </div>
       

      
    </div>
  );
};

export default RegisterNowForm;
