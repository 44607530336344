import React from "react";
import useAllTrainings from "./useAllTrainings";
import { Button, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import Style from "./allTrainings.module.scss";
import AllTrainingShimmer from "../../../../Global/Shimmers/AllTrainingShimmer";
import RegisterNowForm from "../RegisterNowForm";

const AllTrainings = () => {
  const { mainData, tab, refetch, registerForm, closeModal, menuState } = useAllTrainings();
  const contentToDisplay =
    tab === "upcoming" ? mainData?.data?.upcoming : mainData?.data?.entrolled;

  return (
    <>
      <div className={Style.training_wrapper}>
        {
          !contentToDisplay? (
            <AllTrainingShimmer/>
          ):(
            <table>
              <tbody>
                {contentToDisplay?.map((item) => {
                  const dateText = item?.date; 
                  const [month, day] = dateText ? dateText.split(" ") : ["", ""]; // Split the date string
    
                  return (
                    <tr key={item?.id}>
                      <td>
                        <div className={`pro-d-flex  ${Style.date_time}`}>
                          <div className={Style.date}><span>{month}</span><strong>{day}</strong></div>
                          <div className={Style.time}><span>{item?.time}</span></div>
                        </div>
                      </td>
                      
                      <td>
                        <div className={Style.title_desc}>
                          <h6 className={Style.title}>{item?.name}</h6>
                          <p  className={Style.desc}>{item?.description}</p>
                        </div>
                      </td>
                      <td>
                          <div className={Style.mentor}>
                            <span className="material-symbols-outlined">account_circle</span> {item?.mentor}
                          </div>
                      </td>
                      <td>
                        {item?.is_registered === true ? (
                          <span className="pro-badge badge-warning">Registered</span>
                        ) : (
                          <Button className="pro-btn pro-btn-primary" onClick={() => registerForm(item)}>Register Now</Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        }

<ModalLayout
          show={menuState.showCreateModal}
          handleClose={closeModal}
          title={
            "Training: Register Now!"
          }
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          className={`modal-register ${Style.register_modal}`}
        >
          <RegisterNowForm refetch={refetch} closeModal={closeModal} />
        </ModalLayout>
       
      </div>
    </>
  );
};

export default AllTrainings;
