import { useSelector } from "react-redux";
import { useGetListDataQuery, useUpdateTableFieldsDataMutation } from "../../../../store/queries/SellerModule/sellerTraining/index.js";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils.js";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/SellerModule/SellerTraining/sellerTrainingSlice.js";

const useMyTraining = (dashboard) => {
  const { checkIfActiveRoute } = useRouteUtils();
  const dispatch = useDispatch();

  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  const menuState = useSelector((state) => state.sellerTraining);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  const navigation = [
    {
      label: `Upcoming`,
      title: "Upcoming",
      link: `/training/upcoming`,
      active: checkIfActiveRoute(`/training/upcoming`),
    },
    {
      label: `Enrolled`,
      title: "Enrolled",
      link: `/training/enrolled`,
      active: checkIfActiveRoute(`/training/enrolled`),
    },
  ];

  const TraningTabs = [{ label: "Upcoming" }, { label: "Enrolled" }];

  const TraningTabPermission = useMemo(() => {
    let menus = TraningTabs?.flatMap?.((menu) => [menu.label]);
    return navigation
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });

    //eslint-disable-next-line
  }, [TraningTabs]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  
  
  const splitDate = (dateString) => {
    let dateArray = dateString.split(' ');
    return (
      <p className={`pro-m-0`}>
        {dateArray[0]} 
        <h6>{dateArray[1]}</h6>
      </p>
    );
  };

  const registerForm = (item) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedItemsDetails = item;
      })
    );
  }

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
        state.showCreateModal = false;
      })
    );
  };


  return {
    TraningTabs,
    navigation: TraningTabPermission,
    mainData,
    updateTableFields,
    truncateText,
    splitDate,
    menuState,
    registerForm,
    closeModal,
    refetch
  };
};

export default useMyTraining;
