import React from "react";
import useStudentGoals from "./useStudentGoals";
import OverviewShimmer from "../../../../Global/Shimmers/OverviewShimmer";
import Style from "./studentGoals.module.scss";

const StudentGoals = () => {
  const { isFetching, goalList, daysToShow, renderDays } = useStudentGoals();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className={Style.container}>
            {daysToShow?.length > 0 && (
              <div className="col-lg-9">
                <div className={Style.moduleContainer}>
                  {daysToShow?.map((item, index) => (
                    <div key={index} className={Style.module}>
                      {item.module_name && (
                        <div>
                          <div className={Style.moduleName}>
                            {item.module_name}
                          </div>
                          <div>
                            {item.days ? (
                              renderDays(item.days)
                            ) : (
                              <div>No data available</div>
                            )}
                          </div>
                        </div>
                      )}

                      {item.alert_message && (
                        <div>
                          <div>{item?.alert_message}</div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StudentGoals;
