import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     editPackage: false,
     selectedData: '',
     clearSelection: false,
     start_date: "",
    end_date: "",
    package_type: "",
}



  

  export const deleteData = createAsyncThunk(
    "/v1/admin-package/delete",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(`/v1/admin-package/delete`,params);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  

const packageSlice = createSlice({
     name: "package",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          },
          setStoreFormValues: (state, action) => {
            state.package_type= action.payload.package_type;
            state.start_date= action.payload.start_date;
            state.end_date= action.payload.end_date;
          },
          resetExtraFilters: (state, action) => {
            state.package_type= '';
            state.start_date= '';
            state.end_date= '';
          },
     },
     
     
})

export const { updateConfig, setStoreFormValues, resetExtraFilters } = packageSlice.actions;

export default packageSlice.reducer;