import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     clearSelection: false,
     sortBy: "",
     sortOrder: "desc",
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     clearSelection: false,
     start_date: "",
    end_date: "",
    status: "",
}

export const createNewAddon = createAsyncThunk(
  "new/createNewSession",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-addon/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAddons = createAsyncThunk(
  "new/updateAddons",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-addon/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAddonDetails = createAsyncThunk(
  "v1/admin-addon/show",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/admin-addon/show/${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/admin-addon/delete-addons",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-addon/delete`,params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const bonusProgramSlice = createSlice({
     name: "bonusProgram",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          },
          setStoreFormValues: (state, action) => {
            state.start_date= action.payload.start_date;
            state.end_date= action.payload.end_date;
            state.status= action.payload.status;
          },
          resetExtraFilters: (state, action) => {
            state.start_date= '';
            state.end_date= '';
            state.status= '';
          },
     },
     extraReducers: (builder) => {
      builder
        .addCase(getAddonDetails.fulfilled, (state, action) => {
          state.is_edit = true;
          state.selectedItemsDetails = action?.payload?.data?.data
        })
        
    },
})

export const { updateConfig, setStoreFormValues, resetExtraFilters } = bonusProgramSlice.actions;

export default bonusProgramSlice.reducer;