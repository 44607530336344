import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createNewQuestionnaire, getTypeList, updateConfig, updateQuestionnaire } from "../../../../store/slices/questionnaire/questionnaireSlice";
import { useEffect, useState } from "react";

const useAddAddons = ({refetch, setShowform}) => {
  const dispatch = useDispatch();

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.questionnaire
  );


  const initialData = {
    question_type_id: is_edit
      ? selectedItemsDetails?.question_type_id
      : "",
    questionnaire:
      is_edit && selectedItemsDetails
        ? [{
          module_id: selectedItemsDetails?.module_id,
            question: selectedItemsDetails?.question ?? "",
            order: selectedItemsDetails?.order ?? "",
            
          }]
        : [
            {
              module_id: "",
              question: "",
              order: "",
            },
          ],
  };

    const [typeList, setTypeList] = useState([]);

    useEffect(() => {
      dispatch(getTypeList())
        .unwrap()
        .then((result) => {
          setTypeList(
            result.data.data?.map((item) => ({
              label: item.title,
              value: item.id,
            }))
          );
        })
        .catch((err) => {});
    }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      question_type_id: Yup.string().required("*Required"),
      questionnaire: Yup.array().of(
        Yup.object().shape({
          question: Yup.string().required("*Required"),
          order: Yup.number()
          .typeError('Order must be a number')
          .min(1, 'Minimum value is 1')
          .required("*Required"),
        })
      ),
    }),

    validate: (values) => {
      let errors = {};
      if (values.questionnaire && values.questionnaire.length > 0) {
        // Initialize questionnaire array in errors object
        errors.questionnaire = [];

        values.questionnaire.forEach((value, index) => {
          // Initialize an empty object for holding errors of this item
          let contentErrors = {};

          // Check if the questionnaire is empty or only contains whitespace
          if (values?.question_type_id === 1) {
            // Assign the error message to the description field of the current item
            if(value?.module_id === "") {
              contentErrors.module_id = "*Required";
            }
            
          }

          // If there are any errors for the current item, push them to the errors.questionnaire array
          if (Object.keys(contentErrors).length > 0) {
            errors.questionnaire[index] = contentErrors;
          }
        });
        // If after processing all questionnaire items there are no errors, remove the questionnaire key from errors
        if (errors.questionnaire.length === 0) {
          delete errors.questionnaire;
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      let formData = new FormData();
      
      formData.append("question_type_id", values["question_type_id"]);
      values?.questionnaire?.forEach((value, index) => {
        if(is_edit) {
          formData.append("module_id", (value?.module_id !== "" && value?.module_id !== null) ? value?.module_id : "");
          formData.append("question", value?.question);
          formData.append("order", value?.order);
        }
        else {
          formData.append(
            `questionnaire[${index}][module_id]`,
            value?.module_id !== "" ? value?.module_id : ""
          );
        formData.append(
          `questionnaire[${index}][question]`,
          value?.question
        );
        formData.append(
          `questionnaire[${index}][order]`,
          value?.order
        );
        }
          
      });

      if (is_edit) {
        formData.append("id", selectedId);
        dispatch(updateQuestionnaire(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewQuestionnaire(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }

    },
  });

  const handleAddField = () => {
    let { questionnaire } = formik?.values;
    questionnaire = [
      ...questionnaire,
      {
        module_id: "",
        question: "",
        order: "",
      },
    ];
    formik.setFieldValue("questionnaire", questionnaire);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  return {
    formik,
    is_edit,
    getFieldError,
    closeModal,
    handleAddField,
    typeList,
  };
};

export default useAddAddons;
