import React from "react";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import useItemField from "./useItemField";
import Style from "./sessionForm.module.scss";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";

export const ItemField = ({
  formik,
  itmIndex,
  fields,
  is_edit,
  handleContentChange,
  handleContentTextChange,
}) => {
  const { handleRemoveFieldCS, handleVideosChange, handleAudioChange } =
    useItemField({
      fields,
      itmIndex,
      formik,
      is_edit,
    });

  let typeList = [
    {
      label: "Video",
      value: 1,
    },
    {
      label: "Audio",
      value: 2,
    },
    {
      label: "Text",
      value: 3,
    },
    {
      label: "Assessment",
      value: 4,
    },
  ];
  return (
    <>
      <div className={`pro-mb-4 ${Style.box_root} `}>
        {formik?.values?.bonus_program_content?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className={`col-md-12 ${Style.add_more_field} `}>
          <Input
            type="text"
            id={`content_title`}
            name={`content_title`}
            label={`Content Title`}
            onBlur={formik.handleBlur(
              `bonus_program_content.${itmIndex}.content_title`
            )}
            className={`pro-input lg ${
              formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_title &&
              formik.errors?.bonus_program_content?.[itmIndex]?.content_title &&
              " error"
            }`}
            {...formik.getFieldProps(
              `bonus_program_content.${itmIndex}.content_title`
            )}
            error={
              formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_title &&
              formik.errors?.bonus_program_content?.[itmIndex]
                ?.content_title && (
                <span className="error-text">
                  {
                    formik.errors?.bonus_program_content?.[itmIndex]
                      ?.content_title
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_title &&
              formik.errors?.bonus_program_content?.[itmIndex]
                ?.content_title && (
                <span className="error-text">
                  {
                    formik.errors?.bonus_program_content?.[itmIndex]
                      ?.content_title
                  }
                </span>
              )
            }
          />
          <Input
            type="text"
            id={`content_description`}
            name={`content_description`}
            label={`Content Description`}
            onBlur={formik.handleBlur(
              `bonus_program_content.${itmIndex}.content_description`
            )}
            className={`pro-input lg ${
              formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_description &&
              formik.errors?.bonus_program_content?.[itmIndex]
                ?.content_description &&
              " error"
            }`}
            {...formik.getFieldProps(
              `bonus_program_content.${itmIndex}.content_description`
            )}
            error={
              formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_description &&
              formik.errors?.bonus_program_content?.[itmIndex]
                ?.content_description && (
                <span className="error-text">
                  {
                    formik.errors?.bonus_program_content?.[itmIndex]
                      ?.content_description
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_description &&
              formik.errors?.bonus_program_content?.[itmIndex]
                ?.content_description && (
                <span className="error-text">
                  {
                    formik.errors?.bonus_program_content?.[itmIndex]
                      ?.content_description
                  }
                </span>
              )
            }
          />

          <div className="col pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Type *
              </label>
              <Select
                name="content_type"
                options={typeList} // replace with your display_order options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={typeList?.filter(
                  (m) =>
                    formik?.values?.bonus_program_content?.[itmIndex]
                      ?.content_type === m?.value
                )}
                onChange={(selectedOption) =>
                  {
                    formik.setFieldValue(
                    `bonus_program_content.${itmIndex}.content_type`,
                    selectedOption?.value
                  )
                  formik.setFieldValue(
                    `bonus_program_content.${itmIndex}.content_text`,
                    ""
                  )
                  formik.setFieldValue(
                    `bonus_program_content.${itmIndex}.content`,
                    ""
                  )
                }
                }
                className={`pro-input lg ${
                  formik.touched?.bonus_program_content?.[itmIndex]
                    ?.content_type &&
                  formik.errors?.bonus_program_content?.[itmIndex]
                    ?.content_type &&
                  " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(
                  `bonus_program_content.${itmIndex}.content_type`
                )}
              />
              {formik.touched?.bonus_program_content?.[itmIndex]
                ?.content_type &&
                formik.errors?.bonus_program_content?.[itmIndex]
                  ?.content_type && (
                  <div className="error-text">
                    {
                      formik.errors?.bonus_program_content?.[itmIndex]
                        ?.content_type
                    }
                  </div>
                )}
            </div>
          </div>
          {formik?.values?.bonus_program_content?.[itmIndex]?.content_type ===
            3 && (
            // <Input
            //   type="text"
            //   id={`content`}
            //   name={`content`}
            //   label={`Content`}
            //   onBlur={formik.handleBlur(
            //     `bonus_program_content.${itmIndex}.content`
            //   )}
            //   className={`pro-input lg ${
            //     formik.touched?.bonus_program_content?.[itmIndex]?.content &&
            //     formik.errors?.bonus_program_content?.[itmIndex]?.content &&
            //     " error"
            //   }`}
            //   {...formik.getFieldProps(
            //     `bonus_program_content.${itmIndex}.content`
            //   )}
            //   error={
            //     formik.touched?.bonus_program_content?.[itmIndex]?.content &&
            //     formik.errors?.bonus_program_content?.[itmIndex]?.content && (
            //       <span className="error-text">
            //         {formik.errors?.bonus_program_content?.[itmIndex]?.content}
            //       </span>
            //     )
            //   }
            //   errorMessage={
            //     formik.touched?.bonus_program_content?.[itmIndex]?.content &&
            //     formik.errors?.bonus_program_content?.[itmIndex]?.content && (
            //       <span className="error-text">
            //         {formik.errors?.bonus_program_content?.[itmIndex]?.content}
            //       </span>
            //     )
            //   }
            // />
            <div className="col-md-12">
              <div className={Style.editor_container}>
                <Editor
                  wrapperClassName={Style.pro_editor_wrapper}
                  editorClassName={Style.pro_editor_main}
                  toolbarClassName={Style.pro_editor_toolbar}
                  editorState={
                    formik.values?.bonus_program_content?.[itmIndex]?.content
                  }
                  readOnly={false}
                  onEditorStateChange={(editorState) =>
                    handleContentChange(editorState, itmIndex)
                  }
                  // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
                  toolbar={{
                    options: [
                      "blockType",
                      "inline",
                      "list",
                      "textAlign",
                      "fontSize",
                      "fontFamily",
                    ],
                    blockType: {
                      inDropdown: true,
                      className: Style.dropdown_wrapper,
                      dropdownClassName: Style.dropdown_menu,
                    },
                  }}
                />

                {formik.touched?.bonus_program_content?.[itmIndex]?.content &&
                  formik.errors?.bonus_program_content?.[itmIndex]?.content && (
                    <span className="error-text">
                      {
                        formik.errors?.bonus_program_content?.[itmIndex]
                          ?.content
                      }
                    </span>
                  )}
              </div>
            </div>
          )}

          {formik?.values?.bonus_program_content?.[itmIndex]?.content_type ===
            1 && (
            <div className={Style.wrapper}>
              <label className="pro-mb-1 pro-font-sm pro-fw-medium">
                Video*
              </label>
              <div
                className={`pro-d-flex pro-flex-wrap pro-gap-3 pro-p-2 ${Style.upload_wrap}`}
              >
                <></>

                {/* {showAddButton && ( */}
                <div
                  className={`pro-input lg ${
                    formik.touched?.bonus_program_content?.[itmIndex]
                      ?.content &&
                    formik.errors?.bonus_program_content?.[itmIndex]?.content &&
                    " error"
                  }`}
                >
                  <div className="pro-d-flex pro-justify-center pro-items-center pro-flex-column pro-text-center">
                    <span className="material-symbols-outlined x3">backup</span>
                    <span className={`${Style.add_text} pro-fw-medium `}>
                      "Add Video"
                    </span>
                  </div>
                  <input
                    type="file"
                    className={`${Style.file}`}
                    onChange={(e) => handleVideosChange(e, itmIndex)}
                  />
                </div>
                {/* )} */}
                {formik.touched?.bonus_program_content?.[itmIndex]?.content &&
                  formik.errors?.bonus_program_content?.[itmIndex]?.content && (
                    <span className="error-text">
                      {
                        formik.errors?.bonus_program_content?.[itmIndex]
                          ?.content
                      }
                    </span>
                  )}
              </div>
            </div>
          )}
          {formik?.values?.bonus_program_content?.[itmIndex]?.content_type ===
            2 && (
            <div className={Style.wrapper}>
              <label className="pro-mb-1 pro-font-sm pro-fw-medium">
                Audio*
              </label>
              <div
                className={`pro-d-flex pro-flex-wrap pro-gap-3 pro-p-2 ${Style.upload_wrap}`}
              >
                <></>

                {/* {showAddButton && ( */}
                <div
                  className={`pro-input lg ${
                    formik.touched?.bonus_program_content?.[itmIndex]
                      ?.content &&
                    formik.errors?.bonus_program_content?.[itmIndex]?.content &&
                    " error"
                  }`}
                >
                  <div className="pro-d-flex pro-justify-center pro-items-center pro-flex-column pro-text-center">
                    <span className="material-symbols-outlined x3">backup</span>
                    <span className={`${Style.add_text} pro-fw-medium `}>
                      "Add Audio"
                    </span>
                  </div>
                  <input
                    type="file"
                    className={`${Style.file}`}
                    onChange={(e) => handleAudioChange(e, itmIndex)}
                  />
                </div>
                {/* )} */}
                {formik.touched?.bonus_program_content?.[itmIndex]?.content &&
                  formik.errors?.bonus_program_content?.[itmIndex]?.content && (
                    <span className="error-text">
                      {
                        formik.errors?.bonus_program_content?.[itmIndex]
                          ?.content
                      }
                    </span>
                  )}
              </div>
            </div>
          )}

          {(formik?.values?.bonus_program_content?.[itmIndex]?.content_type ===
            1 ||
            formik?.values?.bonus_program_content?.[itmIndex]?.content_type ===
              2) && (
            <div className="col-md-12">
              <div className={Style.editor_container}>
                <Editor
                  wrapperClassName={Style.pro_editor_wrapper}
                  editorClassName={Style.pro_editor_main}
                  toolbarClassName={Style.pro_editor_toolbar}
                  editorState={
                    formik.values?.bonus_program_content?.[itmIndex]
                      ?.content_text
                  }
                  readOnly={false}
                  onEditorStateChange={(editorState) =>
                    handleContentTextChange(editorState, itmIndex)
                  }
                  // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
                  toolbar={{
                    options: [
                      "blockType",
                      "inline",
                      "list",
                      "textAlign",
                      "fontSize",
                      "fontFamily",
                    ],
                    blockType: {
                      inDropdown: true,
                      className: Style.dropdown_wrapper,
                      dropdownClassName: Style.dropdown_menu,
                    },
                  }}
                />

                {formik.touched?.bonus_program_content?.[itmIndex]
                  ?.content_text &&
                  formik.errors?.bonus_program_content?.[itmIndex]
                    ?.content_text && (
                    <span className="error-text">
                      {
                        formik.errors?.bonus_program_content?.[itmIndex]
                          ?.content_text
                      }
                    </span>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemField;
