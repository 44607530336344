import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "./ImageCard.module.scss";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export function ImageCard({
  data,
  docId,
  verification,
  handleClose,
  status,
  statusObj,
  setItemRejected,
  itemRejected,
  note,
  setNote,
  rejectReason,
}) {
  return (
    <div className={`${Style.root} pro-p-2`}>
      <TransformWrapper>
        {({ zoomIn, zoomOut }) => (
          <>
            <div className={Style.action_icon}>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={handleClose}
              >
                <span className="material-symbols-outlined">close</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomIn()}
              >
                <span className="material-symbols-outlined">zoom_in</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0`}
                onClick={() => zoomOut()}
              >
                <span className="material-symbols-outlined">zoom_out</span>
              </button>
            </div>
            <TransformComponent>
              <div className={`${Style.root_image} pro-mb-1`}>
                {data ? (
                  // <div className={Style.root_figure}>
                  // 	<figure className={`${Style.image}`}>
                  // 		<img
                  // 			src={data || ""}
                  // 			alt={"profile"}
                  // 			heigh={`100%`}
                  // 			width={`100%`}
                  // 		/>
                  // 	</figure>
                  // </div>
                  <div className={Style.root_pdf}>
                    <iframe
                      title="PDF Viewer"
                      src={data || ""}
                      height="700px"
                      width="100%"
                    />
                  </div>
                ) : (
                  <div className={Style.root_figure}>
                    <div className={`shine ${Style.thumb}`} />
                  </div>
                )}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      <div
        className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end pro-items-center`}
      >
        {rejectReason !== null && (
          <span className="pro-badge badge-grey pro-me-auto">
            {rejectReason}
          </span>
        )}
        {!itemRejected ? (
          status === 0 ? (
            <>
              <Button
                className="pro-btn lg pro-btn-outline"
                onClick={() => setItemRejected(true)}
              >
                Reject
              </Button>
              <Button
                className="pro-btn lg pro-btn-primary"
                onClick={() => verification(docId, 1)}
              >
                Approve
              </Button>
            </>
          ) : (
            <span
              className={`pro-badge pro-px-3 pro-py-1 ${
                status === 1 ? "badge-success-outline" : "badge-danger-outline"
              }`}
            >
              {statusObj?.[status]}
            </span>
          )
        ) : (
          itemRejected && (
            <>
              <div className="input-wrap">
                <input
                  type="text"
                  placeholder="reason for rejection"
                  onChange={(e) => setNote(e.target.value)}
                  className="pro-input lg"
                />
              </div>
              <Button
                className="pro-btn lg pro-btn-outline"
                onClick={() => setItemRejected(false)}
              >
                Cancel
              </Button>
              <Button
                className="pro-btn lg pro-btn-primary"
                onClick={() =>
                  verification(docId, 2, note) && setItemRejected(false)
                }
              >
                Submit
              </Button>
            </>
          )
        )}
      </div>
    </div>
  );
}
