import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// import Style from "../Students/students.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../../../store/slices/Global";
import { updateConfig } from "../../../../../store/slices/SellerModule/StudentFeedbacks/studentFeedbacksSlice";
import { toast } from "react-toastify";
import { useGetStudentFeedbacksListQuery } from "../../../../../store/queries/SellerModule/studentFeedbacks";
import StarRatings from "react-star-ratings";

const useStudentFeedbacks = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { studentID } = useParams();
  const navigate = useNavigate();
  // const [updateTableFields] = useUpdateStudentMyClassTableFieldsMutation();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const feedbackState = useSelector((state) => state.studentFeedbacks);
  const { tableFields } = useSelector((state) => state.global);
  const {
    data: feedbackList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetStudentFeedbacksListQuery({
    customer_id: activeProfile,
    page_size: feedbackState?.currentPageSize,
    page: feedbackState?.currentPage,
    sort_by: feedbackState?.sortBy,
    sort_order: feedbackState?.sortOrder,
    search: feedbackState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (feild, data) => {
        return (
          <div
          >
              {data?.["country"]?.name}
          </div>
        );
      },
      star_count: (feild, data) => {
        return (
          <div className="no-wrap">
            <StarRatings
              rating={Number(data?.star_count)}
              starDimension="15px"
              starSpacing="1px"
              starEmptyColor="#F4F5F7"
              starRatedColor="#FBC343"
            />
          </div>
        );
      },
      
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    feedbackList,
    paginationOptions,
    feedbackState,
    handlePagination,
    handlePageSize,
    refetch,
    getRow,
    handleEditColumnsClick,
  };
};

export default useStudentFeedbacks;
