import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTrainingVideoForm from "./useTrainingVideoForm";
import {
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import Select from "react-select";
import Style from "../trainingVideo.module.scss";

const TrainingVideoForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
    is_edit,
    handleThumbnailImage,
    imageThumbnailPreview
  } = useTrainingVideoForm({ refetch, closeModal });



  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

<div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Thumbnail*
        </label>
        <div className={`input-drag ${imageThumbnailPreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${
              formik.touched.thumbnail &&
              formik.errors.thumbnail &&
              " error"
            }`}
            id="thumbnail"
            name="thumbnail"
            onBlur={formik.handleBlur("thumbnail")}
            onChange={(e) => handleThumbnailImage(e)}
          />
          <span className="input-drag-box">
            {!imageThumbnailPreview ? (
              <IconText
                title={
                  formik?.values?.thumbnail?.name !== undefined
                    ? limitStrLength(formik?.values?.thumbnail?.name, 30)
                    : selectedItemsDetails !== "" &&
                      selectedItemsDetails?.thumbnail
                    ? selectedItemsDetails?.thumbnail
                    : `Drop files to attach or browse`
                  // `Drop files to attach or browse`
                }
              />
            ) : (
              <div className="img-wrap">
                <span className="img-close">&#10006;</span>
                <Image
                  width={100}
                  height={100}
                  src={imageThumbnailPreview}
                  alt={`thumbnail`}
                />
              </div>
            )}
          </span>
          {formik.touched.thumbnail && formik.errors.thumbnail && (
            <span className="error-text">{formik.errors.thumbnail}</span>
          )}
        </div>
      </div>

      <div className="col-md-12">
        <Input
          type="text"
          id={`video_url`}
          name={`video_url`}
          label={`Video URL`}
          value={formik?.values?.video_url}
          onBlur={formik.handleBlur(`video_url`)}
          className={`pro-input lg ${
            formik.touched?.video_url &&
            formik.errors?.video_url &&
            " error"
          }`}
          {...formik.getFieldProps(`video_url`)}
          error={
            formik.touched?.video_url &&
            formik.errors?.video_url && (
              <span className="error-text">{formik.errors?.video_url}</span>
            )
          }
          errorMessage={
            formik.touched?.video_url &&
            formik.errors?.video_url && (
              <span className="error-text">{formik.errors?.video_url}</span>
            )
          }
          onChange={(e) => formik.setFieldValue(`video_url`, e.target.value)}
        />
      </div>


      <Input
        label={"Description*"}
        type="text"
        id="description
          "
        name="description
          "
        className={`pro-input lg ${
          formik.errors.description && formik.touched.description && "error"
        }`}
        {...formik.getFieldProps("description")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.description && formik.touched.description}
        errorMessage={formik.errors.description}
      />

      {/* toggle button start  */}
     
     {is_edit && (
      <div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? 0
                );
              }}
            />
            <span></span>
          </div>
          <label>Status</label>
        </div>
     )}
        

      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default TrainingVideoForm;
