import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import useUserGoals from "./useUserGoals";
import EmptyData from "../../../Global/EmptyData";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";
import Style from "./userGoals.module.scss";
import { FaCheck, FaTimes } from "react-icons/fa";

const UserGoals = () => {
  const { isFetching, goalList, daysToShow, renderDays } = useUserGoals();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          {/* <div className={`col-12 ${Style.overview_wrap_list} pro-mb-6`}>
                  <div className="row gx-2 gx-xxl-6">
                    
                    {goalList?.data?.length > 0 && (
                      <div className="col-lg-9">
                        <div
                          className={`pro-d-flex pro-flex-nowrap pro-pb-3 ${Style.cluster_img_wrap}`}
                        >
                          {goalList?.data?.map((item, index) => (
                            <div
                              key={index}
                            >
                              {item?.module_name !== undefined && (
                                <div className={Style.imgix_wrap}>
                                  {item?.module_name}
                                </div>
                              )}
                              
                            </div>
                          ))}
                        </div>
                        
                      </div>
                      
                    )}
                  </div>
                </div> */}
          <div className={`${Style.goal_container} pro-pt-5 pro-pb-6`}>
            {daysToShow?.length > 0 && (
              <div className="col">
                <div className={Style.moduleContainer}>
                  {daysToShow?.map((item, index) => (
                    <div key={index} className={Style.module}>
                      {item.module_name && (
                        <div>
                          <div className={`pro-border-bottom pro-pb-5 ${Style.moduleName}`}>
                            <h6 className="pro-fw-bolder">{item.module_name}</h6>
                            <span>Unlock your potential</span>
                          </div>
                          <div className={`pro-py-5 ${Style.day_grid}`}>
                            {item.days ? (
                              renderDays(item.days)
                            ) : (
                              <strong>No Data Available</strong>
                            )}
                          </div>
                        </div>
                      )}

                      {item.alert_message && (
                        <div>
                          <div>
                            {item?.alert_message}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

        </>
      )}
    </div>
  );
};

export default UserGoals;
