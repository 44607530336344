import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteData, getMeditationDetails, updateConfig } from "../../../store/slices/dailyMeditation/dailyMeditationSlice";
import { useState, useMemo, useEffect } from "react";
import { useGetBasicDataQuery, useUpdateTableFieldsDataMutation } from "../../../store/queries/global";
import { useGetDailyMeditationDataQuery } from "../../../store/queries/dailyMeditation";
import { toast } from "react-toastify";

const useDailyMeditations = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.dailyMeditation);
  const { showEditModal } = useSelector((state) => state.global);
  const { showCreateModal, is_edit } = useSelector((state) => state.dailyMeditation);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const { data: formData = {} } = useGetBasicDataQuery();

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetDailyMeditationDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState?.currentPage,
    // filter: menuState?.currentFilter,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasChangeStatusPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };


  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (data) => {
    dispatch(getMeditationDetails(data?.[0]));

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        state.userEditData = {};
        state.clearSelection = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };


  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };
  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({id: selectedItemID[0]}))
        .unwrap()
        .then((result) => {
          if (result?.status_code === 200) {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    mainData,
    isLoading,
    isFetching,
    hasEditPermission,
    hasViewPermission,
    menuState,
    paginationOptions,
    actionOptions,
    currentPage: menuState?.currentPage,
    activeFilter: menuState?.currentFilter,
    formData,
    showCreateModal,
    showEditModal,
    updateTableFields,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    handleCreateClick,
    handleDelete,
    handleDeleteAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
    is_edit
  };
};

export default useDailyMeditations;
