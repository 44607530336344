import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id) => `branch_id[]=${id}`).join("&")
      : "";

      try {
        const response = await api[method](
             `${endpoint}?${getParams("start")}${getParams(
                  "end"
             )}${getParams("filter")}${getParams("sort_by")}&sort_order=${params?.sort_order || "desc"
             }&length=${params?.per_page || "10"}&${getParams("search")}&${getParams("customer_id")}page=${params?.page || 1
             }`,
             body
        );

        return { data: response.data };
   } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const orders = createApi({
  reducerPath: "ordersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["orders"],
  endpoints: (builder) => ({
    getUserOrderListData: builder.query({
      query: (values) => ({
           method: 'get',
           params: values,
           endpoint: `v1/admin-customer/orders`
      }),
      providesTags: ['orders']
 }),
  }),
});

export const {
  useGetUserOrderListDataQuery
} = orders;
