import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useOrders from "./useOrders";

const Orders = () => {
  const { drawerMenu } = useOrders();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Orders;
