import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";
// import Roles from "../components/Configure/roles";

import CaptureEmail from "../components/Auth/CaptureEmailForm";
import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing";
import Notification from "../components/Notification";
import NotificationListing from "../components/Notification/NotificationListing";
import Branches from "../components/Branch/branches";
import UserAdmin from "../components/Users/user";
import BranchDetailLayout from "../components/Branch/branches/branchDetailLayout";
import BranchPincode from "../components/Branch/branches/BranchPincode";
import BranchRate from "../components/Branch/branches/BranchRate";
import BranchCurrency from "../components/Branch/branches/BranchCurrency";
import Branch from "../components/Branch";
import Configure from "../components/Configure";
import Package from "../components/Package";
import Packages from "../components/Package/packages";
import BonusProgram from "../components/BonusPrograms";
import BonusPrograms from "../components/BonusPrograms/bonusPrograms";
import Program from "../components/Program";
import Programs from "../components/Program/programs";
import Roles from "../components/Role/roles";
import Customer from "../components/Customers";
import Customers from "../components/Customers/customer";
import ProgramDetailsLayout from "../components/Program/programs/ProgramDetailsLayout";
import ProgramPrices from "../components/Program/programs/ProgramPrices";
import ProgramSessions from "../components/Program/programs/ProgramSessions";
import PackageDetailsLayout from "../components/Package/packages/PackageDetailsLayout";
import PackagePrices from "../components/Package/packages/PackagePrices";
import PackageModules from "../components/Package/packages/PackageModules";
import UserDetailsLayout from "../components/Customers/customer/UserDetailsLayout";
import UserPurchaseHistory from "../components/Customers/customer/UserPurchaseHistory";
import UserGoals from "../components/Customers/customer/UserGoals";
import UserMyOrders from "../components/Customers/customer/UserModules";
import DailyMeditations from "../components/DailyMeditation/dailyMeditation";
import Questionnairs from "../components/Questionnaire/questionnairs";
import Coupons from "../components/Coupon/coupons";
import Reviews from "../components/Reviews";
import Review from "../components/Reviews/review";
import Testimonials from "../components/Configure/testimonials";
import Agent from "../components/Agent";
import Announcement from "../components/Agent/announcement";
import Agents from "../components/Agent/agents";
import TrainingSession from "../components/Agent/trainingSession";
import TrainingVideo from "../components/Agent/trainingVideo";
import AgentCoupons from "../components/Agent/agentCoupons";
import AgentDetailsLayout from "../components/Agent/agents/AgentDetailsLayout";
import Students from "../components/Agent/agents/Students";
import Payouts from "../components/Agent/agents/Payouts";
import DetailCoupon from "../components/Agent/agents/DetailCoupon";
import AgentTraining from "../components/SellerModule/AgentTraining";
import MyTraining from "../components/SellerModule/AgentTraining/myTraining";
import MyLearning from "../components/SellerModule/AgentTraining/myLearning";
import StudentActivity from "../components/SellerModule/StudentActivity";
import MyStudents from "../components/SellerModule/StudentActivity/myStudents";
import ReportedIssues from "../components/SellerModule/StudentActivity/reportedIssues";
import Feedbacks from "../components/SellerModule/StudentActivity/feedbacks";
import SellerCoupon from "../components/SellerModule/SellerCoupon";
import SellerCoupons from "../components/SellerModule/SellerCoupon/sellerCoupons";
import SellerPayouts from "../components/SellerModule/SellerPayouts";
import SellerPayout from "../components/SellerModule/SellerPayouts/sellerPayout";
import SellerAnnouncement from "../components/SellerModule/SellerAnnouncement";
import SellerAnnouncements from "../components/SellerModule/SellerAnnouncement/sellerAnnouncements";
import StudentDetailsLayout from "../components/SellerModule/StudentActivity/myStudents/StudentDetailsLayout";
import StudentGoals from "../components/SellerModule/StudentActivity/myStudents/StudentGoals";
import StudentPurchaseHistory from "../components/SellerModule/StudentActivity/myStudents/StudentPurchaseHistory";
import StudentFeedbacks from "../components/SellerModule/StudentActivity/myStudents/StudentFeedbacks";
import StudentIssues from "../components/SellerModule/StudentActivity/myStudents/StudentIssues";
import AllTrainings from "../components/SellerModule/AgentTraining/myTraining/AllTrainings";
import Coupon from "../components/Coupon";
import AgentPayouts from "../components/Agent/agentPayouts";
import Issues from "../components/Configure/issues";
import ManageRoles from "../components/Role/roles/ManageRoles";
import MonthlyPayouts from "../components/Agent/monthlyPayouts";
import PayoutHistory from "../components/Agent/payoutHistory";
import PayoutDetailsLayout from "../components/Agent/payoutHistory/PayoutDetailsLayout";
import Orders from "../components/Orders";
import Order from "../components/Orders/order";
import OrderDetailLayout from "../components/Orders/order/OrderDetailLayout";
import ProductsPage from "../components/Orders/order/ProductsPage";
import TransactionPage from "../components/Orders/order/TransactionPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="notifications" element={<Notification />}>
          <Route path="" element={<NotificationListing />} />
        </Route>

        <Route path="user-details" element={<UserDetailsLayout />}>
          <Route path="" element={<UserMyOrders />}></Route>
          <Route path=":userID" element={<UserMyOrders />}></Route>
          <Route
            path="purchase-history/:userID"
            element={<UserPurchaseHistory />}
          ></Route>
          <Route path="goals/:userID" element={<UserGoals />}></Route>
        </Route>
        <Route path="bonus-programs" element={<BonusProgram />}>
          <Route path="" element={<BonusPrograms />} />
        </Route>

        <Route path="review" element={<Reviews />}>
          <Route path="" element={<Review />} />
        </Route>
        <Route path="order" element={<Orders />}>
          <Route path="" element={<Order />} />
        </Route>

        <Route path="order/order-details" element={<OrderDetailLayout />}>
          <Route path=":orderID" element={<ProductsPage />}></Route>
          <Route
            path=":orderID/transaction"
            element={<TransactionPage />}
          ></Route>
        </Route>

        <Route path="program" element={<Program />}>
          <Route path="" element={<Programs />} />
        </Route>
        <Route path="program-details" element={<ProgramDetailsLayout />}>
          <Route path="" element={<ProgramPrices />}></Route>
          <Route path=":programID" element={<ProgramPrices />}></Route>
          <Route
            path="sessions/:programID"
            element={<ProgramSessions />}
          ></Route>
        </Route>
        <Route path="branches" element={<Branch />}>
          <Route path="" element={<Branches />} />
        </Route>
        <Route path="packages" element={<Package />}>
          <Route path="" element={<Packages />} />
        </Route>
        <Route path="packages-details" element={<PackageDetailsLayout />}>
          <Route path="" element={<PackagePrices />}></Route>
          <Route path=":packageID" element={<PackagePrices />}></Route>
          <Route path="modules/:packageID" element={<PackageModules />}></Route>
        </Route>

        <Route path="customer" element={<Customer />}>
          <Route path="" element={<Customers />} />
        </Route>
        <Route path="seller-coupon" element={<SellerCoupon />}>
          <Route path="" element={<SellerCoupons />} />
        </Route>
        <Route path="coupons" element={<Coupon />}>
          <Route path="" element={<Coupons />} />
        </Route>
        <Route path="seller-payouts" element={<SellerPayouts />}>
          <Route path="" element={<SellerPayout />} />
        </Route>
        <Route path="seller-announcement" element={<SellerAnnouncement />}>
          <Route path="" element={<SellerAnnouncements />} />
        </Route>

        <Route path="configure" element={<Configure />}>
          {/* <Route path="" element={<Roles />} ></Route> */}
          <Route path="role" element={<Roles />}></Route>
          <Route path="role/permission" element={<ManageRoles />}></Route>
          <Route path="coupon" element={<Coupons />}></Route>
          <Route path="questionnaire" element={<Questionnairs />}></Route>
          <Route path="user" element={<UserAdmin />}></Route>
          <Route path="daily-meditation" element={<DailyMeditations />}></Route>
          <Route path="testimonial" element={<Testimonials />}></Route>
          <Route path="issues" element={<Issues />}></Route>
        </Route>

        <Route path="agent" element={<Agent />}>
          <Route path="" element={<Agents />}></Route>
          <Route path="announcement" element={<Announcement />}></Route>
          <Route path="training-session" element={<TrainingSession />}></Route>
          <Route path="training-video" element={<TrainingVideo />}></Route>
          <Route path="agent-coupon" element={<AgentCoupons />}></Route>
          <Route path="agent-payouts" element={<AgentPayouts />}></Route>
          <Route path="monthly-payouts" element={<MonthlyPayouts />}></Route>
          <Route path="history" element={<PayoutHistory />}></Route>
        </Route>

        <Route path="student-activity" element={<StudentActivity />}>
          <Route path="my-student" element={<MyStudents />}></Route>
          <Route path="reported-issue" element={<ReportedIssues />}></Route>
          <Route path="feedback" element={<Feedbacks />}></Route>
        </Route>

        <Route path="student-details" element={<StudentDetailsLayout />}>
          <Route path="" element={<StudentGoals />}></Route>
          <Route path=":studentID" element={<StudentGoals />}></Route>
          <Route
            path="purchase-history/:studentID"
            element={<StudentPurchaseHistory />}
          ></Route>
          <Route
            path="feedback/:studentID"
            element={<StudentFeedbacks />}
          ></Route>
          <Route
            path="reported-issues/:studentID"
            element={<StudentIssues />}
          ></Route>
        </Route>

        <Route path="training" element={<AgentTraining />}>
          <Route path="" element={<MyTraining />}>
            <Route path=":tab" element={<AllTrainings />} />
          </Route>
          <Route path="my-learning" element={<MyLearning />}></Route>
        </Route>

        <Route
          path="agent-details/:agentId"
          element={<AgentDetailsLayout />}
        ></Route>
        <Route
          path="history-details/:batchId"
          element={<PayoutDetailsLayout />}
        ></Route>
        <Route path="agent-details" element={<AgentDetailsLayout />}>
          <Route path="students/:agentId" element={<Students />}></Route>
          <Route path="payouts/:agentId" element={<Payouts />}></Route>
          <Route path="coupons/:agentId" element={<DetailCoupon />}></Route>
        </Route>

        <Route path="branches/branch-detail" element={<BranchDetailLayout />}>
          <Route path=":branch_id" element={<BranchCurrency />}></Route>
          <Route
            path="pincode-detail/:branch_id"
            element={<BranchPincode />}
          ></Route>
          <Route path="rate/:branch_id" element={<BranchRate />}></Route>
        </Route>

        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route
            path="forgot-password/capture-email"
            element={<CaptureEmail />}
          ></Route>
          <Route
            path="forgot-password/:email"
            element={<ForgotPassword />}
          ></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>

        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
