import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetListDataQuery } from "../../../store/queries/roles";
import {
  deleteData,
  updateConfig,
} from "../../../store/slices/Roles/rolesSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useUpdateTableFieldsDataMutation } from "../../../store/queries/global";
import { toast } from "react-toastify";
import Style from "./roles.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";

const useRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolesState = useSelector((state) => state.roles);

  const {
    data: roles = {},
    isFetching,
    refetch,
  } = useGetListDataQuery({
    search: rolesState?.search,
    page_size: rolesState.currentPageSize,
    page: rolesState.currentPage,
  });

  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState(false);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const hasCreatePermission = useMemo(() => {
    let permission = roles?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? false;
  }, [roles]);
  const hasEditPermission = useMemo(() => {
    let permission = roles?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? false;
  }, [roles]);

  const hasDeletePermission = useMemo(() => {
    let permission = roles?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? false;
  }, [roles]);

  const handleSort = (label) => {
    if (rolesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rolesState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCreateClick = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    navigate({
      pathname: "/configure/role/permission",
      search: `role=${item[0]}&update=true`,
    });
  };

  const getRow = (feild, data) => {
    const rows = {
      agent: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      bonus_programs: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      cms: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      configure: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      coupon: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      customers: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      dashboard: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      orders: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      packages: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      programs: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      review: (feild, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[feild]}
                  width={16}
                  height={16}
                  alt={data[feild]}
                />
              </div>
            </div>
          </>
        );
      },
      status: (feild, data) => {
        // Determine the class based on the status
        const statusValue = data?.status?.toLowerCase();
        let statusClass = "";
        switch (statusValue) {
          case "requested":
            statusClass = "badge-warning-outline";
            break;
          case "active":
            statusClass = "badge-success-outline";
            break;
          case "deleted":
            statusClass = "badge-info-outline";
            break;
          case "rejected":
            statusClass = "badge-danger-outline";
            break;
          default:
            statusClass = ""; // Default class if none of the above match
            break;
        }

        return (
          <p className="pro-mb-0">
            <span className={`pro-badge ${statusClass}`}>{data[feild]}</span>
          </p>
        );
      },

      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      // support: (_, data) => <NotificationIcon data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({ role_id: selectedItemID[0] })).then((result) => {
        if (result?.payload?.status_code === 200) {
          toast.success(result?.payload?.message);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error(result?.payload?.message);
        }
        if (result) {
          refetch();
          setSelectedItemID("");
          setShowDeleteConfirm(false);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        }
      });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    roles,
    rolesState,
    isFetching,
    showModal,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    actionOptions,
    showEditModal,
    tableFields,
    showDeleteConfirm,
    selectedItemID,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleSearch,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
    handleEditClick,
    handleActionChange,
    refetch,
    closeEditModal,
    updateTableFields,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
  };
};

export default useRoles;
