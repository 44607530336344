import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import { useDispatch } from "react-redux";
import { getCategories } from "../../../../store/slices/program/programFormSlice";

const AgentFilterForm = ({ formik, handleReset, formData }) => {
  
  const today = new Date();
  today.setDate(today.getDate() - 1); 
  const minDate = today.toISOString().split('T')[0];

  const [startDateClass, setStartDateClass] = useState("");
  const [endDateClass, setEndDateClass] = useState("");

  // Handle change for the start date picker
  const handleStartDateChange = (e) => {
    formik.handleChange(e);
    setStartDateClass(e.target.value ? "date-selected" : "");
  };

  // Handle change for the end date picker
  const handleEndDateChange = (e) => {
    formik.handleChange(e);
    setEndDateClass(e.target.value ? "date-selected" : "");
  };
  
  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="status"
          options={formData?.user_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formData?.user_status?.filter((m) => formik.values.status === m.id)}
          onChange={(value) => formik.setFieldValue("status", value?.id)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Verification Status
        </label>
        <Select
          id="verification_status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="verification_status"
          options={formData?.document_verification_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formData?.document_verification_status?.filter((m) => formik.values.verification_status === m.id)}
          onChange={(value) => formik.setFieldValue("verification_status", value?.id)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Join Date From
        </label>
        <input
          className={`pro-input lg ${startDateClass}`}
          type="date"
          name="startDate"
          max={minDate}
          onChange={handleStartDateChange}
          value={formik.values.startDate}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Join Date To
        </label>
        <input 
          className={`pro-input lg ${endDateClass}`}
          type="date"
          name="endDate"
          max={new Date().toISOString().split("T")[0]}
          onChange={handleEndDateChange}
          value={formik.values.endDate}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={() => handleReset()}>
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default AgentFilterForm;
