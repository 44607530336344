import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("end")}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&per_page=${params?.page_size || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const notificationRtk = createApi({
  reducerPath: "notificationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["DataUpdation", "Transfer", "Notification"],
  endpoints: (builder) => ({
  
    getNotificationListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/notification/list`,
      }),
      providesTags: ["Notification"],
    }),
    updateNotificationData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/notification/mark-as-read`,
      }),
      invalidatesTags:["Notification"],
    }),
  }),
});

export const {
  useGetNotificationListDataQuery,
  useUpdateNotificationDataMutation
} = notificationRtk;
