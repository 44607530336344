import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  isLoading:false,
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  categoriesList: [],
  customerDetails: {},
  editPackage: false,
  selectedData: "",
  formActiveTab: "Basic",
  selectedId: "",
  imagePreview: [],
  imageName: [],
};

export const getCategories = createAsyncThunk(
  "programForm/categories",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin-module/category-listing");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const createNewPackage = createAsyncThunk(
  "new/createNewPackage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-package/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePackage = createAsyncThunk(
  "new/updatePackage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-package/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPackageDetails = createAsyncThunk(
  "v1/admin-package/show",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/admin-package/show/${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const packageFormSlice = createSlice({
  name: "packageForm",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
    resetEditAction: (state, action) => {
      state.customerDetails = {};
      state.editCustomer = false;
    },
  },
  extraReducers: {
    [getCategories.pending]: (state, action) => {},
    [getCategories.fulfilled]: (state, action) => {
      state.categoriesList = action.payload.data.data;
    },
    [getCategories.rejected]: (state, action) => {},


    [createNewPackage.pending]: (state, action) => {
      state.isLoading=true
    },
    [createNewPackage.fulfilled]: (state, action) => {
      state.isLoading=false
    },
    [createNewPackage.rejected]: (state, action) => {
      state.isLoading=false
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackageDetails.fulfilled, (state, action) => {
        state.editPackage = true;
        state.selectedData = action?.payload?.data?.data
      })
      
  },
});

export const { updateConfig, setEditTestimonail, resetEditAction } =
  packageFormSlice.actions;

export default packageFormSlice.reducer;
