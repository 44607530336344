import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SessionForm from "./SessionForm";
import PriceForm from "./PriceForm";
import { createNewPackage, updateConfig, updatePackage } from "../../../../store/slices/packages/packageFormSlice";
import { updateConfig as basicUpdateconfig } from "../../../../store/slices/packages/packageSlice";
import { getFormatedDate } from "../../../../utils/functions/table";
import ModuleForm from "./ModuleForm";
import { toast } from "react-toastify";

const useMultiTabForm = (setShowform, refetch, basicData) => {
  const [moveToTab, setMoveToTab] = useState(false);

  const dispatch = useDispatch();

  const { editPackage, isLoading, selectedData, formActiveTab } = useSelector(
    (state) => state.packageForm
  );


  const validationSchema = Yup.object({
    // Define your form field validations here
    package_type: Yup.string().required("Type is required"),
    name: Yup.string().required("Package Name is required"),
    description: Yup.string().required("Description is required"),
    // banner_content: Yup.string().required("Banner content is required"),
    sub_title: Yup.string().required("Sub title is required"),
    package_logo: editPackage
      ? Yup.mixed()
      : Yup.mixed().required("*Required"),
      intro_banner: editPackage
      ? Yup.mixed()
      : Yup.mixed().required("*Required"),
    package_video: editPackage
      ? Yup.mixed()
      : Yup.mixed().required("*Required"),
    package_benefits: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("*Required"),
      })
    ),
    banner_titles: Yup.array().of(
      Yup.object().shape({
        title_icon: Yup.string().required("*Required"),
        offer_image: Yup.string().required("*Required"),
        title_colour_code: Yup.string().required("*Required"),
      })
    ),

    package_price: Yup.array().of(
      Yup.object().shape({
        country_id: Yup.string().required("*Required"),
        price: Yup.number()
          .typeError("Price must be a number")
          .min(0, "Minimum Price is 0")
          .required("*Required"),
        currency: Yup.string().required("*Required"),
        tax: Yup.string().required("*Required"),
        mrp_amount: Yup.number()
          .typeError("MRP Amount must be a number")
          .min(0, "Minimum MRP amount is 0")
          .required("*Required"),
        offer_percentage: Yup.number()
          .typeError("Offer percentage must be a number")
          .min(0, "Minimum Offer percentage is 0")
          .required("*Required"),
        // price_without_offer: Yup.number()
        //   .typeError("This Price without offer must be a number")
        //   .min(0, "Minimum Price without offer is 0")
        //   .required("*Required"),
        offer_tag: Yup.string().required("*Required"),
        offer_validity: Yup.string().required("Required"),
      })
    ),
    modules: Yup.array().min(1, "Select one item").required(`Select item`),
    addons: Yup.array().min(1, "Select one item").required(`Select item`)
  });

  const initialData = {
    package_type: editPackage ? selectedData?.package_type : "",
    name: editPackage ? selectedData?.name : "",
    description: editPackage ? selectedData?.description : "",
    sub_title: editPackage ? selectedData?.sub_title : "",
    // banner_content: editPackage ? selectedData?.banner_content : "",
    package_benefits: editPackage && selectedData?.package_benefit?.length > 0
      ? selectedData?.package_benefit?.map((item, index) => ({
          title: item?.title ?? "",
        }))
      : [
          {
            title: "",
          },
        ],
    banner_titles: editPackage && selectedData?.banner_title?.length > 0
      ? selectedData?.banner_title?.map((item, index) => ({
          title_icon: item?.icon_url ?? "",
          offer_image: item?.logo_url ?? "",
          title_colour_code: item?.title_colour_code ?? "",
          banner_title_contents: item?.banner_title_content?.map((contentItem) => ({
            content_title: contentItem?.content_title ?? "",
            is_colour: contentItem?.is_colour
          }))
        }))
      : [
          {
            title_icon: "",
            offer_image: "",
            title_colour_code: "",
            banner_title_contents: [{
              content_title: "",
              is_colour: ""
            }]
          },
        ],
    package_video: "",
    package_intro_video_url: editPackage && selectedData?.package_intro_video_url ? selectedData?.package_intro_video_url : "",
    package_logo: "",
    intro_banner: "",
    package_logo_url: editPackage ? selectedData?.package_logo_url : "",
    package_price:
      selectedData && selectedData?.package_prices?.length > 0
        ? selectedData?.package_prices?.map((item, index) => ({
            country_id: item?.country_id ?? "",
            price: item?.price ?? "",
            currency: item?.currency ?? "",
            tax: item?.tax ?? "",
            mrp_amount: item?.mrp_amount ?? "",
            offer_percentage: item?.offer_percentage ?? "",
            // price_without_offer: item?.price_without_offer ?? "",
            offer_tag: item?.offer_tag ?? "",
            offer_validity: getFormatedDate(item?.created_at) ?? "",
          }))
        : [
            {
              country_id: "",
              price: "",
              currency: "",
              tax: "",
              mrp_amount: "",
              offer_percentage: "",
              // price_without_offer: "",
              offer_tag: "",
              offer_validity: "",
            },
          ],
          modules: selectedData && selectedData?.programs?.length > 0 ? 
          selectedData?.programs?.map((item) => item?.module_id) : [],
    addons: selectedData && selectedData?.package_addons?.length > 0 ? 
    selectedData?.package_addons?.map((item) => item?.addon_id) : [],
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("package_type", values?.package_type ?? "");
      formData.append("name", values?.name ?? "");
      formData.append("description", values?.description ?? "");
      formData.append("sub_title", values?.sub_title ?? "");
      formData.append("banner_image", values?.package_logo ?? "");
      formData.append("intro_banner", values?.intro_banner ?? "");
      // formData.append("banner_content", values?.banner_content ?? "");
      formData.append("intro_video", values?.package_video ?? "");

      if(editPackage) {
        formData.append('id', selectedData?.id);
      }
      if (values?.modules?.length > 0) {
        values?.modules?.forEach((value, index) => {
          formData.append(`modules[${index}]`, value);
        });
      }
      if (values?.addons?.length > 0) {
        values?.addons?.forEach((value, index) => {
          formData.append(`addons[${index}]`, value);
        });
      }

      if (values?.package_benefits?.length > 0) {
        values?.package_benefits?.forEach((value, index) => {
          formData.append(`package_benefits[${index}][title]`, value?.title);
        });
      }
      if (values?.banner_titles?.length > 0) {
        values?.banner_titles?.forEach((value, index) => {
          formData.append(`banner_title[${index}][title_icon]`, value?.title_icon);
          formData.append(`banner_title[${index}][offer_image]`, value?.offer_image);
          formData.append(`banner_title[${index}][title_colour_code]`, value?.title_colour_code);
          value?.banner_title_contents?.forEach((contentValue, contentIndex) => {
            formData.append(`banner_title[${index}][banner_title_content][${contentIndex}][content_title]`, contentValue?.content_title);
            formData.append(`banner_title[${index}][banner_title_content][${contentIndex}][is_colour]`, contentValue?.is_colour);
          })
        });
      }
      if (values?.package_price?.length > 0) {
        values?.package_price?.forEach((value, index) => {
          formData.append(
            `package_price[${index}][country_id]`,
            value?.country_id
          );
          formData.append(`package_price[${index}][currency_code]`, value?.currency);
          formData.append(`package_price[${index}][price]`, value?.price);
          formData.append(`package_price[${index}][tax]`, value?.tax);
          formData.append(
            `package_price[${index}][mrp_amount]`,
            value?.mrp_amount
          );
          formData.append(
            `package_price[${index}][offer_percentage]`,
            value?.offer_percentage
          );
          // formData.append(
          //   `package_price[${index}][price_without_offer]`,
          //   value?.price_without_offer
          // );
          formData.append(
            `package_price[${index}][offer_tag]`,
            value?.offer_tag
          );
          formData.append(
            `package_price[${index}][offer_validity]`,
            value?.offer_validity
          );
        });
      }

          if (editPackage) {

            dispatch(updatePackage(formData)).then((result) => {
              if (result?.payload?.data?.status_code === 200) {
                refetch();
                toast.success(result?.payload?.message)
                setActiveTabByName("Basic");
                setShowform(false);
                dispatch(
                  basicUpdateconfig((state) => {
                    state.editPackage = false;
                    state.clearSelection = true;
                  })
                );
              } else {
                toast.error(result?.payload?.message);
              }
            })
            .catch((err) => {
              Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
                toast.error("Something went wrong!")
              });
      });
          } else {
            dispatch(createNewPackage(formData)).then((result) => {
                    if (result?.payload?.data?.status_code === 200) {
                      refetch();
                      toast.success(result?.payload?.message)
                      setActiveTabByName("Basic");
                      setShowform(false);
                      dispatch(
                        basicUpdateconfig((state) => {
                          state.editPackage = false;
                          state.clearSelection = true;
                        })
                      );
                    } else {
                      toast.error(result?.payload?.message);
                    }
                  })
                  .catch((err) => {
                    Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
                      toast.error("Something went wrong!")
                    });
            });
          }

      // Handle form submission here
    },
  });

  const tabsAndFields = [
    {
      label: "Basic",
      fields: [
        "package_type",
    "name",
    "description",
    "sub_title",
    "package_benefits",
    "banner_titles",
    "package_video",
    "package_logo",
    "intro_banner",
    // "banner_content"
      ],
    },
    {
      label: "Price",
      fields: [
        "package_price",
        // "price",
        // "currency",
        // "tax",
        // "mrp_amount",
        // "price_without_offer",
        // "offer_tag",
        // "offer_validity",
      ],
    },
    {
      label: "Module",
      fields: [
        "modules",
        "addons"
      ],
    },
  ];

  const tabs = [
    {
      label: "Basic",
      completed: calculateCompletionStatus([
        "package_type",
    "name",
    "description",
    "package_benefits",
    "banner_titles",
    "package_video",
    "package_logo",
    "intro_banner",
    // "banner_content",
    "sub_title"
      ]),
    },
    {
      label: "Price",
      completed: calculateCompletionStatus([
        "country_id",
        "price",
        "currency",
        "tax",
        "mrp_amount",
        "offer_percentage",
        // "price_without_offer",
        "offer_tag",
        "offer_validity",
      ]),
    },
    {
      label: "Module",
      completed: calculateCompletionStatus([
        "modules",
        "addons",
      ]),
    },
  ];

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = updatedTab?.label;
      })
    );
  }

  function calculateCompletionStatus(fields) {
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "country_id" ||
        field === "price" ||
        field === "offer_validity" ||
        field === "offer_tag" ||
        field === "offer_percentage" ||
        // field === "price_without_offer" ||
        field === "mrp_amount" ||
        field === "tax" ||
        field === "currency"
      ) {
        return !!formik?.values?.package_price?.[0]?.[field];
      } else if (field === "title") {
        return !!formik?.values?.package_benefits?.[0]?.[field];
      } else if (field === "title_colour_code" || field === "offer_image" || field === "title_icon" || field === "banner_content") {
        return !!formik?.values?.banner_titles?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    if (tab.label) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
  };

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
  }, [formik.errors]);

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab === "Basic") {
      setActiveTabByName("Basic");
      return "";
    }
    if (firstErrorTab === "Price") {
      setActiveTabByName("Price");
      return "";
    }
    if (firstErrorTab === "Module") {
      setActiveTabByName("Module");
      return "";
    }

    if (firstErrorTab && firstErrorTab !== formActiveTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );

      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const renderTabContent = () => {
    switch (formActiveTab) {
      case "Basic":
        return (
          <>
            <BasicForm
              formik={formik}
              tabs={tabs}
              setShowform={setShowform}
              selectedItemsDetails={editPackage}
              selectedData={selectedData}
            />
          </>
        );
      case "Price":
        return (
          <PriceForm
            formik={formik}
            tabs={tabs}
            setShowform={setShowform}
            selectedItemsDetails={editPackage}
          />
        );

      case "Module":
        return (
          <ModuleForm
          formik={formik}
          tabs={tabs}
          setShowform={setShowform}
          isLoading={isLoading}
          checkError={handleMoveToErrorTab}
          />
        );
      default:
        return null;
    }
  };

  return {
    tabs,
    isLoading,
    activeTab: formActiveTab,
    handleTabClick,
    formik,
    renderTabContent,
    editPackage,
  };
};

export default useMultiTabForm;
