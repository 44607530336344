import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global/index.js";
import { getFormatedDate } from "../../../../utils/functions/table.js";
import { addDays } from "date-fns";
import {
  updateConfig,
  deleteData,
  getEditDataByID,
} from "../../../../store/slices/SellerModule/ReportedIssues/reportedIssuesSlice.js";
import { toast } from "react-toastify";
import Style from "./reportedIssues.module.scss";
import { useGetListDataQuery } from "../../../../store/queries/SellerModule/reportedIssues/index.js";
import { useUpdateTableFieldsDataMutation } from "../../../../store/queries/global/index.js";

const useReportedIssues = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.reportedIssues);

  const { showCreateModal, selctedId } = useSelector(
    (state) => state.reportedIssues
  );
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
    status: menuState.status,
  });

  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const hasCreatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permissions?.[0]?.can_create ? 1 : 0;
  }, [mainData]);

  const hasUpdatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permissions?.[0]?.can_edit ? 1 : 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permissions?.[0]?.can_delete ? 1 : 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(getEditDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      // status: (field, data) => <p className="pro-mb-0"><span className="pro-badge ">{data[field]}</span></p>,
      status: (field, data) => {
        const statusValue = data?.status?.toLowerCase();
        let statusClass = "";
        switch (statusValue) {
          case "pending":
            statusClass = "badge-warning-outline";
            break;
          case "solved":
            statusClass = "badge-success-outline";
            break;
          case "not solved":
            statusClass = "badge-danger-outline";
            break;
          default:
            statusClass = ""; // Default class if none of the above match
            break;
        }

        return (
          <p className="pro-mb-0">
            <span className={`pro-badge ${statusClass}`}>{data[field]}</span>
          </p>
        );
      },
      participants: (field, data) => {
        return (
          <>
            {data?.participants?.map((participant) => (
              <div key={participant.user_id} className={Style.inner_row}>
                <span>
                  {participant?.user?.first_name} {participant?.user?.last_name}
                </span>
              </div>
            ))}
          </>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    showImageModal,
    imageData,
    handleEditAction,
    closeImageModal,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleDelete,
    tableRef,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleDeleteAction,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    handleEditColumnsClick,
    setFilterShow,
    filterShow,
  };
};

export default useReportedIssues;
