import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../../Global/OrderColumn";
import useStudentPurchaseHistory from "./useStudentPurchaseHistory";
import EmptyData from "../../../../Global/EmptyData";

const StudentPurchaseHistory = () => {
  const {
    isFetching,
    purchaseList,
    paginationOptions,
    purchaseState,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    updateTableFields,
    showEditModal,
    refetch,
    closeEditModal
  } = useStudentPurchaseHistory();
  
  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
      <HeadingGroup
          title={"Purchase History"}
          className={`pro-mb-4`}
        />
        <div className={`pro-pb-5`}>
          {purchaseList?.data?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={purchaseList?.data?.data || []}
              uniqueID={"id"}
              fields={purchaseList?.data?.fields || []}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={purchaseState?.clearSelection}
              perpage={purchaseState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
        </div>

        {purchaseList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={purchaseState?.currentPage}
            totalPageCount={Math.ceil(
              purchaseList?.data?.total / purchaseState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(
                purchaseList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = purchaseList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={purchaseList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default StudentPurchaseHistory;
