import AadharInfo from "./AadharInfo";
import BankInfo from "./BankInfo";
import Style from "./manageProfileForm.module.scss";
import Profile from "./Profile";
import Security from "./Security";
import useManageProfileForm from "./useManageProfileForm";

const ManageProfileForm = ({ closeModal, updateUserDetails }) => {
  const {
    formActiveTab,
    isSelectedProfile,
    isSelectedSecurity,
    isSelectedBank,
    isSelectedAadhar,
    activeUser,
    setActiveTab,
  } = useManageProfileForm();
  return (
    <>
      <div className={`${Style.tab_title_wrap}`}>
        <ul className="pro-d-flex pro-gap-2 pro-justify-around pro-items-center pro-border-bottom pro-mt-3 pro-w-100">
          <li
            className={`${Style.tab_btn} ${isSelectedProfile && Style.active}`}
            onClick={() => {
              setActiveTab("Profile");
            }}
          >
            Profile
          </li>
          <li
            className={`${Style.tab_btn} ${isSelectedSecurity && Style.active}`}
            onClick={() => {
              setActiveTab("Security");
            }}
          >
            Security
          </li>
          {activeUser?.id !== 1 && (
            <li
              className={`${Style.tab_btn} ${isSelectedBank && Style.active}`}
              onClick={() => {
                setActiveTab("Bank Info");
              }}
            >
              Bank Info{" "}
              <span className="tooltip">
                <i className="material-symbols-outlined">info</i>
                <span className="tooltip_info">
                  Please fill the Bank Details
                </span>
              </span>
            </li>
          )}
          {activeUser?.id !== 1 && (
            <li
              className={`${Style.tab_btn} ${isSelectedAadhar && Style.active}`}
              onClick={() => {
                setActiveTab("Aadhar Info");
              }}
            >
              Aadhar Info
            </li>
          )}
        </ul>
      </div>
      {formActiveTab && formActiveTab === "Profile" ? (
        <Profile closeModal={closeModal} />
      ) : formActiveTab === "Security" ? (
        <Security closeModal={closeModal} />
      ) : formActiveTab === "Bank Info" ? (
        <BankInfo
          closeModal={closeModal}
          updateUserDetails={updateUserDetails}
        />
      ) : formActiveTab === "Aadhar Info" ? (
        <AadharInfo closeModal={closeModal} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ManageProfileForm;
