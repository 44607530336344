import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
     currentPage: 1,
     currentRatePage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     currentRatePageSize: 10,
     startDate: null,
     endDate: null,
     clearSelection: false,
     queryData: {},
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     rateSearch:"",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     searchCurrency:""
}

export const deletePincode = createAsyncThunk(
     "/admin/branch/pincode/delete",
     async (body, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post(`/admin/branch/pincode/delete`, body)
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)

const branchPincodeSlice = createSlice({
     name: "branchPincode",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          }
     }
})

export const { updateConfig } = branchPincodeSlice.actions;

export default branchPincodeSlice.reducer;
