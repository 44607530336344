import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../api";

import useValidations from "../../../utils/hooks/useValidations";
import { toast } from "react-toastify";

const useResetPassword = () => {
  const { validPasswords } = useValidations();
  const navigate = useNavigate();
  const [resetStatus, setResetStatus] = useState("idle");
  const [showPassword, setShowPassword] = useState(true);

  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  useEffect(() => {
    if (resetStatus === "success") {
      navigate("/login");
    } else if (resetStatus === "failed") {
      formik.setFieldError("confirm_password", "An error occured");
    }
    // eslint-disable-next-line
  }, [resetStatus]);

  const userAccessToken = localStorage.getItem("VERIFICATION_TOKEN")

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      let errors = {};
      const {
        uppercasePassword,
        lowercasePassword,
        digitsPassword,
        minLengthPassword,
        charsPassword,
      } = validPasswords(values.password);
      if (!values.password) {
        errors.password = "*Password required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "*Password confirmation required";
      }
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      if (!minLengthPassword) {
        errors.password = "*Password should be atleast 8 digits";
      }
      if (!charsPassword) {
        errors.password = "*Enter atleast 1 special character";
      }
      if (!digitsPassword) {
        errors.password = "*Enter atleast 1 digit";
      }
      if (!lowercasePassword) {
        errors.password = "*Enter atleast 1 lowercase letter";
      }

      if (!uppercasePassword) {
        errors.password = "*Enter atleast 1 uppercase letter";
      }

      return errors;
    },
    onSubmit: (values) => {
      setResetStatus("pending");

      changePassword({
        password: values.password,
        password_confirmation: values.confirm_password,
        token: userAccessToken,
      }).then((response) => {
        if (response?.data?.success) {
          setResetStatus("success");
          toast.success("Password has been updated successfully");
          navigate(`/login`);
        } else {
          setResetStatus("failed");
          toast.error(response.message);
        }
      });
    },
  });

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    resetStatus,
    showPassword,
    showConfirmPassword,
    handleShowConfirmPassword,
    handleShowPassword,
  };
};

export default useResetPassword;
