import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useProgram from "./useProgram";

const Program = () => {
  const { drawerMenu } = useProgram();
  return (

    <CommonLayout drawerMenu={drawerMenu} dashboard>
      <Outlet />
    </CommonLayout>
    
  );
};

export default Program;
