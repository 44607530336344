import React from "react";
import {
  Actions,
  Header,
  ModalLayout,
  NavGroup,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  OffCanvasLayout,
  ConfirmationBox,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useUserDetailsLayout from "./useUserDetailsLayout";
import profileStyle from "./userDetailsLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";

// import StudentsForm from "../StudentsListing/StudentsForm";
import Assets from "../../../../assets/Assets";
import HeaderActions from "../../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../pages/Layouts/CommonLayout/useCommonLayout";
// import AddStudents from "../Students/AddStudents";
// import CourseAssign from "../CourseAssign";

const UserDetailsLayout = () => {
  const {
    activeProfile,
    isFetching,
    closeModal,
    basicData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    handleChangeStatus,
    setStatusUpdate,
    popUpMessage,
    handleDeleteModal,
  } = useUserDetailsLayout();

  const { navigations, globalState } = useCommonLayout();
  const createLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(basicData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-4 pro-pt-5 left-sidebar`}>
                    <div className={`pro-pt-2 pro-pb-1 pro-border-bottom pro-mb-4 `}>
                      <Link to={`/customer`} className="pro-back-btn pro-items-center pro-d-flex pro-items-center pro-mb-2">
                          <i className="material-symbols-outlined x4">
                            {" "}
                            keyboard_arrow_left{" "}
                          </i>
                        <span>{`Back`}</span>  
                      </Link>
                    </div>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            basicData?.data?.module_image_path ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      {/* <div>{basicData?.data?.module_name}</div> */}
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <h2 className={profileStyle.left_sidebar_title}>{basicData?.data?.name}</h2>
                      <p className={profileStyle.left_sidebar_title}>Joined on {basicData?.data?.date_of_join}</p>
                      <div className="pro-d-flex  pro-justify-between">
                        {/* <h6 className="pro-ttl h6 fw-semibold pro-mb-3 pro-fw-bold">  Basic Details</h6> */}
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Basic Details"}
                    data={basicDetails}
                    // editIcon={
                    //     <span className="material-symbols-outlined">
                    //       {basicData?.result?.permissions["can_edit"]  ? `edit_square` : ``}
                    //     </span>
                    // }
                    handleEdit={() => handleEditClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                      </div> 
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          {/* <OffCanvasLayout
            show={ProfileState?.showAddModal}
            handleClose={closeModal}
            title={`${"Update Student Basic Details"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
          >
            <AddStudents
              isStickyFooter
              refetch={refetch}
              closeModal={closeModal}
            />
          </OffCanvasLayout> */}

          {/* <ModalLayout
            show={ProfileState?.showDeleteModal}
            handleClose={() => handleDeleteModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={popUpMessage}
                cancelText={"No"}
                cancelAction={handleDeleteModal}
                submitText={"Yes"}
                submitAction={() => setStatusUpdate(true)}
                isRight={true}
              />
            </div>
          </ModalLayout> */}

          {/* main container */}

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <div className="tab_wrapper">
                <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
                <Outlet context={{ activeProfile }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default UserDetailsLayout;
