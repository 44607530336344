import Style from './AgentListingShimmer.module.scss';

const AgentListingShimmer = () => {
  return (
    <>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4 pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
          <div  className={` pro-border-left pro-ps-4  pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
        </div>
      </div>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4 pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
          <div  className={` pro-border-left pro-ps-4  pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
        </div>
      </div>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4 pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
          <div  className={` pro-border-left pro-ps-4  pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
        </div>
      </div>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4 pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
          <div  className={` pro-border-left pro-ps-4  pro-d-flex pro-items-center`}>
            <span className='shimmer pro-d-block pro-p-2 pro-w-100'></span>
          </div>
        </div>
      </div>
    </>
        
  )
}

export default AgentListingShimmer