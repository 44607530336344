import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "../manageProfileForm.module.scss";
import useSecurity from "./useSecurity";

const Security = ({ refetch, closeModal }) => {
  const {
    handleShowNewPassword,
    handleShowConfirmPassword,
    handleShowCurrentPassword,
    formik,
    showNewPassword,
    showConfirmPassword,
    showCurrentPassword
  } = useSecurity({ refetch, closeModal });
  return (
    <div className={Style.form_wrap}>
      <div className={`pro-mb-4 ${Style.form_input} p-re input-wrap`}>
        <label
          htmlFor="current_password"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Current Password
        </label>
        <input
          type="password"
          className={`pro-input lg ${
            formik.errors.current_password &&
            formik.touched.current_password &&
            "error"
          }`}
          id="current_password"
          name="current_password"
          {...formik.getFieldProps("current_password")}
        />
        {showCurrentPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowCurrentPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowCurrentPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.touched.current_password && formik.errors.current_password && (
          <span className="error-text">{formik.errors.current_password}</span>
        )}
      </div>

      <div className={`pro-mb-4 ${Style.form_input} p-re input-wrap`}>
        <label
          htmlFor="password"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          New Password
        </label>
        <input
          type="password"
          className={`pro-input lg ${
            formik.errors.password && formik.touched.password && "error"
          }`}
          id="password"
          name="password"
          {...formik.getFieldProps("password")}
        />
        {showNewPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowNewPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowNewPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.touched.password && formik.errors.password && (
          <span className="error-text">{formik.errors.password}</span>
        )}
      </div>

      <div className={`pro-mb-4 ${Style.form_input} p-re input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Confirm Password
        </label>
        <input
          type="password"
          className={`pro-input lg ${
            formik.errors.confirm_password &&
            formik.touched.confirm_password &&
            "error"
          }`}
          id="confirm_password"
          name="confirm_password"
          {...formik.getFieldProps("confirm_password")}
        />

        {showConfirmPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowConfirmPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowConfirmPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <span className="error-text">{formik.errors.confirm_password}</span>
        )}
      </div>

      <div className={`col-12 pro-d-flex `}>
        {/* <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button> */}

        <Button
          className={"pro-btn-primary lg pro-me-3"}
          onClick={formik?.handleSubmit}
          type="button"
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default Security;
