import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useConfigure = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    // {
    //   title: "Configure",
    //   link: "/configure",
    //   icon: <span className="material-symbols-outlined x4"> workspace_premium </span>,
    //   active: checkIfActiveRoute("/configure", true),
    // },
    {
      title: "Roles",
      link: "/configure/role",
      icon: <span className="material-symbols-outlined x4"> currency_exchange </span>,
      active: checkIfActiveRoute("/configure/role", true),
    },
    {
      title: "Admin",
      link: "/configure/user",
      icon: <span className="material-symbols-outlined x4"> connecting_airports </span>,
      active: checkIfActiveRoute("/configure/user", true),
    },
    {
      title: "Daily Meditation",
      link: "/configure/daily-meditation",
      icon: <span className="material-symbols-outlined x4"> package </span>,
      active: checkIfActiveRoute("/configure/daily-meditation", true),
    },
    {
      title: "Questionnaire",
      link: "/configure/questionnaire",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/configure/questionnaire", true),
    },
    {
      title:"Testimonial",
      link:"/configure/testimonial",
      icon:<span className="material-symbols-outlined x4">3p</span>,
      active: checkIfActiveRoute("/configure/testimonial", true)
    },
    {
      title:"Customer Issues",
      link:"/configure/issues",
      icon:<span className="material-symbols-outlined x4">universal_currency_alt</span>,
      active: checkIfActiveRoute("/configure/issues", true)
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useConfigure;
